<template>
  <div>
    <FileList
      v-if="totalCount > 0"
      :files="files"
      :uploaded-files="uploadedFiles"
      :icon-size="64"
      :disabled="isDisabled"
      @remove="$refs.files.remove($event)"
      @removeUploadedFile="onRemoveUploadedFile"
    />

    <v-card v-if="showButton" flat :disabled="isDisabled" :class="{ 'pt-4': totalCount > 0 }">
      <BtnAction :disabled="totalCount >= maxFiles" @click="$refs.files.click()">
        <v-icon left>{{ mdiPaperclip }}</v-icon>
        {{ label || $t('Добавить фото или файл') }}
      </BtnAction>
    </v-card>

    <FileInput ref="files" v-bind="{ ...$props, ...$attrs }" @input="onInput" />
    <v-input :value="allFiles" :rules="rules" :hint="hint" :persistent-hint="persistentHint" />
  </div>
</template>

<script>
import { mdiPaperclip } from '@mdi/js';
import FileList from '@/components/file/FileList';
import FileInput from '@/components/file/FileInput';
import BtnAction from '@/components/ui/BtnAction';

export default {
  name: 'FileSelector',

  components: { BtnAction, FileInput, FileList },

  inject: ['form'],

  inheritAttrs: false,

  model: {
    prop: 'files',
    event: 'update:files',
  },

  props: {
    accept: String,
    disabled: Boolean,
    extensions: String,
    hideButton: Boolean,
    hint: String,
    persistentHint: Boolean,
    id: String,
    label: String,
    maxFiles: {
      type: Number,
      default: 5,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    rules: Array,
    files: Array,
    uploadedFiles: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    mdiPaperclip() {
      return mdiPaperclip;
    },

    allFiles() {
      return this.uploadedFiles.concat(this.files ?? []);
    },

    totalCount() {
      return this.allFiles.length;
    },

    isDisabled() {
      return this.disabled || this.form?.disabled;
    },

    showButton() {
      if (!this.hideButton) {
        return true;
      }

      return this.totalCount < this.maxFiles;
    },
  },

  methods: {
    onInput(files) {
      this.$emit('update:files', files);
    },

    onRemoveUploadedFile(url) {
      this.$emit(
        'update:uploadedFiles',
        this.uploadedFiles.filter(x => x !== url)
      );
    },
  },
};
</script>
