<template>
  <v-text-field
    :value="modelValue"
    class="input-center flex-grow-0"
    style="width: 120px"
    hide-details
    readonly
    :prepend-inner-icon="mdiMinus()"
    :append-icon="mdiPlus()"
    @click:prepend-inner="$emit('update:modelValue', modelValue - (modelValue > min ? 1 : 0))"
    @click:append="$emit('update:modelValue', modelValue + (modelValue < max ? 1 : 0))"
  />
</template>

<script>
import { mdiMinus, mdiPlus } from '@mdi/js';

export default {
  name: 'QuantityInput',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },

  methods: {
    mdiPlus() {
      return mdiPlus;
    },
    mdiMinus() {
      return mdiMinus;
    },
  },
};
</script>
