<template>
  <v-row>
    <v-col cols="12" md="8">
      <h1 class="mb-4 text-h1">{{ news.headline }}</h1>

      <MediaCarousel :images="news.files" class="mb-6" />

      <div class="mb-6">
        <div class="d-flex align-center gap-2">
          <div class="text-body-2 t-grey--text">
            {{ news.publishedFrom | date('long') }}
          </div>
          <Tag v-if="news.fixedOnTopPosition" color="primary" text-color="white" x-small>
            {{ $t('Важно') }}
          </Tag>
        </div>
        <div v-if="news.category" class="mt-2 text-body-2 t-grey--text">
          {{ news.category.name }}
        </div>
      </div>

      <div class="mb-6 d-flex align-center justify-space-between">
        <div>
          <Tags :tags="news.tags" @click:tag="$emit('click:tag', $event)" />
        </div>
      </div>

      <EditorContent :content="news.content" />

      <div v-if="news.externalUrl" class="mt-4 text-body-2 text-right font-italic t-grey--text">
        {{ $t('Источник') }}:
        <a class="t-grey--text" :href="news.externalUrl" target="_blank">
          {{ news.externalUrl | hostname }}
        </a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Tags from '@/components/ui/Tags';
import EditorContent from '@/components/ui/EditorContent';
import MediaCarousel from '@/components/ui/MediaCarousel';
import Tag from '@/components/ui/Tag.vue';

export default {
  name: 'NewsDisplay',

  components: {
    Tag,
    MediaCarousel,
    EditorContent,
    Tags,
  },

  props: {
    news: {
      type: Object,
      required: true,
    },
    liked: Boolean,
  },
};
</script>
