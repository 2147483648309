<template>
  <div
    v-if="dynamicFields && dynamicFields.length > 0"
    :key="renderKey"
    class="d-flex flex-column gap-6"
  >
    <div>
      <DynamicFields v-model="rootFieldsValues" :dynamic-fields="rootFields" />
    </div>
    <div v-for="table in tableFields" :key="table.id">
      <DynamicTableFields v-model="tableFieldsValues[table.id]" :table-field="table" />
    </div>
  </div>
</template>

<script>
import DynamicFields from '@/components/dynamic-fields/DynamicFields.vue';
import DynamicTableFields from '@/components/dynamic-fields/DynamicTableFields.vue';

export default {
  name: 'DynamicForm',

  components: {
    DynamicTableFields,
    DynamicFields,
  },

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    dynamicFields: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
    },
  },

  data() {
    return {
      renderKey: 0,
      rootFieldsValues: [],
      tableFieldsValues: {
        // id => [...]
      },
    };
  },

  computed: {
    rootFields() {
      return this.dynamicFields.filter(x => !x.parentId && x.inputType !== 'table');
    },

    tableFields() {
      return this.dynamicFields
        .filter(x => x.inputType === 'table')
        .map(table => ({
          ...table,
          children: this.dynamicFields.filter(x => x.parentId === table.id),
        }));
    },

    values() {
      const values = [...this.rootFieldsValues];
      Object.values(this.tableFieldsValues).forEach(x => values.push(...x));

      return values;
    },
  },

  watch: {
    rootFieldsValues: 'throwUpdate',

    tableFieldsValues: {
      deep: true,
      handler: 'throwUpdate',
    },

    modelValue() {
      if (this.modelValue !== this.values) {
        this.renderKey++;
        this.readModelValue();
      }
    },
  },

  created() {
    this.readModelValue();
  },

  methods: {
    readModelValue() {
      const modelValue = this.modelValue ?? [];

      this.rootFieldsValues = this.rootFields
        .map(f => modelValue.find(x => x.id === f.id))
        .filter(x => x);

      Object.values(this.tableFields).forEach(tableField => {
        const values = modelValue.filter(x => x.parentId === tableField.id);
        if (values.length) {
          values.unshift(tableField);
        }
        this.$set(this.tableFieldsValues, tableField.id, values);
      });
    },

    throwUpdate() {
      this.$emit('update:modelValue', this.values);
    },
  },
};
</script>
