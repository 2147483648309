<template>
  <v-card outlined rounded v-on="$listeners">
    <v-card-text>
      <v-row>
        <v-col cols="4">{{ unit.name }}</v-col>
        <v-col cols="3">
          <template v-if="unit.area">{{ $t('Площадь') }} - {{ unit.area }}&nbsp;м²</template>
        </v-col>
        <v-col cols="2" class="primary--text">
          <template v-if="unit.rentable && unit.priceCategory">
            {{ unit.priceCategory.price }} ₽/м²
          </template>
        </v-col>
        <v-col cols="3">
          <UnitStatus :unit="unit" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import UnitStatus from '@/components/plan/UnitStatus.vue';

export default {
  name: 'UnitChildItem',

  components: { UnitStatus },

  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
};
</script>
