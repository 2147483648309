<template>
  <v-card
    elevation="8"
    max-width="360"
    rounded="lg"
    :style="{ position: 'sticky', top: `${top}px` }"
  >
    <v-card-text class="px-6 py-8">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'EventAside',

  props: {
    top: {
      type: Number,
      default() {
        return this.$vuetify.application.top + 32;
      },
    },
  },
};
</script>
