<template>
  <div id="tpw">
    <v-app v-if="showApp">
      <v-main>
        <NewsView v-if="isView"></NewsView>
        <LastNews v-else-if="count" :url="url"></LastNews>
        <NewsList v-else></NewsList>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/newsStore';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import NewsList from '@/views/news/NewsList.vue';
import NewsView from '@/views/news/NewsView.vue';
import LastNews from '@/views/news/LastNews.vue';

export default {
  name: 'NewsWidget',

  components: {
    LastNews,
    NewsView,
    NewsList,
  },

  mixins: [commonPropsMixin, themeMixin],

  props: {
    count: [Number, String],
    url: String,
  },

  computed: {
    location() {
      return state.location;
    },
    showApp() {
      if (this.count) {
        return state.location && state.newsList.length > 0;
      }

      return !!state.location;
    },
    isView() {
      return state.type === 'news-view' || state.currentNews;
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
    if (this.count) {
      actions.fetchNews({ pageSize: this.count });
    }
  },
};
</script>
