var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-expansion-panels',{staticClass:"by",attrs:{"accordion":"","multiple":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.categories),function(category){return _c('v-expansion-panel',{key:category.id},[_c('v-expansion-panel-header',[_c('v-badge',{staticClass:"flex-grow-0",attrs:{"value":!!_vm.countByCategory[category.id],"content":_vm.countByCategory[category.id],"inline":""}},[_vm._v(" "+_vm._s(category.name)+" ")])],1),_c('v-expansion-panel-content',[_c('ProductList',{attrs:{"products":_vm.getCategoryProducts(category.id)},scopedSlots:_vm._u([{key:"default",fn:function({ product }){return [_c('ProductItem',{staticClass:"px-6",attrs:{"product":product,"price-color":_vm.getCount(product) > 0 ? 'primary' : undefined,"free":_vm.free},scopedSlots:_vm._u([(!_vm.hideButtons)?{key:"item-action",fn:function(){return [_c('PlusMinus',{attrs:{"count":_vm.getCount(product),"max":product.unlimitedStocks
                    ? null
                    : product.stockQuantity - product.reservationQuantity},on:{"plus":function($event){return _vm.onProductAdd(product)},"minus":function($event){return _vm.onProductRemove(product)}}}),(
                  !product.unlimitedStocks &&
                  _vm.getCount(product) > 0 &&
                  _vm.getCount(product) >= product.stockQuantity - product.reservationQuantity
                )?_c('div',{staticClass:"mt-2 text-caption t-grey--text"},[_vm._v(" "+_vm._s(_vm.$t('Всего в наличии {0} шт', [ product.stockQuantity - product.reservationQuantity, ]))+" ")]):_vm._e()]},proxy:true}:null],null,true)})]}}],null,true)})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }