<template>
  <PageContainerWithChat>
    <component :is="orderComponent" v-if="serviceTicket" />
    <Wrapper class="mt-6">
      <a class="text-decoration-none" href="#" @click.prevent="back">← {{ $t('Назад') }}</a>
    </Wrapper>

    <template v-if="ticket" #chat>
      <ChatBox :ticket-id="ticket.id" :show-input="!isClosed">
        <template v-if="isPendingClose" #bottom>
          <div class="pt-4 text-center text--secondary">
            {{ $t('Пишите, если остались вопросы...') }}
          </div>
        </template>
      </ChatBox>
    </template>
  </PageContainerWithChat>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js';
import { state } from '@/store/orders';
import { ServiceType } from '@/constants/service';
import { STStatus } from '@/constants/STStatus';
import Wrapper from '@/components/ui/Wrapper.vue';
import OrderDefault from '@/views/home/orders/order/OrderDefault.vue';
import OrderBooking from '@/views/home/orders/order/OrderBooking.vue';
import OrderCoffeeBreak from '@/views/home/orders/order/OrderCoffeeBreak.vue';
import PageContainerWithChat from '@/components/ui/page/PageContainerWithChat.vue';
import ChatBox from '@/components/common/chat/ChatBox.vue';

const componentByType = {
  [ServiceType.BOOKING]: OrderBooking,
  [ServiceType.COMMERCIAL]: OrderDefault,
  // [ServiceType.CONTACTING_TPARK]: OrderTpark,
  [ServiceType.COFFEE_BREAK]: OrderCoffeeBreak,
  // [ServiceType.POSTER]: OrderPoster,
  [ServiceType.MASSAGE]: OrderBooking,
  // [ServiceType.EVENT_TICKET]: OrderEventTicket,
  // [ServiceType.COWORKING]: OrderCoworking,
  // [ServiceType.HOTEL]: OrderHotel,
  [ServiceType.RENT_OFFICE]: OrderDefault,
};

export default {
  name: 'OrderView',

  components: {
    ChatBox,
    PageContainerWithChat,
    Wrapper,
  },

  data() {
    return {
      mdiArrowLeft: mdiArrowLeft,
    };
  },

  computed: {
    serviceTicket() {
      return state.viewOrder;
    },

    service() {
      return this.serviceTicket?.service;
    },

    orderComponent() {
      return this.service && (componentByType[this.service.type] || OrderDefault);
    },

    ticket() {
      return this.serviceTicket?.ticket;
    },

    isPendingClose() {
      return this.serviceTicket?.status === STStatus.PENDING_CLOSE;
    },

    isClosed() {
      return (
        this.serviceTicket?.status === STStatus.CLOSED ||
        this.serviceTicket?.status === STStatus.CANCELED
      );
    },
  },

  methods: {
    back() {
      state.viewOrder = null;
    },
  },
};
</script>
