<template>
  <div :class="[serviceTicket.status === $stStatus.CLOSED && 'primary--text']">
    <v-icon
      v-if="serviceTicket.status === $stStatus.CLOSED"
      class="mr-2 my-2"
      color="primary"
      size="32"
    >
      {{ mdiCheckCircleOutline }}
    </v-icon>
    <template v-if="$slots.default">
      <slot />
    </template>
    <template v-else>
      {{ serviceTicket | orderStatus(userMode) }}
    </template>
  </div>
</template>

<script>
import { mdiCheckCircleOutline } from '@mdi/js';

export default {
  name: 'ServiceTicketStatus',
  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
    userMode: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    mdiCheckCircleOutline,
  }),
};
</script>
