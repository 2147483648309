import Vue from 'vue';
import http from '@/api/http';
import { uploadFiles } from '@/api/storage';
import { uniqueFilter } from '@/utils/arrays';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const otrsUrl = host + '/' + process.env.VUE_APP_API_OTRS.replace(/^\/+|\/+$/g, '');
const profileUrl = host + '/' + process.env.VUE_APP_API_PROFILE.replace(/^\/+|\/+$/g, '');

const initialState = {
  articles: {
    // ticketId => [articles]
  },
};

export const state = Vue.observable(initialState);

export const getters = {
  getArticles(ticketId) {
    return state.articles[ticketId] || [];
  },
};

export const actions = {
  async fetchArticles({ ticketId, ...params }) {
    const { items: articles } = await http.get(`${otrsUrl}/ticket/${ticketId}/article`, {
      params: { ticketId, order: 'DESC', limit: 1000, ...params },
    });

    const userIds = articles
      .map(x => x.userId)
      .filter(x => x)
      .filter(uniqueFilter);
    if (userIds.length > 0) {
      const { items: users } = await http.get(`${profileUrl}/profile`, {
        params: {
          userId: userIds.join(','),
          limit: userIds.length,
        },
      });
      articles.forEach(article => {
        article.user = users.find(x => x.userId === article.userId);
      });
    }

    Vue.set(state.articles, ticketId, articles);
  },

  async markAsSeen({ ticketId, ...rest }) {
    await http.patch(`${otrsUrl}/article/seen`, {
      ticketId: String(ticketId),
      ...rest,
    });
  },

  async createArticle({ ticketId, senderType, article, files = [] }) {
    const attachments = [];
    if (files && files.length > 0) {
      const urls = await uploadFiles(files);
      files.forEach((file, idx) => {
        attachments.push({
          filename: file.name,
          contentSize: file.size,
          contentType: file.type || 'application/octet-stream',
          url: urls[idx],
        });
      });
    }

    const data = await http.post(`${otrsUrl}/ticket/${ticketId}/article`, {
      ...article,
      ticketId,
      senderType,
      attachments,
    });

    state.articles[ticketId].unshift(data);
  },

  addArticle({ ticketId, article }) {
    state.articles[ticketId].unshift(article);
  },
};

export default { state, getters, actions };
