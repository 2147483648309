<template>
  <div>
    <DotsMenu v-if="isCancellable" bottom left>
      <v-list dense>
        <v-list-item @click="closeDialog = true">
          {{ $t('Закрыть заявку') }}
        </v-list-item>
      </v-list>
    </DotsMenu>

    <TicketCloseModal
      v-if="closeDialog"
      :service-ticket-id="serviceTicket.id"
      :ticket-id="serviceTicket.ticket.id"
      :state="serviceTicket.ticket.state"
      @close="closeDialog = false"
    />
  </div>
</template>

<script>
import { STStatus } from '@/constants/STStatus';
import DotsMenu from '@/components/ui/DotsMenu';
import TicketCloseModal from '@/views/home/orders/order/menu/TicketCloseModal';

export default {
  name: 'TicketMenu',

  components: { TicketCloseModal, DotsMenu },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    closeDialog: false,
  }),

  computed: {
    isCancellable() {
      return ![STStatus.CANCELED, STStatus.CLOSED].includes(this.serviceTicket?.status);
    },
  },
};
</script>
