var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',{on:{"mouseenter":function($event){return _vm.$emit('mouseenter', $event)},"mouseleave":function($event){return _vm.$emit('mouseleave', $event)}}},[_c('a',{class:{
      active: false,
      disabled: _vm.disabled, // not shown in editor
      free: _vm.item.unit.rentable && _vm.item.unit.availableForRent,
      rented: _vm.item.unit.rentable && !_vm.item.unit.availableForRent,
      service: false,
      unavailable: !_vm.item.unit.rentable,
      hover: false,
      selected: false,
      'no-link': !_vm.$listeners['click'],
    },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('click', $event)}}},[_c('polygon',{staticClass:"contour",attrs:{"points":_vm.polygonPoints}}),(_vm.textPoint)?_c('text',{attrs:{"text-anchor":"middle","x":_vm.textPoint.x,"y":_vm.textPoint.y}},[(_vm.displayName)?_c('tspan',{attrs:{"text-anchor":"middle","x":_vm.textPoint.x,"dy":_vm.nameDy}},[_vm._v(" "+_vm._s(_vm.displayName)+" ")]):_vm._e(),(_vm.displayArea)?_c('tspan',{attrs:{"text-anchor":"middle","x":_vm.textPoint.x,"dy":_vm.areaDy}},[_vm._v(" "+_vm._s(_vm.displayArea)+" м² ")]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }