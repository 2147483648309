import Vue from 'vue';
import Nl2br from 'vue-nl2br';
import vueCustomElement from 'vue-custom-element';
import { ReactiveRefs } from 'vue-reactive-refs';
import App from '@/App.vue';
import PlanWidget from '@/PlanWidget.vue';
import OrdersWidget from '@/OrdersWidget.vue';
import LastOrdersWidget from '@/LastOrdersWidget.vue';
import EventsWidget from '@/EventsWidget.vue';
import NewsWidget from '@/NewsWidget.vue';
import TestWidget from '@/TestWidget.vue';
import '@/plugins/dayjs';
import ReplaceI18n from '@/plugins/replace-i18n';
import vuetify from '@/plugins/vuetify';
import '@/directives/index';
import '@/filters';
import '@/mixins/common';
import '@/mixins/global';

Vue.use(vueCustomElement);
Vue.use(ReactiveRefs);
Vue.use(ReplaceI18n);

Vue.component('Nl2br', Nl2br);

App.vuetify = vuetify;
Vue.customElement('tpark-services', App);
Vue.customElement('tpark-widget', App);
Vue.customElement('olvery-widget', App);

PlanWidget.vuetify = vuetify;
Vue.customElement('olvery-plan-widget', PlanWidget);

OrdersWidget.vuetify = vuetify;
Vue.customElement('olvery-orders-widget', OrdersWidget);
LastOrdersWidget.vuetify = vuetify;
Vue.customElement('olvery-last-orders-widget', LastOrdersWidget);

EventsWidget.vuetify = vuetify;
Vue.customElement('olvery-events', EventsWidget);

NewsWidget.vuetify = vuetify;
Vue.customElement('olvery-news', NewsWidget);

TestWidget.vuetify = vuetify;
Vue.customElement('olvery-test', TestWidget);
