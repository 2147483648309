<template>
  <v-list three-line class="py-0">
    <div v-for="(product, idx) of products" :key="product.id">
      <slot v-bind="{ product }" />
      <v-divider v-if="idx !== products.length - 1" />
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'ProductList',

  props: {
    products: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
