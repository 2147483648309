const ReplaceI18n = {
  install(Vue) {
    Vue.prototype.$t = function (value, data) {
      return value.replace(/{(\w*)}/g, (m, key) => data[key] ?? '');
    };
    Vue.prototype.$n = function (value, type) {
      if (type === 'currency') {
        return `${value} ₽`;
      }

      return value;
    };
  },
};

export default ReplaceI18n;
