import http from './http';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const otrsUrl = host + '/' + process.env.VUE_APP_API_OTRS.replace(/^\/+|\/+$/g, '');

const hotelApi = {
  searchVariants: ({ serviceId, ...params }) =>
    http.get(`${otrsUrl}/hotel/available-variants/service/${serviceId}`, { params }),
};

export default hotelApi;
