import { STStatus } from '@/constants/STStatus';
import { PaymentStatus } from '@/constants/payment';

const label = {
  ServiceTicketStatus: {
    creating: 'Создание',
    new: 'Новый',
    invoicing: 'Новый. Ожидает выставления счета на оплату',
    'waiting for wire payment': 'Безналичный расчет. Счет выставлен',
    'waiting for payment': 'Счет выставлен',
    confirmed: 'Подтвержден',
    active: 'Активен',
    in_work: 'В работе',
    packed: 'Собран',
    delivery: 'В пути',
    'processing required': 'Требуется обработка',
    pending_close: 'Выполнен. Ожидает подтверждения',
    closed: 'Выполнен',
    canceled: 'Отменен',
    'canceled+purchased': 'Отменен. Выполняется возврат',
    'canceled+refunded': 'Отменен. Выполнен возврат',
  },
  PaymentStatus: {
    created: 'Ожидает оплаты/выставления счета',
    redirected: 'В процессе оплаты',
    invoicing: 'Ожидает выставления счета на оплату',
    waiting_for_wire_payment: 'Счет выставлен',
    postpay: 'Постоплата по счету',
    purchased: 'Оплачено',
    refunded: 'Выполнен возврат',
    canceled: 'Отменено',
    error: 'Неуспешная оплата',
    failed: 'Неуспешная оплата',
  },
};

export default function orderStatus(serviceTicket) {
  const isNewWithPayment = serviceTicket.status === STStatus.NEW && serviceTicket.paymentStatus;
  const isCanceledFailed =
    serviceTicket.status === STStatus.CANCELED &&
    [PaymentStatus.FAILED, PaymentStatus.ERROR].includes(serviceTicket.paymentStatus);
  const isCanceledRefunded =
    serviceTicket.status === STStatus.CANCELED &&
    serviceTicket.paymentStatus === PaymentStatus.REFUNDED;

  if (isNewWithPayment || isCanceledFailed || isCanceledRefunded) {
    return (
      label.ServiceTicketStatus[serviceTicket.status] +
      '. ' +
      label.PaymentStatus[serviceTicket.paymentStatus]
    );
  }

  if (
    serviceTicket.status === STStatus.CANCELED &&
    serviceTicket.paymentStatus === PaymentStatus.PURCHASED
  ) {
    return label.ServiceTicketStatus[serviceTicket.status] + '. ' + 'Выполняется возврат';
  }

  return label.ServiceTicketStatus[serviceTicket.status];
}
