import Vue from 'vue';
import dayjs from 'dayjs';
import http from '@/api/http';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const authUrl = host + '/' + process.env.VUE_APP_API_AUTH.replace(/^\/+|\/+$/g, '');
const newsUrl = host + '/' + process.env.VUE_APP_API_EVENT.replace(/^\/+|\/+$/g, '');

const initialState = {
  type: 'news-list', // news-list | news-view
  location: null,
  locationId: null,
  params: {},
  newsList: [],
  meta: {},
  categories: [],
  currentNews: null,
};

export const state = Vue.observable(initialState);

export const getters = {};

export const actions = {
  initData({ locationId, userHash }) {
    state.locationId = locationId;
    http.setLocationId(locationId);

    if (userHash) {
      state.userHash = userHash;
      http.setUserHash(userHash);
    }
  },

  async fetchLocationInfo() {
    if (!state.location && state.locationId) {
      state.location = await http.get(`${authUrl}/location/${state.locationId}`);
      if (state.location) {
        dayjs.tz.setDefault(state.location.city.timezone);
      }
    }
  },

  async fetchNews(params) {
    const { items, ...meta } = await http.get(`${newsUrl}/widget/news`, { params });
    state.meta = meta;
    state.newsList = items;
    state.params = params;
  },

  async fetchCategories() {
    if (!state.categories.length) {
      state.categories = await http.get(`${newsUrl}/widget/news-category`);
    }
  },

  async fetchCurrentNews(id) {
    state.currentNews = await http.get(`${newsUrl}/widget/news/${id}`);
  },
};
