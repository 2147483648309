<template>
  <v-list-item class="item px-6" active-class="active" v-bind="$attrs" v-on="$listeners">
    <v-list-item-avatar size="72" tile>
      <v-img :src="product.imageUrl" alt="" class="rounded-lg" />
    </v-list-item-avatar>

    <v-list-item-content class="py-6">
      <v-list-item-title
        class="mb-2 text-body-1 font-weight-medium"
        :class="[titleColor && `${titleColor}--text`]"
      >
        {{ (ignoreCommercialName ? product.name : product.commercialName) || product.name }}
      </v-list-item-title>
      <div v-if="!free" class="mb-2 text-body-1" :class="[priceColor && `${priceColor}--text`]">
        {{ $n(price || product.price, 'currency') }}
      </div>
      <v-list-item-subtitle v-if="product.description" class="mb-2">
        {{ product.description }}
      </v-list-item-subtitle>

      <div v-if="$slots.bottom">
        <slot name="bottom" />
      </div>
    </v-list-item-content>

    <v-list-item-action class="my-6">
      <slot name="item-action" />
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'ProductItem',
  props: {
    free: Boolean,
    price: {
      type: Number,
      default: null,
    },
    product: {
      type: Object,
      required: true,
    },
    titleColor: {
      type: String,
      default: 't-grey',
    },
    priceColor: {
      type: String,
      default: 'secondary',
    },
    ignoreCommercialName: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.item {
  min-height: 124px;
}

.active {
  background-color: $menu-bg !important;
  border: 1px solid $additional;
  box-sizing: border-box;
}
</style>
