<template>
  <nl2br tag="div" :text="value || ''" />
</template>

<script>
export default {
  name: 'TextareaValue',
  props: {
    value: String,
  },
};
</script>
