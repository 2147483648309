<template>
  <v-card v-if="images.length > 0" flat rounded="lg">
    <v-carousel
      :show-arrows="images.length > 1"
      :hide-delimiters="images.length === 1"
      cycle
      hide-delimiter-background
      height="100%"
    >
      <v-carousel-item v-for="(url, i) in images" :key="i" :src="url" :aspect-ratio="3 / 2" />
    </v-carousel>
  </v-card>
</template>

<script>
export default {
  name: 'MediaCarousel',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
