export default {
  computed: {
    $rules() {
      return {
        required: () => v => (v !== null && v !== undefined && v !== '') || 'Обязательное поле',

        requiredOr: some => v => !!some || !!v || 'Обязательное поле',

        requiredArray: () => v => !!(v && v.length) || 'Обязательное поле',

        requiredTrue: () => v => v === true || 'Обязательное поле',

        int: () => v => {
          return !v || /^-?\d+$/.test(v) || 'Значение должно быть целым числом';
        },

        number: () => v => {
          return !v || !isNaN(+v) || 'Значение должно быть числом';
        },

        inn: () => v => !v || /^(\d{10}|\d{12})$/.test(v) || 'Укажите корректный ИНН',

        kpp: () => v => !v || /^\d{9}$/.test(v) || 'Укажите корректный КПП',

        json: () => v => {
          try {
            if (v !== null && v !== undefined) {
              JSON.parse(v);
            }
          } catch (e) {
            return 'Невалидный JSON';
          }

          return true;
        },

        url: () => val => {
          if (!val) {
            return true;
          }

          let url;
          try {
            url = new URL(val);
          } catch (_) {
            return 'Укажите корректный URL в формате https://example.com';
          }

          return url.protocol === 'http:' || url.protocol === 'https:' || 'Укажите корректный URL';
        },
      };
    },
  },
};
