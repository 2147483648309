<template>
  <div
    ref="wrapper"
    v-resize.quiet="setChatWidth"
    class="t-back-light fill-height"
    style="border-left: 1px solid"
  >
    <Chat
      v-if="ticketId && !$brandConfig.hideChat"
      :ticket-id="ticketId"
      :show-input="showInput"
      :height="`100%`"
      :width="chatWidth"
      :agent-side="agentSide"
      :style="{ position: $vuetify.breakpoint.mdAndUp ? 'fixed' : undefined }"
    >
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </Chat>
  </div>
</template>

<script>
import Chat from '@/components/common/chat/Chat';

export default {
  name: 'ChatBox',

  components: { Chat },

  props: {
    ticketId: Number,
    showInput: Boolean,
    agentSide: Boolean,
  },

  data: () => ({
    chatHeight: null,
    chatWidth: null,
  }),

  mounted() {
    this.$nextTick(() => this.setChatWidth());
  },

  methods: {
    setChatWidth() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.chatWidth = this.$refs.wrapper.clientWidth;
      }
    },
  },
};
</script>
