<template>
  <div class="d-flex align-baseline" :class="{ 'font-weight-bold': summary }">
    <div class="flex-grow-1">
      <slot>{{ name }}</slot>
    </div>
    <span class="ml-4 flex-shrink-0 text-right t-grey--text text-body-2" style="width: 88px">
      <slot name="qty">{{ qty }}</slot>
    </span>
    <span class="ml-4 flex-shrink-0 text-right primary--text" style="min-width: 64px">
      <slot name="sum">{{ sum }}</slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'OrderPosition',
  props: {
    name: String,
    qty: [String, Number],
    sum: String,
    summary: Boolean,
  },
};
</script>
