<template>
  <div v-if="service && service.offerActive" class="text-body-2">
    Нажимая кнопку, я принимаю условия
    <a
      v-if="service && service.offerActive"
      class="color-inherit"
      href="#"
      @click.prevent="$refs.serviceOffer.open()"
      v-text="'оферты оказания услуги'"
    />
    <ServiceOfferDialog ref="serviceOffer" :service="service" />
    <!--
    {{ ' ' }}
    <a
      class="color-inherit"
      href="#"
      @click.prevent="$refs.privacyDialog.open()"
      v-text="'политики конфиденциальности'"
    />
    <PrivacyPolicyDialog ref="privacyDialog" />

    <template v-if="location && publicOffer">
      и
      <a
        class="color-inherit"
        href="#"
        @click.prevent="$refs.touDialog.open()"
        v-text="'пользовательского соглашения'"
      />
      <TermsOfUseDialog ref="touDialog" :public-offer="publicOffer" />
    </template>
    -->
  </div>
</template>

<script>
import { state } from '@/store/store';
import ServiceOfferDialog from '@/components/terms/ServiceOfferDialog';

export default {
  name: 'AcceptTerms',

  components: {
    ServiceOfferDialog,
  },

  props: {
    service: Object,
  },

  computed: {
    location() {
      return state.location;
    },
  },
};
</script>
