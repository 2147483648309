<script>
import SelectField from './SelectField.vue';

export default {
  name: 'MultiselectField',

  functional: true,

  render(h, { data, children }) {
    return h(SelectField, { ...data, props: { ...data.props, multiple: true } }, children);
  },
};
</script>
