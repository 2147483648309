<template>
  <v-expansion-panel v-bind="$attrs" v-on="$listeners">
    <v-expansion-panel-header class="font-weight-bold">
      {{ header }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <Wrapper class="pb-6">
        <slot />
      </Wrapper>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import Wrapper from '@/components/ui/Wrapper.vue';

export default {
  name: 'OrderPanel',
  components: { Wrapper },
  props: ['header'],
};
</script>
