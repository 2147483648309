<template>
  <v-dialog
    v-model="dialog"
    content-class="gallery-dialog rounded-0"
    width="90%"
    :fullscreen="fullscreen"
  >
    <v-sheet class="d-flex">
      <v-sheet min-width="104" width="104">
        <div class="overflow-y-auto">
          <v-container>
            <v-row dense :style="{ maxHeight: height }">
              <v-col v-for="(img, n) in images" :key="n" class="d-flex justify-center" cols="12">
                <ImgPreview :src="img" :selected="selected === n" @click="selectImage(n)" />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>

      <v-sheet class="flex-grow-1" rounded="0">
        <v-img
          v-if="images && images.length"
          :src="images[selected]"
          :height="height"
          :max-height="height"
          aspect-ratio="1"
          contain
        >
          <div class="px-8 d-flex align-center justify-space-between fill-height">
            <div
              class="d-flex align-center fill-height"
              style="width: 50%; cursor: pointer"
              @click="prev"
            >
              <DarkBtn v-show="selected > 0" fab small>
                <v-icon>{{ mdiArrowLeft }}</v-icon>
              </DarkBtn>
            </div>

            <div
              class="d-flex align-center justify-end fill-height"
              style="width: 50%; cursor: pointer"
              @click="next"
            >
              <DarkBtn v-show="selected < images.length - 1" fab small>
                <v-icon>{{ mdiArrowRight }}</v-icon>
              </DarkBtn>
            </div>
          </div>

          <v-btn
            fab
            small
            absolute
            top
            right
            dark
            color="t-dark"
            style="opacity: 0.5; top: 16px"
            @click="dialog = false"
          >
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-img>
      </v-sheet>
    </v-sheet>
  </v-dialog>
</template>

<script>
import ImgPreview from '@/components/gallery/ImgPreview';
import DarkBtn from '@/components/ui/DarkBtn';
import { mdiArrowLeft, mdiArrowRight, mdiClose } from '@mdi/js';

export default {
  name: 'PhotoViewer',
  components: { DarkBtn, ImgPreview },
  props: {
    images: Array,
    showIdx: Number,
  },

  data: () => ({
    dialog: false,
    selected: null,
    mdiArrowLeft,
    mdiArrowRight,
    mdiClose,
  }),

  computed: {
    fullscreen() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    height() {
      return this.fullscreen ? '100vh' : '90vh';
    },
  },

  watch: {
    images(val) {
      if (val && val.length) {
        this.dialog = true;
        this.selected = this.showIdx;
      }
    },
  },

  methods: {
    selectImage(n) {
      this.selected = n;
    },

    next() {
      if (this.selected + 1 < this.images.length) {
        this.selected++;
      }
    },

    prev() {
      if (this.selected - 1 >= 0) {
        this.selected--;
      }
    },
  },
};
</script>
