<template>
  <div>
    <v-expansion-panels
      class="by"
      accordion
      multiple
      :readonly="readonly"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'OrderPanels',
  inheritAttrs: false,
  props: {
    readonly: Boolean,
  },
};
</script>
