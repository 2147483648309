<template>
  <ServiceViewPage :service="service" :subtitle="subtitle">
    <v-form ref="form" :disabled="saving" @submit.prevent="onSubmit">
      <BlockTitle class="mt-5 mb-5">Новое бронирование</BlockTitle>

      <v-row dense>
        <v-col cols="12">
          <OrderDateInput
            v-model="form.date"
            :service-id="service.id"
            :rules="[v => !!v || 'Выберите дату']"
            label="Выберите дату бронирования из доступных"
            autoselect
          />
        </v-col>
        <v-col cols="12">
          <SlotToggle
            v-model="form.slots"
            :slots="slotsForDate"
            :rules="[v => !!(v && v.length) || 'Выберите время', validateSlotCount]"
            label="Выберите часы бронирования из доступных"
          />
        </v-col>
        <v-col v-if="earlyAccess" cols="12">
          <div>
            <SvgIcon size="24" class="mr-2 primary--text">
              <IconDoorIn />
            </SvgIcon>
            Доступ предоставляется за {{ earlyAccess }} минут до начала аренды, если переговорная
            будет свободна.
          </div>
        </v-col>
      </v-row>

      <v-row v-if="!userHash" dense>
        <v-col cols="12">
          <v-text-field
            v-model="form.name"
            :rules="[v => !!v || 'Укажите ФИО']"
            label="Фамилия Имя Отчество"
            name="name"
          />
        </v-col>
        <v-col cols="12" md="4">
          <PhoneField v-model="form.phone" label="Телефон" name="phone" type="phone" />
        </v-col>
        <v-col cols="12" md="8">
          <EmailField
            v-model="form.email"
            required
            label="Электронная почта"
            name="email"
            type="email"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-text-field
            v-model="form.comment"
            label="Комментарий или пожелание"
            name="comment"
            autocomplete="off"
          />
        </v-col>
      </v-row>

      <v-row v-if="!service.freeOfCharge" dense>
        <v-col cols="12">
          Стоимость бронирования
          <div class="big-sum primary--text">
            <div v-if="rentAmount">{{ `${rentAmount} ₽` }}</div>
            <div v-else>{{ `От ${minAmount} ₽` }}</div>
          </div>
        </v-col>
      </v-row>

      <div class="mt-6 d-flex flex-column gap-6">
        <div v-if="service.onlyForRFResidents">
          <v-checkbox
            v-model="form.confirmRFResidents"
            label="Я подтверждаю, что являюсь резидентом РФ"
            :rules="[value => value || 'Заказ услуги доступен только для резидентов РФ']"
            hide-details="auto"
          />
        </div>

        <v-card-actions class="pa-0">
          <v-btn color="t-grey" :disabled="saving" outlined @click="$emit('back')">
            {{ 'Назад' }}
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :loading="saving"
            :disabled="service.onlyForRFResidents && !form.confirmRFResidents"
          >
            {{ rentAmount ? `Забронировать за ${rentAmount} ₽` : 'Забронировать' }}
          </v-btn>
        </v-card-actions>

        <AcceptTerms :service="service" />
      </div>
    </v-form>
  </ServiceViewPage>
</template>

<script>
import dayjs from 'dayjs';
import { actions, getters, state } from '@/store/store';
import { TARIFFS } from '@/constants/tarrifs';
import SlotToggle from '@/components/ui/SlotToggle.vue';
import PhoneField from '@/components/ui/PhoneField.vue';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import EmailField from '@/components/ui/EmailField.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import IconDoorIn from '@/components/icons/IconDoorIn.vue';
import OrderDateInput from '@/components/order/OrderDateInput.vue';
import AcceptTerms from '@/components/terms/AcceptTerms.vue';
import ServiceViewPage from '@/views/services/views/ServiceViewPage.vue';

export default {
  name: 'BookingForm',

  components: {
    ServiceViewPage,
    AcceptTerms,
    OrderDateInput,
    IconDoorIn,
    SvgIcon,
    EmailField,
    BlockTitle,
    PhoneField,
    SlotToggle,
  },

  data: () => ({
    saving: false,
    isAccepted: false,
    form: {
      date: null,
      slots: [],
      name: undefined,
      phone: undefined,
      email: undefined,
      comment: null,
      amount: 0,
      confirmRFResidents: undefined,
    },
  }),

  computed: {
    service() {
      return state.selectedService;
    },

    userHash() {
      return state.userHash;
    },

    subtitle() {
      if (this.service.type !== 'booking' || this.service.freeOfCharge) {
        return this.service.title;
      }

      const { minSlotCount, timeSlotDuration } = this.service.booking;
      const minPrice = minSlotCount * this.pricePerSlot;
      const minDuration = minSlotCount * (timeSlotDuration / 60);

      return `${minPrice} ₽ за ${minDuration} мин.`;
    },

    slotsForDate() {
      const t1 = dayjs.tz(this.form.date).unix();
      const t2 = t1 + 86400;
      return getters.getServiceSlots(this.service.id, t1, t2);
    },

    userIsResident() {
      return false;
    },

    pricePerSlot() {
      if (!this.service || this.service.freeOfCharge) {
        return 0;
      }

      const key = this.userIsResident ? TARIFFS.REGISTERED_USER : TARIFFS.UNREGISTERED_USER;
      let tariff = this.service.tariffs.find(x => x.name === key);
      if (!tariff) {
        tariff = this.service.tariffs.find(x => x.name === TARIFFS.THE_ONE);
      }

      return tariff?.price;
    },

    minAmount() {
      return this.pricePerSlot * (this.service.booking?.minSlotCount ?? 1);
    },

    rentAmount() {
      return this.pricePerSlot * this.form.slots.length;
    },

    earlyAccess() {
      const { earlyAccess } = this.service.booking;

      return earlyAccess && earlyAccess / 60;
    },
  },

  watch: {
    'form.date'(val) {
      if (val) {
        const periodStart = dayjs.tz(val).startOf('d').unix();
        const periodEnd = periodStart + 86400;
        actions.fetchServiceSlots({ serviceId: this.service.id, periodStart, periodEnd });
      }

      if (this.form.slots.length) {
        this.form.slots = [];
      }
    },
  },

  created() {
    if (this.service.onlyForRFResidents) {
      this.form.confirmRFResidents = true;
    }
  },

  mounted() {
    const periodStart = dayjs.tz().startOf('d').unix();
    const periodEnd = periodStart + 86400 * 7;
    actions.fetchServiceSlots({ serviceId: this.service.id, periodStart, periodEnd });
  },

  methods: {
    validateSlotCount(v) {
      const { minSlotCount, maxSlotCount } = this.service.booking;

      if (!minSlotCount && !maxSlotCount) {
        return true;
      }

      const msg = [
        'Выберите',
        minSlotCount ? `от ${minSlotCount}` : '',
        maxSlotCount ? `до ${maxSlotCount}` : '',
        'элементов',
      ].join(' ');

      const minError = minSlotCount && v.length < minSlotCount;
      const maxError = maxSlotCount && v.length > maxSlotCount;

      return minError || maxError ? msg : true;
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      const payload = {
        ...this.form,
        amount: this.rentAmount,
        source: 'widget',
        serviceId: this.service.id,
        // paymentType: ...,
      };

      try {
        const serviceTicket = await actions.createReservation(payload);
        await actions.handlePayment({ serviceTicket });
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
