<template>
  <div>
    <div class="mb-2">{{ label }}</div>
    <v-input :value="modelValue" v-bind="$attrs">
      <div class="d-flex flex-wrap ma-n1">
        <div v-for="date of upcomingSlots" :key="String(date)" class="ma-1">
          <SlotBlock
            :active="date === modelValue"
            :disabled="!allowedDates(date)"
            width="132"
            height="58"
            @click="$emit('update:modelValue', date)"
          >
            <div>
              {{ date | date('dayAndMonth') }}
            </div>
            <div class="font-weight-regular">
              <div v-if="date === todayYmd">
                {{ $t('сегодня') }}
              </div>
              <div v-else>
                {{ date | date('weekday') }}
              </div>
            </div>
          </SlotBlock>
        </div>

        <SlotBlock
          :active="modelValue && !upcomingSlots.includes(modelValue)"
          class="ma-1"
          width="192"
          height="58"
          @click="$refs.dp.open()"
        >
          <div class="d-flex align-center">
            <div style="width: 0; opacity: 0">
              <DatePicker
                ref="dp"
                :value="modelValue"
                :min="todayYmd"
                :allowed-dates="allowedDates"
                @input="$emit('update:modelValue', $event)"
              />
            </div>

            <div v-if="!modelValue || upcomingSlots.includes(modelValue)">
              {{ $t('Другая дата') }}
            </div>
            <div v-else class="text-center">
              <div>
                {{ modelValue | date('dayAndMonth') }}
              </div>
              <div class="font-weight-regular">
                {{ modelValue | date('weekday') }}
              </div>
            </div>

            <div class="ml-2">
              <v-icon>{{ mdiCalendarOutline }}</v-icon>
            </div>
          </div>
        </SlotBlock>
      </div>
    </v-input>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mdiCalendarOutline } from '@mdi/js';
import { actions, getters } from '@/store/store';
import SlotBlock from '@/components/ui/SlotBlock.vue';
import DatePicker from '@/components/ui/DatePicker.vue';

export default {
  name: 'OrderDateInput',

  components: { DatePicker, SlotBlock },

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    modelValue: String,
    serviceId: {
      type: Number,
      required: true,
    },
    label: String,
    autoselect: Boolean,
  },

  data: () => ({
    upcomingSlots: [],
    requests: {},
    mdiCalendarOutline: mdiCalendarOutline,
  }),

  computed: {
    workDates() {
      return getters.getWorkDates(this.serviceId);
    },

    todayDt() {
      return dayjs.tz().startOf('day');
    },

    todayYmd() {
      return this.todayDt.format('YYYY-MM-DD');
    },
  },

  watch: {
    serviceId: {
      immediate: true,
      async handler() {
        this.requests = [];
        this.upcomingSlots = [];

        await this.requestMonth(this.todayDt.format('YYYY-MM-DD'));
        await this.requestMonth(this.todayDt.add(1, 'month').format('YYYY-MM-DD'));
        this.upcomingSlots = this.generateUpcomingSlots();
      },
    },

    upcomingSlots: {
      immediate: true,
      handler() {
        if (!this.modelValue && this.autoselect && this.upcomingSlots.length) {
          this.$emit('update:modelValue', this.upcomingSlots[0]);
        }
      },
    },
  },

  methods: {
    requestMonth(date) {
      const ym = date.substring(0, 7); // ymd
      if (!(ym in this.requests)) {
        this.requests[ym] = actions.fetchWorkDatesMonth({ serviceId: this.serviceId, date });
      }
      return this.requests[ym];
    },

    generateUpcomingSlots() {
      const slots = [];
      let i = 0;
      do {
        const date = this.todayDt.add(i, 'd').format('YYYY-MM-DD');
        if (this.allowedDates(date)) {
          slots.push(date);
        }
        i++;
      } while (slots.length !== 4 && i < 14);
      return slots;
    },

    allowedDates(val) {
      this.requestMonth(val);
      return this.workDates.includes(val);
    },
  },
};
</script>
