<template>
  <div>
    <v-list dense class="py-0">
      <v-list-item class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiCalendar }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-subtitle-1 primary--text">
          {{ event.startAt | date('dateLongWithWeekday') }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiClockOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="text-subtitle-1 font-weight-regular primary--text"
            :class="{ 'mb-2': !compact && event.checkInDuration && !isEnded }"
          >
            {{ event.startAt | time }} — {{ event.endAt | timeTo }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="!compact && event.checkInDuration && !isEnded"
            class="text-body-2 font-weight-regular"
          >
            ({{ $t('вход возможен с') }} {{ (event.startAt - event.checkInDuration * 60) | time }})
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="event.category" class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiProjectorScreenOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-subtitle-1 primary--text">
          {{ event.category.name }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="event.registrationType === 'internal'" class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiCash }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          class="text-subtitle-1"
          :class="[isAdmin ? 't-grey--text' : 'primary--text']"
        >
          <template v-if="!isAdmin">
            {{
              event.prices && event.prices[tariff]
                ? $n(event.prices[tariff], 'currency')
                : $t('Бесплатно')
            }}
          </template>
          <template v-else>
            <EventPrices :prices="event.prices" />
          </template>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!compact && ticketsInfo" class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiTicketOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          class="text-subtitle-1"
          :class="[isAdmin ? 't-grey--text' : 'primary--text']"
        >
          {{ ticketsInfo }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="event.place" class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiMapMarkerOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-subtitle-1 t-grey--text">
          {{ event.place }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="!compact && event.streamTitle" class="px-0">
        <v-list-item-icon>
          <v-icon>{{ mdiCast }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="text-body-1 t-grey--text">
          <component
            :is="event.streamUrl ? 'a' : 'div'"
            class="text--secondary"
            :href="event.streamUrl"
            target="_blank"
          >
            {{ event.streamTitle }}
          </component>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <slot />
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiCash,
  mdiCast,
  mdiClockOutline,
  mdiMapMarkerOutline,
  mdiProjectorScreenOutline,
  mdiTicketOutline,
} from '@mdi/js';
import { EventStatus, EventTicketTariff } from '@/constants/event';
import EventPrices from '@/components/event/EventPrices';

export default {
  name: 'EventInfo',

  components: { EventPrices },

  props: {
    event: {
      type: Object,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    tariff: {
      type: String,
      default: EventTicketTariff.GUEST,
    },
  },

  computed: {
    mdiCast() {
      return mdiCast;
    },
    mdiMapMarkerOutline() {
      return mdiMapMarkerOutline;
    },
    mdiTicketOutline() {
      return mdiTicketOutline;
    },
    mdiCash() {
      return mdiCash;
    },
    mdiProjectorScreenOutline() {
      return mdiProjectorScreenOutline;
    },
    mdiClockOutline() {
      return mdiClockOutline;
    },
    mdiCalendar() {
      return mdiCalendar;
    },

    isEnded() {
      return [EventStatus.PASSED, EventStatus.CANCELED].includes(this.event.status);
    },

    ticketsLeft() {
      return this.event.participantsLimit
        ? this.event.participantsLimit - this.event.ticketCount
        : undefined;
    },

    ticketsInfo() {
      if (!this.event.participantsLimit) {
        return null;
      }

      if (this.isAdmin) {
        return `${this.event.ticketCount} / ${this.event.participantsLimit}`;
      }

      return this.ticketsLeft > 0 ? this.ticketsLeft : this.$t('Мест нет');
    },
  },
};
</script>
