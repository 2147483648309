<template>
  <div>
    <div class="mb-4 text-h1">{{ $t('Ближайшие мероприятия') }}</div>

    <div class="mt-4">
      <v-row class="ev-row">
        <v-col v-for="item in items" :key="item.id" cols="12" md="6" lg="4" xl="3">
          <EventCard class="ev-card" :event="item" :tariff="tariff" @click="onClick(item)" />
        </v-col>
      </v-row>
    </div>

    <div v-if="url" class="mt-4">
      <a :href="url">{{ $t('Посмотреть все мероприятия') }} →</a>
    </div>
  </div>
</template>

<script>
import { state } from '@/store/eventStore';
import { EventTicketTariff } from '@/constants/event';
import EventCard from '@/components/event/EventCard.vue';

export default {
  name: 'LastEvents',

  components: {
    EventCard,
  },

  props: {
    url: String,
  },

  computed: {
    items() {
      return state.events;
    },
    tariff() {
      return EventTicketTariff.GUEST;
    },
  },

  methods: {
    onClick(item) {
      state.currentEvent = item;
    },
  },
};
</script>
