<template>
  <v-menu
    v-model="dateMenu"
    v-resize="calcNudge"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :nudge-left="nudgeLeft"
    :nudge-top="nudgeTop"
    :top="top"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="displayValue"
        :label="label"
        :placeholder="placeholder"
        :clearable="clearable"
        :disabled="disabled"
        :rules="rules"
        :append-icon="appendIcon"
        readonly
        :class="inputClass"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...on }"
        @click:append="$listeners['click:append'] || on.click"
        @click:clear="$emit('input', null)"
      />
    </template>
    <v-date-picker
      ref="picker"
      :value="value"
      :max="max"
      :min="min"
      locale="ru"
      :range="range"
      :allowed-dates="allowedDates"
      :type="type"
      first-day-of-week="1"
      no-title
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import dayjs from 'dayjs';
import { capitalize } from '@/utils/string';
import { mdiCalendarMonthOutline } from '@mdi/js';

export default {
  name: 'DatePicker',

  inheritAttrs: false,

  props: {
    allowedDates: [Array, Object, Function],
    appendIcon: {
      type: String,
      default: mdiCalendarMonthOutline,
    },
    clearable: Boolean,
    disabled: {
      type: Boolean,
      default: undefined,
    },
    display: String,
    inputClass: String,
    label: String,
    max: String,
    min: String,
    placeholder: String,
    range: Boolean,
    rules: Array,
    top: {
      type: Boolean,
      default: undefined,
    },
    type: String,
    value: [String, Array],
  },

  data: () => ({
    dateMenu: false,
    selected: 0,
    mdiCalendarMonthOutline: mdiCalendarMonthOutline,
    nudgeLeft: 0,
    nudgeTop: 0,
  }),

  computed: {
    displayValue() {
      if (this.display !== undefined) {
        return this.display;
      }

      if (!this.value) {
        return '';
      }

      if (Array.isArray(this.value)) {
        return this.value.map(x => dayjs.tz(x).format('DD.MM.YYYY')).join(' - ');
      }

      if (this.type === 'month') {
        return capitalize(dayjs.tz(this.value));
      }

      return dayjs.tz(this.value).format('DD.MM.YYYY');
    },
  },

  mounted() {
    this.calcNudge();
  },

  methods: {
    open() {
      this.dateMenu = true;
    },

    calcNudge() {
      // const wrap = document.querySelector('.wrap');
      // this.nudgeLeft = wrap
      //   ? (document.body.clientWidth - document.querySelector('.wrap').clientWidth) / 2
      //   : 0;
      //
      // this.nudgeTop = 0;
      // const header = document.querySelector('.subheader');
      // const content = document.querySelector('#content');
      // if (header) {
      //   this.nudgeTop += header.clientHeight;
      // }
      // if (content) {
      //   this.nudgeTop += parseInt(getComputedStyle(content).paddingTop, 10);
      // }
    },

    onInput(val) {
      this.selected++;

      this.$emit('input', val);

      if (!this.range) {
        this.dateMenu = false;
        this.selected = 0;
      }

      if (this.range && this.selected === 2) {
        this.dateMenu = false;
        this.selected = 0;
      }
    },
  },
};
</script>
