<template>
  <div>
    <v-row v-if="serviceTicket.rentUnitId" dense>
      <v-col cols="6">{{ $t('Помещение') }}</v-col>
      <v-col cols="6" class="text-right">{{ rentUnit && rentUnit.name }}</v-col>
    </v-row>
    <v-row v-if="serviceTicket.comment" dense>
      <v-col cols="6">{{ $t('Комментарий') }}</v-col>
      <v-col cols="6" class="text-right">{{ serviceTicket.comment }}</v-col>
    </v-row>
    <template v-if="serviceTicket.dynamicFields">
      <v-row v-for="(item, idx) of serviceTicket.dynamicFields" :key="idx" dense>
        <v-col cols="6" :class="{ 'font-weight-medium': item.inputType === 'table' }">
          {{ item.name }}
        </v-col>
        <v-col cols="6" class="d-flex justify-end text-right">
          <DynamicFieldValue :item="item" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import DynamicFieldValue from '@/components/dynamic-fields/DynamicFieldValue';

export default {
  name: 'RequestTicketDetailsBlock',

  components: {
    DynamicFieldValue,
  },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    rentUnit() {
      return null;
    },
  },

  mounted() {
    // if (this.serviceTicket.rentUnitId && !this.rentUnit) {
    //   this.fetchUnit({ id: this.serviceTicket.rentUnitId });
    // }
  },
};
</script>
