<script>
export default {
  name: 'FilterField',
  functional: true,
  render(h, context) {
    const { children } = context;
    const node = children[0];
    const { tag, propsData } = node.componentOptions;

    const props = {
      backgroundColor: tag !== 'v-checkbox' ? 't-back-light' : null,
      clearable: true,
      dense: true,
      hideDetails: true,
      outlined: true,
      placeholder:
        propsData.label ||
        node.data.attrs.label ||
        propsData.placeholder ||
        node.data.attrs.placeholder,
      singleLine: true,
    };

    node.componentOptions.propsData = { ...props, ...propsData };
    node.data.attrs = { ...props, ...node.data.attrs };

    return node;
  },
};
</script>
