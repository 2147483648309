<template>
  <OrderBase>
    <template #menu>
      <OrderMenu :service-ticket="serviceTicket" />
    </template>

    <template #subtitle>
      <ReadyTime v-if="serviceTicket.order" :ready-time="serviceTicket.readyTime" />
    </template>

    <template v-if="service.deliveryType === 'self_pickup'" #after-status>
      <OrderStatusBox class="mt-2">
        <v-icon class="mr-3" color="t-additional">mdi-alert-circle-outline</v-icon>
        <div class="py-2">
          {{ $t('Данный заказ можно получить только через мобильное приложение.') }}
          <br />
          {{ service.stock && service.stock.pickupDescription }}
        </div>
      </OrderStatusBox>
    </template>

    <v-row>
      <v-col cols="12">
        <Wrapper>
          <OrderBlock :title="$t('Заказ')">
            <CoffeeBreakOrderItems :items="serviceTicket.order.items" />
            <OrderItemsTotalPrice :price="serviceTicket.totalPrice" />
          </OrderBlock>
        </Wrapper>
      </v-col>

      <v-col cols="12">
        <Wrapper>
          <OrderBlock v-if="serviceTicket.deliveryPlace" :title="$t('Место доставки')">
            <div class="d-flex justify-space-between">
              <div class="mr-2">
                {{ serviceTicket.deliveryPlace }}
                <div v-if="serviceTicket.needToPack" class="mt-2 d-flex align-center">
                  <v-icon class="mr-2">mdi-food-takeout-box-outline</v-icon>
                  {{ $t('Упаковать с собой') }}
                </div>
                <div v-if="serviceTicket.servedOnTable" class="mt-2 d-flex align-center">
                  <v-icon class="mr-2">mdi-table-chair</v-icon>
                  {{ $t('Принести за столик') }}
                </div>
              </div>
              <div
                v-if="serviceTicket.status === $stStatus.PACKED && serviceTicket.pickupFromPoint"
                class="primary--text"
              >
                {{ $t('Ожидает в кофейне') }}
              </div>
            </div>
          </OrderBlock>
        </Wrapper>
      </v-col>

      <!--<v-col v-if="serviceTicket.service.showLinkedServices && isPayed" cols="12">
        <v-divider class="pb-4" />
        <Wrapper class="mb-2 font-weight-bold">
          {{ serviceTicket.service.linkedServicesHeader }}
        </Wrapper>
        <LinkedServices :service-ticket="serviceTicket" />
      </v-col>-->
    </v-row>
  </OrderBase>
</template>

<script>
import { serviceTicketMixin } from '@/views/home/orders/mixins/serviceTicketMixin';
import OrderBase from '@/views/home/orders/order/OrderBase';
import OrderBlock from '@/components/order/OrderBlock';
import Wrapper from '@/components/ui/Wrapper';
import CoffeeBreakOrderItems from '@/components/order/coffee-break/CoffeeBreakOrderItems';
import OrderItemsTotalPrice from '@/components/order/OrderItemsTotalPrice';
import OrderMenu from '@/views/home/orders/order/menu/OrderMenu';
import ReadyTime from '@/components/order/ReadyTime';
import OrderStatusBox from '@/components/order/OrderStatusBox.vue';

export default {
  name: 'OrderCoffeeBreak',

  components: {
    OrderStatusBox,
    ReadyTime,
    OrderMenu,
    OrderItemsTotalPrice,
    CoffeeBreakOrderItems,
    Wrapper,
    OrderBlock,
    OrderBase,
  },

  mixins: [serviceTicketMixin],
};
</script>
