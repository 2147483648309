<template>
  <div>
    <Wrapper class="mb-6">
      <h1 class="text-h1 flex-grow-1">{{ event.name }}</h1>
    </Wrapper>
    <Wrapper>
      <MediaCarousel :images="event.images" />
    </Wrapper>
    <Wrapper class="mt-6">
      <Tags :tags="event.tags" @click:tag="$emit('click:tag', $event)" />
    </Wrapper>
    <Wrapper class="mt-8">
      <EditorContent :content="event.description" />
    </Wrapper>
  </div>
</template>

<script>
import { EventTicketStatus } from '@/constants/event';
import Wrapper from '@/components/ui/Wrapper';
import MediaCarousel from '@/components/ui/MediaCarousel';
import Tags from '@/components/ui/Tags';
import EditorContent from '@/components/ui/EditorContent';

export default {
  name: 'EventContent',

  components: {
    EditorContent,
    Tags,
    MediaCarousel,
    Wrapper,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
    liked: Boolean,
    subscribed: Boolean,
    showAdminInfo: {
      type: Boolean,
      default: false,
    },
    ticket: Object,
  },

  computed: {
    hasActiveTicket() {
      return this.ticket && this.ticket.status !== EventTicketStatus.CANCELED;
    },
  },
};
</script>
