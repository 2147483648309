<template>
  <div>
    <DotsMenu v-if="isCancellable">
      <v-list>
        <!--<v-list-item
          v-if="isRepeatable"
          :to="{
            name: 'services.service',
            params: { id: serviceTicket.service.id },
            query: { repeat: serviceTicket.id },
          }"
        >
          {{ $t('Повторить заказ') }}
        </v-list-item>-->
        <v-list-item v-if="isCancellable" @click="cancelDialog = true">
          {{ cancelMenuTitle }}
        </v-list-item>
      </v-list>
    </DotsMenu>

    <ConfirmDialog
      v-if="isCancellable"
      v-model="cancelDialog"
      :title="cancelDialogTitle"
      :submit="cancel"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { actions } from '@/store/orders';
import { ServiceType } from '@/constants/service';
import { stStatus } from '@/constants/stStatus';
import { TicketState } from '@/constants/ticketState';
import DotsMenu from '@/components/ui/DotsMenu';
import ConfirmDialog from '@/components/ui/ConfirmDialog';

export default {
  name: 'OrderMenu',

  components: {
    ConfirmDialog,
    DotsMenu,
  },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
    cancelDialogTitle: {
      type: String,
      default() {
        return this.$t('Вы действительно хотите отменить заказ?');
      },
    },
    cancelMenuTitle: {
      type: String,
      default() {
        return this.$t('Отменить заказ');
      },
    },
    noRepeat: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    cancelDialog: false,
  }),

  computed: {
    unixtime() {
      return dayjs().unix();
    },

    isCancellable() {
      const st = this.serviceTicket;
      const service = st.service;

      if ([stStatus.CANCELED, stStatus.CLOSED].includes(st.status)) {
        return false;
      }

      if ([stStatus.CREATING, stStatus.INVOICING].includes(st.status)) {
        return true;
      }

      const isPersonalNewNotPayed =
        String(st.payer).startsWith('pe-') &&
        st.status === this.$stStatus.NEW &&
        (!st.paymentStatus || st.paymentStatus === this.$PaymentStatus.CREATED);

      const isCompanyConfirmed =
        String(st.payer).startsWith('co-') && st.status === this.$stStatus.CONFIRMED;

      if (isPersonalNewNotPayed || isCompanyConfirmed) {
        return true;
      }

      let isAllowCancelOrder =
        service.userCanCancelConfirmed && service.refundingStatuses.includes(st.status);
      if (!isAllowCancelOrder) {
        return false;
      }

      if (service.type === ServiceType.COFFEE_BREAK) {
        return true;
      }

      if (!service.unableToCancelMinutes) {
        return true;
      }

      let startAt = 0;
      if (service.type === ServiceType.BOOKING) {
        startAt = st.reservation.slots[0];
      }
      if (service.type === ServiceType.COWORKING) {
        startAt = st.coworkingTickets[0].startAt;
      }
      if (service.type === ServiceType.HOTEL) {
        startAt = st.hotelTickets[0].startAt;
      }

      const minutesBeforeStart = 60 * service.unableToCancelMinutes;
      const timeBefore = startAt - minutesBeforeStart;

      return startAt && this.unixtime < timeBefore;
    },

    isRepeatable() {
      return !this.noRepeat;
    },
  },

  methods: {
    async cancel() {
      await actions
        .updateState({
          id: this.serviceTicket.id,
          state: TicketState.CANCELED,
        })
        .finally(() => (this.cancelDialog = false));
    },
  },
};
</script>
