<template>
  <BtnPrimary :loading="loading" rounded @click="onClick">
    <slot />
  </BtnPrimary>
</template>

<script>
import { actions } from '@/store/orders';
import { TicketState } from '@/constants/ticketState';
import BtnPrimary from '@/components/ui/BtnPrimary';

export default {
  name: 'ChangeStateButton',

  components: { BtnPrimary },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
    newState: String,
  },

  data: () => ({
    loading: false,
  }),

  methods: {
    async onClick() {
      this.loading = true;

      let state = this.newState;
      if (!state || state === 'closed') {
        state =
          this.serviceTicket.ticket.state === TicketState.PENDING_AUTO_CLOSE_MINUS
            ? TicketState.CLOSED_UNSUCCESSFUL_CUSTOMER
            : TicketState.CLOSED_SUCCESSFUL_CUSTOMER;
      }

      await actions
        .updateState({
          id: this.serviceTicket.id,
          state: state,
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
