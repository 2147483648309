<template>
  <div class="pl-6 pr-6">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Wrapper',
};
</script>
