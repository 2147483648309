<template>
  <v-container>
    <v-row>
      <v-col v-for="unit in units" :key="unit.id" cols="12" md="6" lg="4" xl="3">
        <TerritoryCard :unit="unit" @click="selectUnit(unit.id)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { state } from '@/store/plan';
import TerritoryCard from '@/components/plan/TerritoryCard.vue';

export default {
  name: 'TerritoryList',

  components: { TerritoryCard },

  computed: {
    units() {
      return state.structList.filter(x => x.parentIds === null);
    },
  },

  methods: {
    selectUnit(id) {
      state.selectedUnitId = id;
    },
  },
};
</script>
