import Vue from 'vue';
import dayjs from 'dayjs';
import http from '@/api/http';
import { PaymentType } from '@/constants/payment';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const authUrl = host + '/' + process.env.VUE_APP_API_AUTH.replace(/^\/+|\/+$/g, '');
const eventUrl = host + '/' + process.env.VUE_APP_API_EVENT.replace(/^\/+|\/+$/g, '');
const otrsUrl = host + '/' + process.env.VUE_APP_API_OTRS.replace(/^\/+|\/+$/g, '');

const initialState = {
  type: 'event-list', // event-list | event-view
  location: null,
  locationId: null,
  eventId: null,
  params: {},
  events: [],
  meta: {},
  categories: [],
  currentEvent: null,
  token: localStorage.getItem('serviceTicketToken'),
  serviceTicket: null,
};

export const state = Vue.observable(initialState);

export const getters = {};

export const actions = {
  initData({ locationId, userHash, eventId }) {
    state.locationId = locationId;
    http.setLocationId(locationId);

    if (eventId) {
      state.type = 'event-view';
      state.eventId = eventId;
    }

    if (userHash) {
      state.userHash = userHash;
      http.setUserHash(userHash);
    }
  },

  async fetchLocationInfo() {
    if (!state.location && state.locationId) {
      state.location = await http.get(`${authUrl}/location/${state.locationId}`);
      if (state.location) {
        dayjs.tz.setDefault(state.location.city.timezone);
      }
    }
  },

  async fetchEvents(params) {
    const data = await http.get(`${eventUrl}/widget/event`, {
      params: {
        ...params,
        status: 'upcoming,current',
        sort: 'start_at',
      },
    });
    state.params = params;
    if ('items' in data) {
      const { items, ...meta } = data;
      state.meta = meta;
      state.events = items;
    } else {
      state.events = data;
    }
  },

  async fetchCategories() {
    if (!state.categories.length) {
      state.categories = await http.get(`${eventUrl}/widget/category`);
    }
  },

  async createEventTicket(eventId, data) {
    return http.post(`${eventUrl}/widget/event/${eventId}/ticket`, data);
  },

  async handlePayment(ticket) {
    const { serviceTicket } = ticket;

    if (serviceTicket.paymentType === PaymentType.ONLINE) {
      const payment = await http.post(
        `${otrsUrl}/servicing/token-access/service-ticket/${serviceTicket.token}/payment`,
        { source: 'widget' }
      );
      localStorage.setItem('serviceTicketToken', serviceTicket.token);
      window.location.href = payment.redirectUrl;
    } else {
      state.token = serviceTicket.token;
      state.serviceTicket = serviceTicket;
    }
  },

  async fetchServiceTicket() {
    try {
      state.serviceTicket = await http.get(
        `${otrsUrl}/servicing/token-access/service-ticket/${state.token}`
      );
    } catch (e) {
      state.serviceTicket = null;
      state.token = null;
      localStorage.removeItem('serviceTicketToken');
    }
  },

  async fetchCurrentEvent(id) {
    state.currentEvent = await http.get(`${eventUrl}/widget/event/${id}`);
  },
};

let subscribed = false;
export const subscribeToUpdateServiceTicket = async () => {
  if (!state.token) {
    return;
  }
  if (!subscribed) {
    subscribed = true;
    localStorage.removeItem('serviceTicketToken');
    setTimeout(() => {
      updateServiceTicket();
    }, 2400);
  }
};

const updateServiceTicket = async () => {
  if (!subscribed) {
    return;
  }

  const serviceTicket = await http.get(
    `${otrsUrl}/servicing/token-access/service-ticket/${state.token}`
  );
  if (!serviceTicket) {
    return;
  }

  state.serviceTicket = serviceTicket;

  if (serviceTicket.paymentId && ['created', 'redirected'].includes(serviceTicket.paymentStatus)) {
    setTimeout(() => {
      updateServiceTicket();
    }, 3200);
  }

  // if (serviceTicket.paymentStatus === 'purchased') {
  //   await actions.fetchPayment();
  // }
};

export const unsubscribeToUpdateServiceTicket = async () => {
  subscribed = false;
};
