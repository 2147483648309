<template>
  <div>
    <div v-for="(item, idx) in items" :key="idx" class="py-2">
      <v-icon class="mr-4" color="primary">{{ item.icon }}</v-icon>
      {{ item.text }}
    </div>
  </div>
</template>

<script>
import { mdiAccountBoxOutline, mdiCommentOutline, mdiEmailOutline, mdiPhoneOutline } from '@mdi/js';

export default {
  name: 'CustomerInfo',

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      const { customer, comment } = this.serviceTicket;

      return [
        { icon: mdiAccountBoxOutline, text: customer.name },
        { icon: mdiPhoneOutline, text: customer.phone },
        customer.email && { icon: mdiEmailOutline, text: customer.email },
        comment && { icon: mdiCommentOutline, text: comment },
      ].filter(x => x);
    },
  },
};
</script>
