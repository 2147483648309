<template>
  <div>
    <PageHeader :title="service.name" :subtitle="subtitle" />

    <v-row>
      <v-col cols="12" md="8">
        <BlockTitle class="mb-5">Описание и характеристики</BlockTitle>
        <EditorContent class="mt-4" :content="service.description" />

        <v-form ref="form" :disabled="saving" @submit.prevent="onSubmit">
          <BlockTitle class="mt-5 mb-5">Новое бронирование</BlockTitle>

          <v-row dense>
            <v-col cols="12">
              <CoworkingCommonForm ref="commonForm" v-model="form" :service="service" />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="form.name"
                :rules="[v => !!v || 'Укажите ФИО']"
                label="Фамилия Имя Отчество"
                name="name"
              />
            </v-col>
            <v-col cols="12" md="4">
              <PhoneField v-model="form.phone" label="Телефон" name="phone" type="phone" />
            </v-col>
            <v-col cols="12" md="8">
              <EmailField
                v-model="form.email"
                required
                label="Электронная почта"
                name="email"
                type="email"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="form.paymentType"
                label="Способ оплаты"
                :items="paymentTypeItems"
              />
            </v-col>
            <v-col v-if="form.paymentType === PaymentType.WIRE_PAYMENT" cols="12">
              <ExternalCompanyForm v-model="externalCompanyForm" />
            </v-col>
          </v-row>

          <div class="mt-6 d-flex flex-column gap-6">
            <div v-if="service.onlyForRFResidents">
              <v-checkbox
                v-model="form.confirmRFResidents"
                :label="
                  form.quantity > 1
                    ? 'Я подтверждаю, что все посетители являются резидентами РФ'
                    : 'Я подтверждаю, что посетитель является резидентом РФ'
                "
                :rules="[value => value || 'Заказ услуги доступен только для резидентов РФ']"
                hide-details="auto"
              />
            </div>

            <div class="d-flex">
              <v-card-actions class="pa-0">
                <v-btn color="t-grey" :disabled="saving" outlined @click="$emit('back')">
                  {{ 'Назад' }}
                </v-btn>
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="saving"
                  :disabled="service.onlyForRFResidents && !form.confirmRFResidents"
                >
                  {{ 'Забронировать' }}{{ totalPrice ? ` за ${totalPrice} ₽` : '' }}
                </v-btn>
              </v-card-actions>

              <div v-if="form.paymentType === PaymentType.WIRE_PAYMENT" class="ml-4 text-body-2">
                <div class="primary--text">Информация для Юридических лиц.</div>
                <div v-if="service.acceptGuaranteeLetter" class="text--secondary">
                  Для подтверждения и оплаты заказа с вами свяжется менеджер. Просим вас оплатить
                  заказ до начала бронирования или иметь при себе гарантийное письмо об оплате
                  заказа по прибытии.
                </div>
                <div v-else class="text--secondary">
                  Для подтверждения и оплаты заказа с вами свяжется менеджер. Просим вас оплатить
                  заказ до начала бронирования.
                </div>
              </div>
            </div>

            <AcceptTerms :service="service" />
          </div>
        </v-form>
      </v-col>

      <v-col cols="12" md="4">
        <AsideInfo :service="service" :variant="variant">
          <template v-if="minTariffPrice" #price>
            <div v-for="item in minTariffPrice" :key="item.priceUnit">
              от {{ `${item.price} ₽` }} / {{ PriceUnitLabel[item.priceUnit] }}
            </div>
          </template>
        </AsideInfo>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { actions, state } from '@/store/store';
import { PriceUnitLabel } from '@/constants/serviceTariff';
import { PaymentType } from '@/constants/payment';
import PhoneField from '@/components/ui/PhoneField.vue';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import EmailField from '@/components/ui/EmailField.vue';
import PageHeader from '@/components/ui/PageHeader.vue';
import EditorContent from '@/components/ui/EditorContent.vue';
import AcceptTerms from '@/components/terms/AcceptTerms.vue';
import CoworkingCommonForm from '@/components/coworking/form/CoworkingCommonForm.vue';
import ExternalCompanyForm from '@/components/payer/ExternalCompanyForm.vue';
import AsideInfo from '@/components/tariff/AsideInfo.vue';

export default {
  name: 'CoworkingForm',

  components: {
    AsideInfo,
    ExternalCompanyForm,
    CoworkingCommonForm,
    AcceptTerms,
    EditorContent,
    PageHeader,
    EmailField,
    BlockTitle,
    PhoneField,
  },

  refs: ['commonForm'],

  data: () => ({
    saving: false,
    form: {
      quantity: 1,
      serviceVariantId: null,
      priceUnit: null,
      hourDate: null,
      hourSlots: [],
      dayPeriodStart: null,
      dayPeriodEnd: null,
      tariffModifierIds: [],
      name: null,
      phone: null,
      email: null,
      paymentType: PaymentType.ONLINE,
      confirmRFResidents: undefined,
    },
    externalCompanyForm: {
      name: null,
      inn: null,
      kpp: null,
      fio: null,
      position: null,
      phone: null,
      email: null,
    },
    PaymentType: PaymentType,
    PriceUnitLabel: PriceUnitLabel,
  }),

  computed: {
    service() {
      return state.selectedService;
    },

    subtitle() {
      return this.service.title;
    },

    paymentTypeItems() {
      return [
        { text: 'Банковская карта', value: PaymentType.ONLINE },
        { text: 'Безналичный расчет для Юридических лиц', value: PaymentType.WIRE_PAYMENT },
      ];
    },

    variants() {
      return this.$refs.commonForm?.variants ?? [];
    },

    variant() {
      return this.$refs.commonForm?.variant;
    },

    minTariffPrice() {
      return this.$refs.commonForm?.minTariffPrice;
    },

    currentTariff() {
      return this.$refs.commonForm?.currentTariff;
    },

    period() {
      return this.$refs.commonForm?.period;
    },

    totalPrice() {
      return this.$refs.commonForm?.totalPrice;
    },
  },

  created() {
    if (this.service.onlyForRFResidents) {
      this.form.confirmRFResidents = true;
    }
  },

  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      const payload = {
        serviceId: this.service.id,
        serviceVariantId: this.form.serviceVariantId,
        quantity: this.form.quantity,
        tariffId: this.currentTariff.id,
        tariffModifierIds: this.form.tariffModifierIds,
        startAt: this.period.startAt,
        endAt: this.period.endAt,
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        paymentType: this.form.paymentType,
      };

      if (payload.paymentType === PaymentType.WIRE_PAYMENT) {
        payload.externalPayer = this.externalCompanyForm;
      }

      try {
        const serviceTicket = await actions.createCoworking(payload);
        await actions.handlePayment({ serviceTicket });
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
