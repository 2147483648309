<template>
  <div>{{ value }}</div>
</template>

<script>
export default {
  name: 'TextValue',
  props: {
    value: String,
  },
};
</script>
