<template>
  <v-alert :color="color" class="pa-4 warning elevation-3" outlined>
    <div v-if="title" class="mb-2 text-body-2 font-weight-bold black--text">
      {{ title }}
    </div>
    <div class="text-body-2 text--primary">
      <slot />
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'TAlert',

  props: {
    title: String,
    color: {
      type: String,
      default: 'warning',
    },
  },
};
</script>
