<template>
  <div class="d-flex flex-column" style="gap: 8px">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OrderPositions',
};
</script>
