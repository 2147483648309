<template>
  <div>
    <div class="mb-2">{{ label }}</div>
    <v-input :value="modelValue" :rules="rules" :hide-details="hideDetails">
      <v-item-group
        :value="modelValue"
        :mandatory="mandatory"
        class="d-flex flex-wrap gap-2"
        @change="$emit('update:modelValue', $event)"
      >
        <div v-for="item in items" :key="item[itemValue]">
          <v-item v-slot="{ active, toggle }" :value="item[itemValue]">
            <SlotBlock :active="active" :height="height" @click="toggle">
              {{ item[itemText] }}
            </SlotBlock>
          </v-item>
        </div>
      </v-item-group>
    </v-input>
  </div>
</template>

<script>
import SlotBlock from '@/components/ui/SlotBlock';

export default {
  name: 'SlotSelect',

  components: { SlotBlock },

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    height: {
      type: [Number, String],
      default: 40,
    },
    hideDetails: Boolean,
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    label: String,
    rules: Array,
    mandatory: Boolean,
    modelValue: [Number],
    items: Array,
  },
};
</script>
