<template>
  <div>
    <div class="mb-2">{{ label }}</div>
    <v-input
      :value="modelValue"
      :rules="(isToday && nowAllowed) || !date ? [] : rules"
      v-bind="$attrs"
    >
      <div class="d-flex flex-wrap ma-n1">
        <SlotBlock
          v-if="isToday && nowAllowed"
          :active="!modelValue"
          class="ma-1 text-no-wrap"
          width="132"
          height="40"
          @click="$emit('update:modelValue', null)"
        >
          {{ $t('По готовности') }}
        </SlotBlock>

        <div v-for="(value, idx) of timeSlotsValues" :key="value" class="ma-1">
          <SlotBlock
            :active="modelValue === value"
            :disabled="!allowedTime(value)"
            width="72"
            height="40"
            @click="$emit('update:modelValue', value)"
          >
            {{ timeSlots[idx] | time }}
          </SlotBlock>
        </div>

        <SlotBlock
          :active="modelValue && !timeSlotsValues.includes(modelValue)"
          class="ma-1"
          width="166"
          height="40"
          @click="$refs.picker.open()"
        >
          <div style="height: 0; opacity: 0">
            <TimePicker
              ref="picker"
              :value="modelValue"
              :min="minTime"
              :allowed-hours="allowedHours"
              :allowed-minutes="allowedMinutes"
              @click:hour="clickedHour = $event"
              @click:minute="clickedMinute = $event"
              @input="$emit('update:modelValue', $event)"
            />
          </div>

          <div class="d-flex align-center">
            <div v-if="!modelValue || timeSlotsValues.includes(modelValue)">
              {{ $t('Другое время') }}
            </div>
            <div v-else class="text-center">
              <div>{{ modelValue }}</div>
            </div>
            <div class="ml-2">
              <v-icon>{{ mdiClockTimeFourOutline }}</v-icon>
            </div>
          </div>
        </SlotBlock>
      </div>
    </v-input>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mdiClockTimeFourOutline } from '@mdi/js';
import { actions, getters } from '@/store/store';
import { DeliveryType } from '@/constants/service';
import SlotBlock from '@/components/ui/SlotBlock';
import TimePicker from '@/components/ui/TimePicker';

export default {
  name: 'OrderTimeInput',

  components: { TimePicker, SlotBlock },

  inheritAttrs: false,

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    date: String,
    isDelivery: {
      type: Boolean,
      default: false,
    },
    label: String,
    modelValue: String,
    rules: Array,
    service: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    clickedHour: null,
    clickedMinute: null,
    mdiClockTimeFourOutline: mdiClockTimeFourOutline,
  }),

  computed: {
    workTime() {
      return getters.getWorkTime(this.service.id, this.date);
    },

    now() {
      let now = dayjs.tz().startOf('minute');

      return now.minute(Math.ceil((now.minute() - 10) / 30) * 30);
    },

    isToday() {
      return this.date === this.now.format('YYYY-MM-DD');
    },

    nowAllowed() {
      return this.allowedTime(this.now.format('HH:mm'));
    },

    timeSlots() {
      if (this.isToday && this.isDelivery && this.deliveryFrom >= this.now) {
        return [0, 30, 60, 90].map(x => this.deliveryFrom.add(x, 'm'));
      }

      if (this.isToday) {
        return [30, 60, 90, 120].map(x => this.now.add(x, 'm'));
      }

      let workBeginTime = this.service.workBeginTime.split(':');
      let dt = dayjs.tz().startOf('d').hour(workBeginTime[0]);

      if (this.isDelivery) {
        dt = this.deliveryFrom;
      } else if (this.workTime) {
        dt = dayjs.unix(this.workTime[0].begin).tz();
      }

      return [0, 30, 60, 90, 120].map(x => dt.add(x, 'm'));
    },

    timeSlotsValues() {
      return this.timeSlots.map(x => x.format('HH:mm'));
    },

    minTime() {
      if (this.isToday) {
        return this.timeSlotsValues[0];
      }
      return null;
    },

    hasDelivery() {
      return [DeliveryType.COURIER, DeliveryType.PICKUP_AND_COURIER].includes(
        this.service.deliveryType
      );
    },

    deliveryFrom() {
      const date = this.date || this.now.format('YYYY-MM-DD');
      return this.hasDelivery ? dayjs.tz(`${date} ${this.service.deliveryBeginTime}`) : null;
    },

    deliveryTo() {
      const date = this.date || this.now.format('YYYY-MM-DD');
      return this.hasDelivery ? dayjs.tz(`${date} ${this.service.deliveryEndTime}`) : null;
    },
  },

  watch: {
    date: {
      immediate: true,
      handler(date) {
        this.$emit('update:modelValue', null);
        if (date) {
          actions.fetchWorkTime({ serviceId: this.service.id, date });
        }
      },
    },

    isDelivery: {
      immediate: true,
      handler() {
        if (this.modelValue && !this.isWorkTime(this.modelValue)) {
          this.$emit('update:modelValue', null);
        }
      },
    },
  },

  methods: {
    allowedTime(val) {
      const [hh, mm] = val.split(':');
      return this.allowedHours(hh) && this.allowMinutes(hh, mm);
    },

    allowedHours(val) {
      const hh = String(val).padStart(2, '0');
      return this.isWorkTime(`${hh}:00`);
    },

    allowedMinutes(val) {
      return this.clickedHour && this.allowMinutes(this.clickedHour, val);
    },

    allowMinutes(h, m) {
      const hh = String(h).padStart(2, '0');
      const mm = String(m).padStart(2, '0');
      return this.isWorkTime(`${hh}:${mm}`);
    },

    isWorkTime(time) {
      if (!this.date) {
        return false;
      }

      if (this.isDelivery) {
        const dt = dayjs.tz(`${this.date} ${time}`);

        return dt >= this.deliveryFrom && dt <= this.deliveryTo;
      }

      if (!this.workTime) {
        return false;
      }

      const ts = dayjs.tz(`${this.date} ${time}`).unix();

      return this.workTime.some(range => ts >= range.begin && ts < range.end);
    },
  },
};
</script>
