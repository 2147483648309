<template>
  <v-btn text :color="color" class="" min-width="75" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: 'TextBtn',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
