<template>
  <PaginatedListContainer
    v-slot="{ loading }"
    :filter="filter"
    :pagination.sync="pagination"
    :fetch-items="fetchItems"
  >
    <v-card rounded="lg" elevation="4">
      <v-form :disabled="loading" class="pa-4 px-6">
        <div class="d-flex align-center">
          <v-row class="row-filter">
            <v-col cols="12" sm="3">
              <FilterField>
                <DatePickerPeriod
                  :start.sync="filter.periodStart"
                  :end.sync="filter.periodEnd"
                  :label="$t('Период')"
                  :max="maxFilterDate"
                />
              </FilterField>
            </v-col>

            <v-col cols="12" sm="3">
              <FilterField>
                <v-select
                  v-model="filter.serviceId"
                  class="one-line"
                  :items="servicesItems"
                  :label="$t('Услуга')"
                  multiple
                />
              </FilterField>
            </v-col>

            <v-col cols="12" sm="3">
              <FilterField>
                <v-select
                  v-model="filter.status"
                  class="one-line"
                  :items="statusItems"
                  :label="$t('Статус')"
                  multiple
                />
              </FilterField>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>

    <v-card rounded="lg" elevation="4" class="mt-4 pa-8">
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :server-items-length="meta.totalCount"
        disable-sort
        hide-default-footer
        item-key="id"
        @click:row="onClickRow($event)"
      >
        <template #item.ticket.createdAt="{ item }">
          {{ item.createdAt | date('long') }}
        </template>

        <template #item.orderNumber="{ item }">
          {{ item | orderNumber }}
        </template>

        <template #item.status="{ item }">
          {{ item | orderStatus(true) }}
        </template>

        <template #footer>
          <div class="v-data-footer justify-start pt-6">
            <Pagination
              v-if="meta.pageCount"
              :key="meta.pageCount"
              v-model="pagination.page"
              :disabled="loading"
              :length="meta.pageCount"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </PaginatedListContainer>
</template>

<script>
import dayjs from 'dayjs';
import { actions, state } from '@/store/orders';
import { stStatus } from '@/constants/stStatus';
import { makeFilter, makePagination } from '@/utils/pagination';
import FilterField from '@/components/ui/filter/FilterField.vue';
import DatePickerPeriod from '@/components/ui/DatePickerPeriod.vue';
import Pagination from '@/components/ui/Pagination.vue';
import PaginatedListContainer from '@/components/common/PaginatedListContainer.vue';

const filterConfig = {
  periodStart: Number,
  periodEnd: Number,
  serviceId: [Number],
  payer: [String],
  status: [String],
};

export default {
  name: 'OrderList',

  components: { PaginatedListContainer, Pagination, DatePickerPeriod, FilterField },

  props: {},

  data() {
    return {
      filter: makeFilter({}, filterConfig),
      pagination: makePagination({}),
    };
  },

  computed: {
    items() {
      return state.orders;
    },

    meta() {
      return state.meta;
    },

    services() {
      return state.services;
    },

    headers() {
      return [
        { text: this.$t('Дата и время'), value: 'ticket.createdAt', align: 'start' },
        { text: this.$t('Номер'), value: 'orderNumber' },
        { text: this.$t('Название услуги'), value: 'title' },
        { text: this.$t('Статус'), value: 'status' },
      ];
    },

    servicesItems() {
      return this.services
        .map(x => ({ value: x.id, text: x.name }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    statusItems() {
      return [
        { text: 'Новый', value: stStatus.NEW },
        { text: 'Подтвержден', value: stStatus.CONFIRMED },
        { text: 'Активен', value: stStatus.ACTIVE },
        { text: 'В работе', value: stStatus.IN_WORK },
        { text: 'Собран', value: stStatus.PACKED },
        { text: 'В пути', value: stStatus.DELIVERY },
        { text: 'Требуется обработка', value: stStatus.PROCESSING_REQUIRED },
        { text: 'Выполнен. Ожидает подтверждения', value: stStatus.PENDING_CLOSE },
        { text: 'Выполнен', value: stStatus.CLOSED },
        { text: 'Отменен', value: stStatus.CANCELED },
      ];
    },

    maxFilterDate() {
      return dayjs.tz().format('YYYY-MM-DD');
    },
  },

  mounted() {
    actions.fetchServices();
  },

  methods: {
    fetchItems: actions.fetchOrders,

    onClickRow(item) {
      state.viewOrder = item;
    },
  },
};
</script>
