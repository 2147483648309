<template>
  <span>
    {{ ticket.startAt | date('short') }} - {{ (ticket.endAt - 1) | date('short') }}, {{ nights }}
    {{ nights | plural('ночь', 'ночи', 'ночей') }}
  </span>
</template>

<script>
import { getNights } from '@/utils/date';

export default {
  name: 'HotelTicketPeriod',

  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    nights() {
      return getNights(this.ticket.startAt, this.ticket.endAt);
    },
  },
};
</script>
