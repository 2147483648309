<script>
import { VRadio, VRadioGroup } from 'vuetify/lib/components';

export default {
  name: 'RadioGroupField',

  functional: true,

  render(h, { props, data }) {
    const items = props.field.values.map(x => h(VRadio, { props: { label: x, value: x } }));

    return h(VRadioGroup, data, items);
  },
};
</script>
