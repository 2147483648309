<template>
  <v-sheet>
    <v-tabs v-model="tab" height="32">
      <v-tab class="text-none">{{ $t('Публичное') }}</v-tab>
      <v-tab class="text-none">{{ $t('Внутреннее') }}</v-tab>
    </v-tabs>

    <v-sheet class="py-2" :color="tab === 1 ? 'warning' : ''">
      <FileList
        :files="files"
        :icon-size="40"
        :disabled="saving"
        @remove="$refs.files.remove($event)"
      />

      <v-textarea
        v-model.trim="comment"
        background-color="transparent"
        class="comment overflow-auto"
        style="max-height: 30vh"
        rows="1"
        auto-grow
        solo
        flat
        hide-details
        :disabled="saving"
        :placeholder="$t('Написать сообщение...')"
        @keydown.enter.exact="submit"
      />

      <div class="d-flex pt-2 px-3">
        <v-btn icon :disabled="files.length >= maxFiles" @click="$refs.files.click()">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-badge :content="files.length" :value="files.length" overlap>
                <v-icon v-bind="attrs" v-on="on">mdi-paperclip</v-icon>
              </v-badge>
            </template>
            <span>{{ $t('Максимальный размер файла: 50 MB') }}</span>
          </v-tooltip>
        </v-btn>

        <v-spacer />

        <v-btn icon :disabled="sendDisabled" :loading="saving" @click="submit">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </div>
    </v-sheet>

    <FileInput id="tcf" ref="files" v-model="files" accept="*" :max-files="maxFiles" />
  </v-sheet>
</template>

<script>
import FileList from '@/components/file/FileList';
import FileInput from '@/components/file/FileInput';

export default {
  name: 'ChatInputAdmin',

  components: { FileInput, FileList },

  props: {
    sendComment: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    tab: 0,
    comment: '',
    files: [],
    showFiles: false,
    saving: false,
    maxFiles: 5,
  }),

  computed: {
    sendDisabled() {
      return !this.comment && !this.files.length;
    },
  },

  watch: {
    files() {
      this.showFiles = false;
    },
  },

  methods: {
    async submit() {
      if (this.sendDisabled) {
        return;
      }

      this.saving = true;

      await this.sendComment({
        article: {
          subject: this.$t('Комментарий с сайта'),
          body: this.comment,
          visibleForCustomer: this.tab === 0,
        },
        files: this.files,
      });

      this.reset();

      this.saving = false;
    },

    reset() {
      this.comment = '';
      this.$refs.files.clear();
    },
  },
};
</script>
