<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-breadcrumbs :items="breadcrumbs" class="px-0 py-3" divider="•">
          <template #item="{ item }">
            <li v-if="item.id !== unit.id">
              <a class="v-breadcrumbs__item t-grey--text" @click.prevent="selectUnit(item.id)">
                {{ item.text }}
              </a>
            </li>
            <v-breadcrumbs-item v-else class="primary--text">
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <div class="pb-4 text-h2">{{ unit.name }}</div>

        <div v-if="unit.description" class="mb-8">{{ unit.description }}</div>

        <div class="mb-8 d-flex gap-4">
          <UnitParamCard :label="$t('Статус')">
            <UnitStatus :unit="unit" />
          </UnitParamCard>
          <UnitParamCard v-if="unit.area" :label="$t('Площадь')">
            {{ unit.area }}&nbsp;м²
          </UnitParamCard>
          <UnitParamCard v-if="unit.rentable && unit.priceCategory" :label="$t('Стоимость')">
            <span class="primary--text">{{ unit.priceCategory.price }} ₽/м²</span>
          </UnitParamCard>
        </div>

        <v-tabs v-if="children.length > 0 || scheme" v-model="tab" class="mb-4">
          <v-tab v-if="children.length > 0">{{ $t('Список') }}</v-tab>
          <v-tab v-if="scheme">{{ $t('Визуальный план') }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="children.length > 0" :transition="false">
            <div class="d-flex flex-column gap-2">
              <UnitChildItem
                v-for="child of children"
                :key="child.id"
                :unit="child"
                @click="selectUnit(child.id)"
              />
            </div>
          </v-tab-item>
          <v-tab-item v-if="scheme" :transition="false">
            <PlanView :scheme="scheme" @contour:mouseenter="onShowPopup" />
            <v-menu
              ref="popup"
              v-model="popupMenu"
              :activator="popupActivator"
              :close-on-content-click="false"
              offset-y
              open-on-hover
            >
              <UnitPopupCard v-if="popupUnit" :unit="popupUnit" @more="selectUnit(popupUnit.id)" />
            </v-menu>
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-col cols="12" md="4">
        <v-carousel
          v-if="unit.images"
          class="mb-8"
          height="auto"
          hide-delimiters
          :show-arrows="unit.images.length > 1"
        >
          <v-carousel-item
            v-for="(img, i) in unit.images"
            :key="i"
            :src="$crop(img, '390x260')"
            :aspect-ratio="3 / 2"
            @click="openGallery(i)"
          />
        </v-carousel>
        <div v-if="showRequestButton" class="text-center">
          <v-dialog v-model="dialog" scrollable max-width="800px">
            <template #activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">
                {{ $t('Оставить заявку') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                {{ 'Заявка' }}
              </v-card-title>
              <v-card-text>
                <v-form ref="form" :disabled="saving">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.name"
                        :rules="[v => !!v || 'Укажите ФИО']"
                        label="Фамилия Имя Отчество"
                        name="name"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <PhoneField v-model="form.phone" type="phone" label="Телефон" name="phone" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <EmailField
                        v-model="form.email"
                        type="email"
                        label="Электронная почта"
                        name="email"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <DynamicFields
                        v-model="form.dynamicFields"
                        :dynamic-fields="rentService.dynamicFields"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" :loading="saving" @click.prevent="onSubmit">
                  {{ 'Отправить' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-snackbar v-model="snackbar">
            Заявка создана. В ближайшее время с вами свяжется наш менеджер.
          </v-snackbar>
        </div>
      </v-col>
    </v-row>
    <PhotoViewer :images="showImages" :show-idx="showIdx" />
  </v-container>
</template>

<script>
import { actions, getters, state } from '@/store/plan';
import PhotoViewer from '@/components/gallery/PhotoViewer.vue';
import PlanView from '@/components/tech-plan/PlanView.vue';
import UnitPopupCard from '@/components/plan/UnitPopupCard.vue';
import UnitChildItem from '@/components/plan/UnitChildItem.vue';
import PhoneField from '@/components/ui/PhoneField.vue';
import EmailField from '@/components/ui/EmailField.vue';
import UnitStatus from '@/components/plan/UnitStatus.vue';
import UnitParamCard from '@/components/plan/UnitParamCard.vue';
import DynamicFields from '@/components/dynamic-fields/DynamicFields.vue';

const initForm = () => ({
  name: null,
  phone: null,
  email: null,
  dynamicFields: null,
});

export default {
  name: 'UnitView',

  components: {
    DynamicFields,
    UnitParamCard,
    UnitStatus,
    EmailField,
    PhoneField,
    UnitChildItem,
    UnitPopupCard,
    PlanView,
    PhotoViewer,
  },

  data: () => ({
    tab: null,
    showImages: null,
    showIdx: null,
    popupActivator: null,
    popupItem: null,
    popupMenu: false,
    dialog: false,
    saving: false,
    snackbar: false,
    form: initForm(),
  }),

  computed: {
    unit: getters.getSelectedUnit,

    children: getters.getChildrenUnits,

    breadcrumbs() {
      let items = getters.getParentUnits();

      items.reverse();
      items.push(this.unit);

      items = items.map(x => ({ id: x.id, text: x.name }));
      items.unshift({ id: null, text: 'Главная' });

      return items;
    },

    scheme() {
      return state.schemeList.find(x => x.unitId === this.unit.id);
    },

    popupUnit() {
      return state.structList.find(x => x.id === this.popupItem?.unitId);
    },

    showRequestButton() {
      return state.rentService?.publishedToWidget && this.unit.rentable;
    },

    rentService() {
      return state.rentService;
    },
  },

  methods: {
    onShowPopup({ target, item }) {
      this.popupActivator = target;
      this.popupItem = item;
      this.$refs.popup.save();
      this.$refs.popup.runDelay('open');
    },

    openGallery(idx) {
      this.showIdx = idx;
      this.showImages = [...this.unit.images];
    },

    selectUnit(id) {
      state.selectedUnitId = id;
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      try {
        const payload = {
          ...this.form,
          rentUnitId: this.unit.id,
          serviceId: state.location.rentServiceId,
          source: 'struct-widget',
        };
        await actions.createTicket(payload);
        this.dialog = false;
        this.snackbar = true;
        this.form = initForm();
        this.$refs.form.resetValidation();
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
