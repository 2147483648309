<template>
  <v-card
    v-bind="$attrs"
    :class="{ active: active, disabled: disabled }"
    :disabled="disabled"
    outlined
    v-on="$listeners"
  >
    <div
      :class="[active && 'font-weight-bold']"
      class="pa-3 px-4 text-body-2 fill-height d-flex flex-column align-center justify-center text"
    >
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SlotBlock',
  props: {
    active: Boolean,
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border: 2px solid var(--v-t-menu-bg-base);

  &:not(.disabled):hover {
    border: 2px solid currentColor;
    color: var(--v-primary-base);
    &::v-deep {
      .v-icon {
        color: currentColor;
      }
    }
  }

  &.active {
    border: 2px solid currentColor;
    color: var(--v-primary-base);
    &::v-deep {
      .v-icon {
        color: currentColor;
      }
    }
  }

  &.disabled {
    border: none;
    color: var(--v-t-grey-base);
    .text {
      opacity: 0.4;
    }
  }
}

.disabled {
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 2px,
    var(--v-t-menu-bg-base) 2px,
    var(--v-t-menu-bg-base) 4px
  );
}
</style>
