<template>
  <div ref="content" class="overflow-y-auto slim-scroll">
    <div class="pa-4">
      <div class="my-n2">
        <div v-for="item in chatItems" :key="item.date || item.id" class="py-2">
          <div v-if="item.date" :key="item.date" class="text-center text--primary text-subtitle-1">
            {{ item.date }}
          </div>

          <ChatItem v-else :item="item" :agent-side="agentSide" @click-image="onClickImage" />
        </div>
      </div>

      <slot name="bottom" />
    </div>
    <PhotoViewer ref="gallery" />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ChatItem from '@/components/common/chat/ChatItem';
import PhotoViewer from '@/components/gallery/PhotoViewer.vue';

export default {
  name: 'ChatItems',

  components: { PhotoViewer, ChatItem },

  props: {
    articles: Array,
    agentSide: Boolean,
  },

  data: () => ({
    lastId: null,
  }),

  computed: {
    todayDate() {
      return dayjs.tz().startOf('d');
    },

    yesterdayDate() {
      return dayjs.tz().subtract(1, 'd').startOf('d');
    },

    chatItems() {
      const items = [];
      const articles = [...this.articles];
      articles.reverse(); // asc order

      const defaultFormat = 'dayAndMonth';
      const alternativeFormat = 'dateLong';

      const todayFormatted = this.$d(this.todayDate, defaultFormat);
      const yesterdayFormatted = this.$d(this.yesterdayDate, defaultFormat);

      let date;
      for (let item of articles) {
        const dt = dayjs.unix(item.createdAt).tz();
        const format = dt.year() === this.todayDate.year() ? defaultFormat : alternativeFormat;

        let displayDate = this.$d(dt, format);
        if (displayDate === todayFormatted) {
          displayDate = this.$t('Сегодня');
        } else if (displayDate === yesterdayFormatted) {
          displayDate = this.$t('Вчера');
        }

        if (date !== displayDate) {
          date = displayDate;
          items.push({ date: date });
        }

        items.push(item);
      }

      // flex-column-reverse
      // items.reverse();

      return items;
    },

    attachments() {
      const articles = [...this.articles];
      // articles.reverse();

      const items = [];
      for (const article of articles) {
        items.push(...article.attachments);
      }

      return items;
    },

    images() {
      return this.attachments.filter(x => this.isImage(x));
    },

    imagesUrls() {
      return this.images.map(x => x.url);
    },
  },

  watch: {
    articles() {
      this.lastId = this.articles[0]?.id;
    },

    lastId() {
      this.$nextTick(() => {
        this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
      });
    },
  },

  mounted() {
    this.$refs.content.scrollTop = this.$refs.content.scrollHeight;
  },

  methods: {
    isImage(attachment) {
      return attachment.contentType.startsWith('image');
    },

    onClickImage(item) {
      const idx = this.images.findIndex(x => x === item);
      this.$refs.gallery.show(this.imagesUrls, idx);
    },
  },
};
</script>
