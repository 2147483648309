<template>
  <div
    ref="root"
    v-resize-observer.quiet="onResize"
    class="plan"
    :class="{ disabled: false, selectable: false }"
    style="position: relative"
  >
    <PlanImg
      v-if="clientWidth && scheme.backgroundUrl"
      :url="scheme.backgroundUrl"
      :width="scaledWidth"
      :height="scaledHeight"
    ></PlanImg>
    <PlanSvg
      v-if="clientWidth"
      :width="scheme.width || 0"
      :height="scheme.height || 0"
      :items="planItems"
      :disabled-units="disabledUnits"
      @contour:mouseenter="$emit('contour:mouseenter', $event)"
      @contour:mouseleave="$emit('contour:mouseleave', $event)"
      v-on="createBinds()"
    ></PlanSvg>
    <slot v-if="clientWidth" />
  </div>
</template>

<script>
import PlanImg from '@/components/tech-plan/PlanImg.vue';
import PlanSvg from '@/components/tech-plan/PlanSvg.vue';

export default {
  name: 'PlanView',

  components: { PlanSvg, PlanImg },

  props: {
    scheme: {
      type: Object,
      required: true,
    },
    disabledUnits: Array,
  },

  data() {
    return {
      clientWidth: null,
    };
  },

  computed: {
    planItems() {
      return this.scheme.content || [];
    },

    scale() {
      return this.clientWidth && this.clientWidth < this.scheme.width
        ? this.clientWidth / this.scheme.width
        : 1;
    },

    scaledWidth() {
      return this.scheme.width * this.scale;
    },

    scaledHeight() {
      return this.scheme.height * this.scale;
    },
  },

  mounted() {
    this.$nextTick(() => this.onResize());
  },

  methods: {
    onResize() {
      this.clientWidth = this.$refs.root.clientWidth;
    },

    createBinds() {
      const bind = {};
      if (this.$listeners['contour:click']) {
        bind['contour:click'] = e => this.$emit('contour:click', e);
      }
      return bind;
    },
  },
};
</script>

<style scoped lang="scss">
.plan {
  user-select: none;
  display: block;
  width: 100%;
  position: relative;
  z-index: 0;
  page-break-inside: avoid;

  &.disabled svg {
    pointer-events: none;
  }
}
</style>
