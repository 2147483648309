<template>
  <div class="py-2">
    <v-icon class="mr-4" color="primary">{{ mdiClockTimeFourOutline }}</v-icon>
    <HotelTicketPeriod :ticket="serviceTicket.hotelTickets[0]" />
  </div>
</template>

<script>
import { mdiClockTimeFourOutline } from '@mdi/js';
import HotelTicketPeriod from '@/components/hotel/HotelTicketPeriod.vue';

export default {
  name: 'HotelInfo',

  components: { HotelTicketPeriod },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mdiClockTimeFourOutline,
    };
  },
};
</script>
