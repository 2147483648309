export const formProxyMixin = {
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },

  watch: {
    form: {
      deep: true,
      handler(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};

export const resetDataMixin = {
  methods: {
    resetData() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
  },
};
