<template>
  <div>
    <v-sheet class="mb-2 text-subtitle-1">
      <span class="primary--text">{{ serviceTicket.extId }}</span>
      {{ ' ' }}
      <span class="t-grey--text">{{ serviceTicket.createdAt | date('long') }}</span>
    </v-sheet>

    <div class="d-flex align-center justify-space-between">
      <h1 class="text-h1">
        <slot name="title">{{ serviceTicket.title || serviceTicket.service.name }}</slot>
      </h1>
      <slot name="menu" />
    </div>

    <div
      v-if="$scopedSlots.subtitle || serviceTicket.reservation || serviceTicket.order"
      class="pt-2 text-subtitle-1 primary--text"
    >
      <slot name="subtitle">
        <ReservationIntervals
          v-if="serviceTicket.reservation"
          :service-ticket="serviceTicket"
          class="primary--text"
        />
        <ReadyTime v-if="serviceTicket.order" :ready-time="serviceTicket.readyTime" />
      </slot>
    </div>
  </div>
</template>

<script>
import ReservationIntervals from '@/components/booking/ReservationIntervals';
import ReadyTime from '@/components/order/ReadyTime';

export default {
  name: 'ServiceTicketHeaderCommon',

  components: { ReadyTime, ReservationIntervals },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },
};
</script>
