<template>
  <v-card elevation="0" rounded="0" height="100%" v-bind="$attrs" v-on="$listeners">
    <v-img :src="$crop(image, '390x260')" height="250" width="376" />
    <v-card-title class="text-h5 text-md-h4 t-dark--text">
      {{ title }}
    </v-card-title>
    <v-card-subtitle v-if="$slots.notice" class="primary--text">
      <slot name="notice" />
    </v-card-subtitle>
    <v-card-text v-if="subtitle" class="text-body-1" style="margin-top: -10px">
      {{ subtitle }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ServiceBasicCard',
  props: {
    serviceId: Number,
    favorite: Boolean,
    grayscale: Boolean,
    hideFavorite: {
      type: Boolean,
      default: false,
    },
    image: String,
    subtitle: String,
    title: String,
  },
};
</script>
