import dayjs from 'dayjs';
import { getters } from '@/store/store';
import { DeliveryType } from '@/constants/service';

export const coffeeBreakWorkAndDeliveryMixin = {
  computed: {
    // getWorkTime: getters.getWorkTime,

    date() {
      return dayjs.tz().format('YYYY-MM-DD');
    },

    workBeginTime() {
      return this.service.workBeginTime && dayjs.tz(`${this.date} ${this.service.workBeginTime}`);
    },

    workEndTime() {
      return this.service.workEndTime && dayjs.tz(`${this.date} ${this.service.workEndTime}`);
    },

    isSelfPickup() {
      return this.service.deliveryType === DeliveryType.SELF_PICKUP;
    },

    hasPickup() {
      return [DeliveryType.PICKUP, DeliveryType.PICKUP_AND_COURIER].includes(
        this.service.deliveryType
      );
    },

    hasDelivery() {
      return [DeliveryType.COURIER, DeliveryType.PICKUP_AND_COURIER].includes(
        this.service.deliveryType
      );
    },

    deliveryFrom() {
      return this.hasDelivery ? dayjs.tz(`${this.date} ${this.service.deliveryBeginTime}`) : null;
    },

    deliveryTo() {
      return this.hasDelivery ? dayjs.tz(`${this.date} ${this.service.deliveryEndTime}`) : null;
    },

    workTime() {
      return getters.getWorkTime(this.service.id, this.date);
    },

    isWorkingTime() {
      if (!this.workBeginTime || this.isSelfPickup) {
        return true;
      }

      const now = dayjs().tz();
      const ts = now.unix();

      // while load
      if (!this.workTime) {
        return now >= this.workBeginTime && now <= this.workEndTime;
      }

      for (let range of this.workTime) {
        if (ts >= range.begin && ts < range.end) {
          return true;
        }
      }

      return false;
    },

    isDeliveryTime() {
      const now = dayjs.tz();
      return now >= this.deliveryFrom && now < this.deliveryTo;
    },

    orderAvailable() {
      if (this.isSelfPickup) {
        return true;
      }

      const pickup = this.hasPickup;
      const delivery = this.hasDelivery && this.isDeliveryTime;

      return this.isWorkingTime && (pickup || delivery);
    },

    orderTimeFrom() {
      if (!this.hasPickup && this.hasDelivery && !this.isDeliveryTime) {
        return this.deliveryFrom;
      }
      return this.workBeginTime;
    },

    orderTimeTo() {
      if (!this.hasPickup && this.hasDelivery && !this.isDeliveryTime) {
        return this.deliveryTo;
      }
      return this.workEndTime;
    },
  },
};
