<template>
  <div id="tpw">
    <v-app v-if="location">
      <v-main>
        <NewsView v-if="isView" />
        <NewsList v-else />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/newsStore';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import NewsList from '@/views/news/NewsList.vue';
import NewsView from '@/views/news/NewsView.vue';

export default {
  name: 'NewsWidget',

  components: {
    NewsView,
    NewsList,
  },

  mixins: [commonPropsMixin, themeMixin],

  props: {
    eventId: [Number, String],
  },

  computed: {
    location() {
      return state.location;
    },
    isView() {
      return state.type === 'news-view' || state.currentNews;
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
  },
};
</script>
