<script>
import { DynamicFieldType } from '@/constants/dynamicFields';
import { VCheckbox, VTextarea, VTextField } from 'vuetify/lib';
import TimePicker from '@/components/ui/TimePicker.vue';
import SelectField from './field/SelectField.vue';
import MultiselectField from './field/MultiselectField.vue';
import UploadField from './field/UploadField.vue';
import DateField from '@/components/dynamic-fields/field/DateField.vue';
import EmailField from '@/components/ui/EmailField.vue';
import RadioGroupField from '@/components/dynamic-fields/field/RadioGroupField.vue';

export default {
  functional: true,

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  render(h, { props, data, children }) {
    const map = {
      [DynamicFieldType.TEXT]: VTextField,
      [DynamicFieldType.TEXTAREA]: VTextarea,
      [DynamicFieldType.NUMBER]: VTextField,
      // [DynamicFieldType.NUMBER]: NumberField,
      [DynamicFieldType.EMAIL]: EmailField,
      [DynamicFieldType.CHECKBOX]: VCheckbox,
      [DynamicFieldType.RADIO]: RadioGroupField,
      [DynamicFieldType.SELECT]: SelectField,
      [DynamicFieldType.MULTISELECT]: MultiselectField,
      [DynamicFieldType.DATE]: DateField,
      [DynamicFieldType.TIME]: TimePicker,
      [DynamicFieldType.UPLOAD]: UploadField,
    };

    const Component = map[props.field.inputType] ?? null;
    if (!Component) {
      return null;
    }

    if ([DynamicFieldType.CHECKBOX, DynamicFieldType.RADIO].includes(props.field.inputType)) {
      data.attrs.inputValue = data.attrs.value;
      data.on.change = data.on.input;
    }

    return h(Component, { ...data, props: { ...data.props, field: props.field } }, children);
  },
};
</script>
