<script>
import App from '@/App.vue';
import OrdersWidget from '@/OrdersWidget.vue';
import LastOrdersWidget from '@/LastOrdersWidget.vue';
import EventsWidget from '@/EventsWidget.vue';
import PlanWidget from '@/PlanWidget.vue';
import NewsWidget from '@/NewsWidget.vue';

export default {
  name: 'TestWidget',

  render(h) {
    const map = {
      events: EventsWidget,
      lastOrders: LastOrdersWidget,
      news: NewsWidget,
      orders: OrdersWidget,
      plan: PlanWidget,
      services: App,
    };

    const widthMap = {
      lastOrders: '360px',
    };

    const urlParams = new URLSearchParams(window.location.search);
    const widgetName = urlParams.get('widget');

    const props = {
      locationId: urlParams.has('locationId') ? Number(urlParams.get('locationId')) : null,
      userHash: urlParams.get('userHash'),
      primaryColor: urlParams.get('primaryColor') || '#D8001A',
    };

    const reserved = Object.keys(props).concat('widget');
    for (const [key, value] of urlParams.entries()) {
      if (!reserved.includes(key)) {
        props[key] = value;
      }
    }

    const elem = h(map[widgetName] ?? App, { props });

    const width = widthMap[widgetName];
    if (!width) {
      return elem;
    }

    return h('div', { style: { width } }, [elem]);
  },
};
</script>
