<template>
  <v-dialog width="600" v-bind="$attrs" v-on="$listeners">
    <v-card>
      <v-card-title>
        {{ $t('Заказ изменен') }}
      </v-card-title>

      <v-card-text>
        <div class="mb-4">
          <div v-if="qtyChanged" class="mb-2">
            {{ $t('Для выполнения вашего заказа не хватает позиций на складе.') }}
          </div>
          <div v-if="priceChanged" class="mb-2">
            {{ $t('Стоимость некоторых позиций была изменена.') }}
          </div>
        </div>
        <div class="mb-2 font-weight-bold">
          {{ $t('Ваш заказ') }}
        </div>
        <OrderPositions>
          <v-divider />
          <OrderPosition
            v-for="item of cart"
            :key="item.id"
            :qty="`${item.qty} x ${$n(item.price, 'currency')}`"
            :sum="$n(item.qty * item.price, 'currency')"
          >
            <div>{{ item.name }}</div>
            <div
              v-if="item.modifications && item.modifications.length"
              class="text-body-2 primary--text"
            >
              {{ getItemModificationText(item.modifications) }}
            </div>
          </OrderPosition>
          <OrderPosition
            v-for="(item, idx) of removedItems"
            :key="idx"
            :name="item.name"
            :sum="'—'"
          />
          <v-divider />
          <OrderPosition summary :sum="$n(totalPrice, 'currency')">
            {{ $t('Итоговая стоимость') }}
          </OrderPosition>
        </OrderPositions>
      </v-card-text>

      <v-card-actions>
        <BtnSecondary :disabled="loading" @click="$emit('cancel')">
          {{ $t('Вернуться к заказу') }}
        </BtnSecondary>
        <v-spacer />
        <BtnPrimary
          v-if="cart.length > 0 && totalPrice > 50"
          :loading="loading"
          @click="$emit('submit')"
        >
          {{ totalPrice ? $t('Заказать за {0}', [$n(totalPrice, 'currency')]) : $t('Заказать') }}
        </BtnPrimary>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { changeType } from '@/constants/changes';
import OrderPositions from '@/components/order/OrderPositions';
import OrderPosition from '@/components/order/OrderPosition';
import BtnPrimary from '@/components/ui/BtnPrimary';
import BtnSecondary from '@/components/ui/BtnSecondary';

export default {
  name: 'OrderChangedDialog',

  components: {
    BtnSecondary,
    BtnPrimary,
    OrderPosition,
    OrderPositions,
  },

  props: {
    cart: {
      type: Array,
      required: true,
    },
    changes: {
      type: Array,
      required: true,
    },
    loading: Boolean,
    totalPrice: Number,
  },

  computed: {
    removedItems() {
      return this.changes.filter(x => x.type === changeType.REMOVE);
    },

    qtyChanged() {
      const types = [
        changeType.QTY,
        changeType.MOD_COUNT,
        changeType.REMOVE,
        changeType.MOD_REMOVE,
      ];
      return this.changes.filter(x => types.includes(x.type)).length > 0;
    },

    priceChanged() {
      const types = [changeType.PRICE, changeType.MOD_PRICE];
      return this.changes.filter(x => types.includes(x.type)).length > 0;
    },
  },

  methods: {
    getItemModificationText(modifications) {
      return modifications.map(x => (x.count === 1 ? x.name : `${x.name} x ${x.count}`)).join(', ');
    },
  },
};
</script>
