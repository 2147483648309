<template>
  <v-sheet>
    <div v-for="(item, idx) of orders" :key="item.id">
      <div class="">
        <div class="mb-3">
          <v-chip small color="t-menu-bg" text-color="primary" :ripple="false">
            {{ item | orderStatus(true) }}
          </v-chip>
        </div>
        <div class="my-2 font-weight-bold name" style="text-overflow: ellipsis">
          {{ item.service.name }}
        </div>
        <div v-if="item.service.title" class="mt-2 text-body-2 text--secondary">
          {{ item.service.title }}
        </div>
        <div class="mt-4 text-caption text--secondary">
          {{ item.createdAt | date('long') }}
        </div>
      </div>
      <v-divider v-if="idx !== orders.length - 1" class="my-4" />
    </div>
  </v-sheet>
</template>

<script>
import { actions, state } from '@/store/lastOrders';

export default {
  name: 'LastOrders',

  props: {
    limit: {
      type: Number,
      default: 4,
    },
  },

  computed: {
    orders() {
      return state.orders;
    },
  },

  mounted() {
    actions.fetchOrders({ sort: 'createdAt-', limit: this.limit });
  },
};
</script>

<style scoped>
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
