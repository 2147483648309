import dayjs from 'dayjs';

export default function (value) {
  if (!value && value !== 0) {
    return '';
  }

  const date = typeof value === 'number' ? dayjs.unix(value).tz() : dayjs.tz(value);

  return date.format('HH:mm');
}
