<template>
  <div class="img-container">
    <img :src="url" :width="width" :height="height" alt="" />
  </div>
</template>

<script>
export default {
  name: 'PlanImg',

  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.img-container {
  width: 100%;
  min-height: auto;
  //min-height: 100px;
  //visibility: hidden;
  //&.loaded {
  //  min-height: auto;
  //  visibility: visible;
  //}

  img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
  }
}
</style>
