<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="[0, 0, width, height].join(',')">
    <PlanContour
      v-for="(item, idx) of items"
      :key="idx"
      :item="item"
      :disabled="disabledUnits.includes(item.unitId)"
      @mouseenter="$emit('contour:mouseenter', { target: $event.target, item })"
      @mouseleave="$emit('contour:mouseleave', { target: $event.target, item })"
      v-on="createBinds(item)"
    />
  </svg>
</template>

<script>
import PlanContour from '@/components/tech-plan/PlanContour.vue';

export default {
  name: 'PlanSvg',

  components: { PlanContour },

  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    disabledUnits: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    createBinds(item) {
      const bind = {};
      if (this.$listeners['contour:click']) {
        bind['click'] = () => this.$emit('contour:click', item);
      }
      return bind;
    },
  },
};
</script>

<style scoped>
svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  margin: 0 auto;
}
</style>
