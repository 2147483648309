<template>
  <div>
    <div v-if="label" class="mb-2">{{ label }}</div>
    <v-input :value="value" v-bind="$attrs">
      <v-item-group :value="value" multiple class="d-flex flex-wrap ma-n1" @change="onChange">
        <div v-for="slot of displaySlots" :key="slot.id" class="ma-1">
          <v-item v-slot="{ active, toggle }" :value="slot.slot">
            <v-tooltip top :disabled="slot.free">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <SlotBlock
                    :active="active"
                    :disabled="!slot.free"
                    width="72"
                    height="40"
                    @click="slot.free ? toggle($event) : null"
                  >
                    {{ slot.slot | time }}
                  </SlotBlock>
                </div>
              </template>
              <span v-if="!slot.free">{{ getBusyTooltip(slot) }}</span>
            </v-tooltip>
          </v-item>
        </div>
      </v-item-group>
    </v-input>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import SlotBlock from '@/components/ui/SlotBlock';

export default {
  name: 'SlotToggle',

  components: { SlotBlock },

  inheritAttrs: false,

  props: {
    label: String,
    slots: Array,
    value: Array,
  },

  data() {
    return {
      initialValue: this.value || [],
      prevValue: this.value || [],
    };
  },

  computed: {
    displaySlots() {
      return this.slots.map(x => ({
        ...x,
        free: x.free || this.initialValue.includes(x.slot),
      }));
    },

    busyIntervals() {
      const intervals = [];

      let curInterval;
      let prevFree = true;
      for (let item of this.displaySlots) {
        if (!item.free && prevFree) {
          curInterval = { from: item.slot, to: null };
          intervals.push(curInterval);
        }

        if (item.free && !prevFree) {
          curInterval.to = item.slot;
        }

        if (
          item === this.displaySlots[this.displaySlots.length - 1] &&
          curInterval &&
          !curInterval.to
        ) {
          curInterval.to = item.slot + item.duration;
        }

        prevFree = item.free;
      }

      return intervals;
    },
  },

  methods: {
    getBusyInterval(el) {
      if (el.free) {
        return null;
      }

      for (let interval of this.busyIntervals) {
        if (el.slot >= interval.from && el.slot <= interval.to) {
          return interval;
        }
      }
    },

    getBusyTooltip(el) {
      const interval = this.getBusyInterval(el);
      if (!interval) {
        return null;
      }

      const t1 = dayjs.unix(interval.from).tz().format('HH:mm');
      const t2 = dayjs.unix(interval.to).tz().format('HH:mm');

      return `С ${t1} до ${t2} время занято`;
    },

    onChange(value) {
      let newValue = value;

      if (value.length >= 2) {
        // индексы выбранных слотов
        const indexes = value.map(time => this.displaySlots.findIndex(x => x.slot === time));
        indexes.sort((a, b) => a - b);

        // последнее выбранное значение
        const lastTime = value[value.length - 1];
        const lastTimeSlotIdx = this.displaySlots.findIndex(x => x.slot === lastTime);
        const foundIdx = indexes.indexOf(lastTimeSlotIdx);

        // находит разрыв последовательности в сторону начала
        let sliceStart = 0;
        for (let i = 1; i <= foundIdx; i++) {
          if (indexes[foundIdx] - indexes[foundIdx - i] > i) {
            sliceStart = foundIdx - i + 1;
            break;
          }
        }

        // находит разрыв последовательности до конца
        let sliceEnd = indexes.length;
        for (let i = 1; i <= indexes.length - 1 - foundIdx; i++) {
          if (indexes[foundIdx + i] - indexes[foundIdx] > i) {
            sliceEnd = foundIdx + i;
            break;
          }
        }

        newValue = indexes.slice(sliceStart, sliceEnd).map(x => this.displaySlots[x].slot);
      }

      this.$emit('input', newValue);
      this.prevValue = newValue;
    },
  },
};
</script>
