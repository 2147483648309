<template>
  <v-card width="420">
    <v-img :src="$crop(image, '420x280')" height="280" contain></v-img>
    <v-card-title>{{ unit.name }}</v-card-title>
    <v-card-subtitle class="d-flex align-center justify-space-between">
      <div v-if="unit.area">{{ $t('Площадь') }} - {{ unit.area }}&nbsp;м²</div>
      <div v-if="unit.rentable && unit.priceCategory">{{ unit.priceCategory.price }} ₽/м²</div>
      <UnitStatus :unit="unit" />
    </v-card-subtitle>
    <v-card-actions>
      <v-btn color="primary" block rounded @click="$emit('more')">{{ $t('Подробнее') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { state } from '@/store/plan';
import UnitStatus from '@/components/plan/UnitStatus.vue';

export default {
  name: 'UnitPopupCard',

  components: { UnitStatus },

  props: {
    unit: {
      type: Object,
      required: true,
    },
  },

  computed: {
    image() {
      return this.unit.images?.[0] || state.location.companyLogo;
    },
  },
};
</script>
