<template>
  <div class="title font-weight-bold text-uppercase">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BlockTitle',
};
</script>
