<template>
  <div>
    <v-menu :disabled="disabled" bottom left>
      <template #activator="{ on, attrs }">
        <v-btn icon :disabled="disabled" :loading="loading" v-bind="attrs" v-on="on">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <slot />
    </v-menu>
  </div>
</template>

<script>
import { mdiDotsVertical } from '@mdi/js';

export default {
  name: 'DotsMenu',

  // fixes using menu inside list-item-group
  provide: {
    isInGroup: false,
    listItemGroup: undefined,
  },

  props: {
    disabled: Boolean,
    icon: {
      type: String,
      default: mdiDotsVertical,
    },
    loading: Boolean,
  },
};
</script>
