<template>
  <div class="d-flex align-center" style="width: 100%">
    <div class="text--primary" style="flex: 0 0 40%">
      {{ modifier.name }}
    </div>
    <div class="ml-4 text--secondary text-body-2 flex-grow-1">
      {{ modifier.description }}
    </div>
    <div class="ml-4 text-right primary--text" style="min-width: 120px">
      {{ $n(modifier.price, 'currency') }}
      <template v-if="modifier.affect === ModifierAffect.TARIFF_PRICE || modifier.dependsOnGuests">
        {{ '/' }}
        <span v-if="modifier.affect === ModifierAffect.TARIFF_PRICE">
          {{ PriceUnitLabel[priceUnit].toLowerCase() }}
        </span>
        <span v-if="modifier.dependsOnGuests">
          {{ 'с гостя' }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import { ModifierAffect, PriceUnitLabel } from '@/constants/serviceTariff';

export default {
  name: 'ModifierLabel',

  props: {
    modifier: {
      type: Object,
      required: true,
    },
    priceUnit: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      ModifierAffect: ModifierAffect,
      PriceUnitLabel: PriceUnitLabel,
    };
  },
};
</script>
