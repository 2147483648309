<template>
  <v-sheet class="d-flex flex-column" color="t-back-light" tile flat v-bind="$attrs">
    <v-spacer />
    <ChatItems :articles="articles" :agent-side="agentSide">
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </ChatItems>
    <template v-if="showInput">
      <ChatInputAdmin v-if="agentSide" :send-comment="sendComment" />
      <ChatInput v-else :send-comment="sendComment" />
    </template>
  </v-sheet>
</template>

<script>
import eventBus from '@/eventBus';
import chatStore from '@/store/chatStore';
import ChatItems from '@/components/common/chat/ChatItems';
import ChatInput from '@/components/common/chat/ChatInput';
import ChatInputAdmin from '@/components/common/chat/ChatInputAdmin';

export default {
  name: 'Chat',

  components: { ChatInputAdmin, ChatInput, ChatItems },

  props: {
    ticketId: Number,
    agentSide: Boolean,
    showInput: Boolean,
  },

  computed: {
    articles() {
      return chatStore.getters.getArticles(this.ticketId);
    },
  },

  mounted() {
    this.loadComments();
    this.markSeen();
    // this.viewTicket({ ticketId: this.ticketId });
    eventBus.$on('push:article.create.data-push', this.handleNewArticle);
  },

  beforeDestroy() {
    // this.closeTicket();
    eventBus.$off('push:article.create.data-push', this.handleNewArticle);
  },

  methods: {
    async loadComments() {
      await chatStore.actions.fetchArticles({
        ticketId: this.ticketId,
        agentView: this.agentSide ? '1' : '0',
      });
    },

    async markSeen() {
      await chatStore.actions.markAsSeen({
        ticketId: this.ticketId,
        senderType: this.agentSide ? 'customer' : 'agent',
      });
    },

    async sendComment(data) {
      await chatStore.actions.createArticle({
        ...data,
        ticketId: this.ticketId,
        senderType: this.agentSide ? 'agent' : 'customer',
      });
      await this.loadComments();
    },

    handleNewArticle({ data: { serviceTicket, article } }) {
      if (serviceTicket.ticketId === this.ticketId) {
        chatStore.actions.addArticle({ ticketId: this.ticketId, article });
        this.markSeen();
      }
    },
  },
};
</script>
