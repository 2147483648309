<template>
  <ServiceBasicCard
    :service-id="service.id"
    :image="service.images[0]"
    :title="service.name"
    :subtitle="service.title"
    @click="$emit('click')"
  />
</template>

<script>
import ServiceBasicCard from '@/components/catalog/ServiceBasicCard';

export default {
  name: 'ServiceCard',
  components: { ServiceBasicCard },
  props: ['service'],
};
</script>
