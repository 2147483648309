<template>
  <div class="d-flex align-center">
    <v-icon v-if="count > 0" size="32" color="primary" @click="$emit('minus')">
      {{ count === 1 ? mdiCloseCircleOutline : mdiMinusCircleOutline }}
    </v-icon>
    <div v-if="count > 0" class="px-3">
      {{ count }}
    </div>
    <v-icon
      :disabled="max !== null ? count >= max : null"
      size="32"
      color="primary"
      @click="$emit('plus')"
    >
      {{ !count ? mdiPlusCircleOutline : mdiPlusCircle }}
    </v-icon>
  </div>
</template>

<script>
import {
  mdiCloseCircleOutline,
  mdiMinusCircleOutline,
  mdiPlusCircle,
  mdiPlusCircleOutline,
} from '@mdi/js';

export default {
  name: 'PlusMinus',

  props: {
    count: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    mdiCloseCircleOutline: mdiCloseCircleOutline,
    mdiMinusCircleOutline: mdiMinusCircleOutline,
    mdiPlusCircle: mdiPlusCircle,
    mdiPlusCircleOutline: mdiPlusCircleOutline,
  }),
};
</script>
