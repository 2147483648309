<template>
  <OrderBase>
    <template #menu>
      <TicketMenu :service-ticket="serviceTicket" />
    </template>

    <!--
    <v-row>
      <v-col v-if="serviceTicket.service.showLinkedServices" cols="12">
        <Wrapper class="mb-2 font-weight-bold">
          {{ serviceTicket.service.linkedServicesHeader }}
        </Wrapper>
        <LinkedServices :service-ticket="serviceTicket" />
      </v-col>
    </v-row>
    -->
  </OrderBase>
</template>

<script>
import OrderBase from '@/views/home/orders/order/OrderBase';
import TicketMenu from '@/views/home/orders/order/menu/TicketMenu';
import { serviceTicketMixin } from '@/views/home/orders/mixins/serviceTicketMixin';

export default {
  name: 'OrderDefault',

  components: {
    TicketMenu,
    OrderBase,
  },

  mixins: [serviceTicketMixin],
};
</script>
