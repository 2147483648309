<template>
  <v-container class="pa-0 fill-height" fluid>
    <v-row no-gutters class="fill-height">
      <v-col cols="12" md="8" class="px-2 py-4 px-sm-10 py-sm-8" style="background-color: white">
        <slot />
      </v-col>
      <v-col cols="12" md="4" style="">
        <slot name="chat" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageContainerWithChat',
};
</script>
