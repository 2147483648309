<template>
  <ServiceBasicCard
    :image="service.images[0]"
    :title="service.name"
    :subtitle="subtitle || '\xa0'"
    @click="$emit('click')"
  >
    <template v-if="service.minPrice" #notice>
      {{ $t('от') }} {{ $n(service.minPrice, 'currency') }}
    </template>
  </ServiceBasicCard>
</template>

<script>
import dayjs from 'dayjs';
import { actions, getters } from '@/store/store';
import ServiceBasicCard from '@/components/catalog/ServiceBasicCard';

export default {
  name: 'ServiceBookingCard',

  components: { ServiceBasicCard },

  props: ['service'],

  computed: {
    subtitle() {
      return this.service.booking.useBusyStateAsDescription ? this.freeStatus : this.service.title;
    },

    slots() {
      const periodStart = dayjs.tz().startOf('d').unix();
      const periodEnd = periodStart + 86400;
      return getters.getServiceSlots(this.service.id, periodStart, periodEnd);
    },

    freeStatus() {
      const slots = this.slots;
      if (!slots || !slots.length) {
        return null;
      }

      // текущее состояние - следующий за текущим временем слот
      const ts = dayjs().unix();
      const currentSlot = slots.find(x => x.slot >= ts);
      if (!currentSlot) {
        return null;
      }

      const nextSlots = slots.filter(x => x.slot >= ts);

      if (currentSlot.free) {
        const busySlot = nextSlots.find(x => !x.free);
        if (!busySlot) {
          return `Сегодня свободна`;
        }

        return `Свободна до ${dayjs.unix(busySlot.slot).tz().format('HH:mm')}`;
      }

      const freeSlot = nextSlots.find(x => x.free);
      if (!freeSlot) {
        return `Сегодня занята`;
      }

      return `Занята до ${dayjs.unix(freeSlot.slot).tz().format('HH:mm')}`;
    },
  },

  created() {
    if (this.service.booking.hasTimeSlots) {
      const periodStart = dayjs.tz().startOf('d').unix();
      const periodEnd = periodStart + 86400;
      actions.fetchServiceSlots({ serviceId: this.service.id, periodStart, periodEnd });
    }
  },
};
</script>
