import Vue from 'vue';
import dayjs from 'dayjs';
import http from '@/api/http';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const authUrl = host + '/' + process.env.VUE_APP_API_AUTH.replace(/^\/+|\/+$/g, '');
const otrsUrl = host + '/' + process.env.VUE_APP_API_OTRS.replace(/^\/+|\/+$/g, '');

const initialState = {
  location: null,
  locationId: null,
  orders: [],
  meta: {},
};

export const state = Vue.observable(initialState);

export const getters = {};

export const actions = {
  initData({ locationId, userHash }) {
    state.locationId = locationId;
    http.setLocationId(locationId);
    if (userHash) {
      state.userHash = userHash;
      http.setUserHash(userHash);
    }
  },

  async fetchLocationInfo() {
    if (!state.location && state.locationId) {
      state.location = await http.get(`${authUrl}/location/${state.locationId}`);
      if (state.location) {
        dayjs.tz.setDefault(state.location.city.timezone);
      }
    }
  },

  async fetchOrders(params) {
    const { items, ...meta } = await http.get(`${otrsUrl}/servicing/service-ticket`, { params });
    state.meta = meta;
    state.orders = items;
  },
};
