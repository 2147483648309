<template>
  <div>{{ value ? $t('Да') : $t('Нет') }}</div>
</template>

<script>
export default {
  name: 'CheckboxValue',
  props: {
    value: Boolean,
  },
};
</script>
