<template>
  <div id="tpw">
    <v-app v-if="locationId">
      <v-main>
        <UnitView v-if="selectedUnit" :key="selectedUnit.id" />
        <TerritoryList v-else />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, getters } from '@/store/plan';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import TerritoryList from '@/views/plan/TerritoryList.vue';
import UnitView from '@/views/plan/UnitView.vue';

export default {
  name: 'PlanWidget',

  components: {
    UnitView,
    TerritoryList,
  },

  mixins: [commonPropsMixin, themeMixin],

  computed: {
    selectedUnit() {
      return getters.getSelectedUnit();
    },
  },

  created() {
    actions.setLocationId(this.locationId);
  },

  mounted() {
    actions.fetchLocationData();
    actions.fetchLocationStructure();
  },
};
</script>
