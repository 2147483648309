<template>
  <div class="box">
    <SectionHeader class="title">{{ title || 'Услуги' }}</SectionHeader>
    <div v-if="subtitle" class="text-body-1 primary--text">{{ subtitle }}</div>
    <div v-else class="subtitle-empty"></div>
  </div>
</template>

<script>
import SectionHeader from '@/components/ui/SectionHeader';

export default {
  name: 'PageHeader',

  components: { SectionHeader },

  props: ['title', 'subtitle'],
};
</script>

<style scoped>
.box {
  padding-bottom: 40px;
  padding-top: 10px;
}

.title {
  padding-bottom: 10px;
}

.subtitle-empty {
  padding-bottom: 24px;
}
</style>
