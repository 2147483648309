<template>
  <div>
    <v-form ref="form" :disabled="saving">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            ref="name"
            v-model="form.name"
            :rules="[v => !!v || 'Укажите ФИО']"
            label="Фамилия Имя Отчество"
            name="name"
          />
        </v-col>
        <v-col cols="12" md="4">
          <PhoneField v-model="form.phone" label="Телефон" name="phone" type="phone" />
        </v-col>
        <v-col cols="12" md="8">
          <EmailField
            v-model="form.email"
            required
            label="Электронная почта"
            name="email"
            type="email"
          />
        </v-col>
      </v-row>
      <v-row v-if="isPaid" dense>
        <v-col cols="12">
          <v-select v-model="form.paymentType" label="Способ оплаты" :items="paymentTypeItems" />
        </v-col>
        <v-col v-if="form.paymentType === PaymentType.WIRE_PAYMENT" cols="12">
          <ExternalCompanyForm v-model="externalCompanyForm" />
        </v-col>
      </v-row>
    </v-form>

    <div class="pt-6 d-flex align-center">
      <v-btn
        v-if="!hideBackBtn"
        class="mr-3"
        color="t-grey"
        :disabled="saving"
        outlined
        @click="$emit('back')"
      >
        {{ $t('Назад') }}
      </v-btn>

      <v-btn color="primary" :loading="saving" @click="onSubmit">
        <template v-if="isPaid">
          {{ $t('Купить за {0}', [$n(price, 'currency')]) }}
        </template>
        <template v-else>
          {{ $t('Зарегистрироваться') }}
        </template>
      </v-btn>

      <div v-if="form.paymentType === PaymentType.WIRE_PAYMENT" class="ml-8 text-body-2">
        <div class="primary--text">Информация для Юридических лиц.</div>
        <div class="text--secondary">
          Для оплаты и подтверждения заказа с вами свяжется менеджер. Оплатите заказ до начала
          мероприятия, или он будет отменен.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { actions } from '@/store/eventStore';
import { PaymentType } from '@/constants/payment';
import EmailField from '@/components/ui/EmailField.vue';
import PhoneField from '@/components/ui/PhoneField.vue';
import ExternalCompanyForm from '@/components/payer/ExternalCompanyForm.vue';

export default {
  name: 'EventRegistrationForm',

  components: {
    ExternalCompanyForm,
    EmailField,
    PhoneField,
  },

  props: {
    event: {
      type: Object,
      required: true,
    },
    hideBackBtn: Boolean,
  },

  data() {
    return {
      form: {
        name: null,
        phone: null,
        email: null,
        paymentType: undefined,
      },
      externalCompanyForm: {
        name: null,
        inn: null,
        kpp: null,
        fio: null,
        position: null,
        phone: null,
        email: null,
      },
      saving: false,
    };
  },

  computed: {
    PaymentType() {
      return PaymentType;
    },

    price() {
      return this.event.prices?.guest;
    },

    isPaid() {
      return this.price > 0;
    },

    paymentTypeItems() {
      return [
        { text: 'Банковская карта', value: PaymentType.ONLINE },
        { text: 'Безналичный расчет для Юридических лиц', value: PaymentType.WIRE_PAYMENT },
      ];
    },
  },

  watch: {
    event: {
      immediate: true,
      handler() {
        if (this.isPaid) {
          this.form.paymentType = PaymentType.ONLINE;
        }
      },
    },
  },

  methods: {
    focus() {
      this.$refs.name.focus();
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      const payload = { ...this.form, id: this.event.id };
      if (payload.paymentType === PaymentType.WIRE_PAYMENT) {
        payload.externalPayer = this.externalCompanyForm;
      }

      try {
        const ticket = await actions.createEventTicket(this.event.id, payload);
        await actions.handlePayment(ticket);
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
