<template>
  <v-img
    :src="src || require('@/assets/profile/t-dude_003.png')"
    aspect-ratio="1"
    class="white--text align-end rounded-xl"
    v-bind="$attrs"
    :height="height || size"
    :width="height || size"
  >
    <slot />
  </v-img>
</template>

<script>
export default {
  name: 'UserImg',

  props: {
    height: [String, Number],
    size: [String, Number],
    src: String,
    width: [String, Number],
  },
};
</script>
