<template>
  <v-sheet class="px-2 py-4 px-sm-10 py-sm-8">
    <v-row v-if="event">
      <v-col cols="12" md="8">
        <EventContent class="mb-8" :event="event" @click:tag="onClickTag" />
        <Wrapper v-if="isActual">
          <div v-if="event.registrationType" class="mb-6 text-h3">
            {{ $t('Регистрация на мероприятие') }}
          </div>
          <EventRegistrationForm
            v-if="event.registrationType === 'internal' && hasTickets"
            ref="form"
            v-intersect="onIntersect"
            :event="event"
            :hide-back-btn="type === 'event-view'"
            @back="goBack"
          />
          <div v-else>
            <div v-if="event.registrationType === 'internal' && !hasTickets" class="mb-6">
              {{ 'Мест нет' }}
            </div>
            <div>
              <v-btn
                v-if="type !== 'event-view'"
                class="mr-3"
                color="t-grey"
                outlined
                @click="goBack"
              >
                {{ $t('Назад') }}
              </v-btn>
              <v-btn
                v-if="event.registrationType === 'external'"
                color="primary"
                :href="event.registrationUrl"
                target="_blank"
              >
                {{ $t('Зарегистрироваться') }}
              </v-btn>
            </div>
          </div>
        </Wrapper>
      </v-col>
      <v-col cols="12" md="4">
        <EventAside :top="20">
          <EventInfo :event="event">
            <div
              v-if="isActual && event.registrationType === 'internal' && hasTickets"
              v-show="!formOnScreen"
              class="mt-4"
            >
              <v-btn color="primary" block @click="$refs.form.focus()">
                <template v-if="isPaid">
                  {{ $t('Купить за {0}', [$n(price, 'currency')]) }}
                </template>
                <template v-else>
                  {{ $t('Зарегистрироваться') }}
                </template>
              </v-btn>
            </div>
          </EventInfo>
        </EventAside>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { actions, state } from '@/store/eventStore';
import { EventStatus } from '@/constants/event';
import EventContent from '@/components/event/EventContent.vue';
import EventAside from '@/components/event/EventAside.vue';
import EventInfo from '@/components/event/EventInfo.vue';
import EventRegistrationForm from '@/views/events/EventRegistrationForm.vue';
import Wrapper from '@/components/ui/Wrapper.vue';

export default {
  name: 'EventView',

  components: {
    Wrapper,
    EventRegistrationForm,
    EventInfo,
    EventAside,
    EventContent,
  },

  data() {
    return {
      formOnScreen: false,
    };
  },

  computed: {
    type() {
      return state.type;
    },
    event() {
      return state.currentEvent;
    },

    price() {
      return this.event.prices?.guest;
    },

    isPaid() {
      return this.price > 0;
    },

    isActual() {
      return [EventStatus.CURRENT, EventStatus.UPCOMING].includes(this.event.status);
    },

    hasTickets() {
      return this.event.participantsLimit
        ? this.event.participantsLimit - this.event.ticketCount > 0
        : true;
    },
  },

  mounted() {
    if (state.eventId) {
      actions.fetchCurrentEvent(state.eventId);
    } else if (state.currentEvent) {
      actions.fetchCurrentEvent(state.currentEvent.id);
    }
  },

  methods: {
    goBack() {
      state.currentEvent = null;
    },

    onClickTag(tag) {
      state.params = { tag };
      state.events = [];
      state.meta = {};
      state.currentEvent = null;
    },

    onIntersect(entries, observer, isIntersecting) {
      this.formOnScreen = isIntersecting;
    },
  },
};
</script>
