<template>
  <v-card elevation="0" rounded="0" height="100%" @click="$emit('click')">
    <div class="d-flex flex-no-wrap align-stretch">
      <div class="pa-4 pr-0 pa-md-10 pr-md-0">
        <v-img :src="$crop(category.image, '100x100')" class="polygon" height="64" width="55" />
      </div>
      <div class="d-flex flex-column justify-center">
        <v-card-title class="pa-4 pa-md-10 text-subtitle-1 text-md-h3 text-md-uppercase">
          {{ category.name }}
        </v-card-title>
        <v-card-text
          v-if="category.description"
          class="mt-n2 mt-md-n6 pa-4 pt-0 pa-md-10 pt-md-0 text-body-2 text-md-body-1 text--secondary"
        >
          {{ category.description }}
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CategoryCard',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
};
</script>
