<template>
  <OrderPosition summary class="py-1" :sum="$n(price, 'currency')">
    {{ $t('Итоговая стоимость') }}
  </OrderPosition>
</template>

<script>
import OrderPosition from '@/components/order/OrderPosition';

// refac
export default {
  name: 'OrderItemsTotalPrice',

  components: { OrderPosition },

  props: {
    price: {
      type: Number,
      required: true,
    },
  },
};
</script>
