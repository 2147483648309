<template>
  <div class="py-2">
    <v-icon class="mr-4" color="primary">{{ mdiClockTimeFourOutline }}</v-icon>
    <template v-if="serviceTicket.coworkingTickets.length > 1">
      {{ serviceTicket.coworkingTickets.length }}
      {{ serviceTicket.coworkingTickets.length | plural('билет', 'билета', 'билетов') }}
    </template>
    <CoworkingTicketPeriod :ticket="serviceTicket.coworkingTickets[0]" />
  </div>
</template>

<script>
import { mdiClockTimeFourOutline } from '@mdi/js';
import CoworkingTicketPeriod from '@/components/coworking/CoworkingTicketPeriod.vue';

export default {
  name: 'CoworkingInfo',

  components: { CoworkingTicketPeriod },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mdiClockTimeFourOutline,
    };
  },
};
</script>
