<template>
  <v-text-field
    :required="required"
    :rules="emailRules"
    type="email"
    inputmode="email"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
  </v-text-field>
</template>

<script>
export default {
  name: 'EmailField',

  props: {
    required: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    emailRules() {
      return [
        ...this.rules,
        v => !this.required || !!v || 'Укажите почту',
        v => !v || /.+@.+\..+/.test(v) || 'Укажите корректный email',
      ];
    },
  },
};
</script>
