<template>
  <div>{{ value.join(', ') }}</div>
</template>

<script>
export default {
  name: 'MultiselectValue',
  props: {
    value: Array,
  },
};
</script>
