<template>
  <div>
    <div class="pb-2 ma-n1 d-flex">
      <a
        v-for="item of images"
        :key="item.id"
        :href="item.url"
        :download="item.filename"
        :title="item.filename"
        class="ma-1 text-decoration-none"
        target="_blank"
        @click.prevent="$emit('click-image', item)"
      >
        <v-img :src="$crop(item.url, '100x100')" height="100" width="100" contain />
      </a>
    </div>

    <div class="pb-2 ma-n1">
      <div v-for="item of files" :key="item.id" class="ma-1 d-flex">
        <div class="mr-2">
          <v-icon>{{ mdiPaperclip }}</v-icon>
        </div>
        <div>
          <a
            :href="item.url"
            :download="item.filename"
            :title="item.filename"
            class="text-body-2 text-decoration-none text--secondary font-weight-bold text-break"
            target="_blank"
          >
            {{ item.filename }}
          </a>
          <div class="text-caption text--secondary font-weight-light">
            {{ item.contentSize | formatSize }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiPaperclip } from '@mdi/js';

export default {
  name: 'AttachmentsView',

  props: {
    attachments: {
      type: Array,
      required: true,
    },
  },

  computed: {
    images() {
      return this.attachments.filter(x => this.isImage(x));
    },

    files() {
      return this.attachments.filter(x => !this.isImage(x));
    },

    mdiPaperclip() {
      return mdiPaperclip;
    },
  },

  methods: {
    isImage(attachment) {
      return attachment.contentType.startsWith('image');
    },
  },
};
</script>
