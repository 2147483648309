<template>
  <div
    class="d-flex align-center"
    style="cursor: pointer"
    @click.stop.prevent="$emit('click')"
    @mousedown.stop="() => {}"
  >
    <span class="mr-1" style="font-size: 10px">{{ count }}</span>
    <BounceTransition>
      <SwitchIcon :value="value" :icon-on="iconOn" :icon-off="iconOff" small />
    </BounceTransition>
  </div>
</template>

<script>
import SwitchIcon from '@/components/ui/SwitchIcon';
import BounceTransition from '@/components/ui/BounceTransition';

export default {
  name: 'ItemAction',
  components: { BounceTransition, SwitchIcon },
  props: {
    value: Boolean,
    iconOn: String,
    iconOff: String,
    count: Number,
  },
};
</script>
