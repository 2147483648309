<template>
  <div>
    <PageHeader :title="service.name" :subtitle="subtitle" />

    <v-row>
      <v-col cols="12" md="8">
        <slot v-if="!hideDescription" name="description">
          <BlockTitle class="mb-5">Описание и характеристики</BlockTitle>
          <EditorContent class="mt-4" :content="service.description" />
        </slot>
        <slot />
      </v-col>

      <v-col cols="12" md="4">
        <ServiceImages :images="service.images" />
        <slot name="aside" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PageHeader from '@/components/ui/PageHeader.vue';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import EditorContent from '@/components/ui/EditorContent.vue';
import ServiceImages from '@/components/service/ServiceImages.vue';

export default {
  name: 'ServiceViewPage',

  components: {
    ServiceImages,
    EditorContent,
    BlockTitle,
    PageHeader,
  },

  props: {
    hideDescription: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      required: false,
    },
    subtitle: String,
    title: String,
  },
};
</script>
