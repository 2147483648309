<script>
import FileSelector from '@/components/file/FileSelector.vue';

export default {
  name: 'UploadField',

  functional: true,

  render(h, { data, children }) {
    const { value, ...attrs } = data.attrs;

    const files = (value || []).filter(x => x.file);
    const uploadedFiles = (value || []).filter(x => !x.file);

    const onUpdateFiles = files => {
      data.on.input([...uploadedFiles, ...files]);
    };

    const onUpdateUploadedFiles = urls => {
      data.on.input([...uploadedFiles.filter(x => urls.includes(x.url)), ...files]);
    };

    return h(
      FileSelector,
      {
        ...data,
        attrs: {
          ...attrs,
          files: files,
          uploadedFiles: uploadedFiles.map(x => x.url),
        },
        on: {
          ...data.on,
          'update:files': onUpdateFiles,
          'update:uploadedFiles': onUpdateUploadedFiles,
        },
        props: {
          ...data.props,
          extensions: 'jpg, jpeg, png, pdf, doc, docx, xls, xlsx, csv, txt, rtf',
          maxFiles: 10,
        },
      },
      children
    );
  },
};
</script>
