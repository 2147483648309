export const DeliveryType = {
  COURIER: 'courier',
  PICKUP: 'pickup',
  PICKUP_AND_COURIER: 'pickup_and_courier',
  SELF_PICKUP: 'self_pickup',
};

export const ServiceDialog = {
  BOOKING_ROOM: 'booking.room',
  CONTACTING_TPARK: 'contacting_tpark',
  COFFEE_BREAK: 'coffee_break',
  POSTER: 'poster',
  MASSAGE: 'massage',
};

export const ServiceType = {
  BOOKING: 'booking',
  COMMERCIAL: 'commercial',
  CONTACTING_TPARK: 'contacting_tpark',
  COFFEE_BREAK: 'coffee_break',
  POSTER: 'poster',
  MASSAGE: 'massage',
  EVENT_TICKET: 'event_ticket',
  COWORKING: 'coworking',
  HOTEL: 'hotel',
  RENT_OFFICE: 'rent_office',
};
