<template>
  <div>{{ value | date('short') }}</div>
</template>

<script>
export default {
  name: 'DateValue',
  props: {
    value: String,
  },
};
</script>
