<template>
  <v-badge inline left dot :color="color">
    <span :class="textClass" v-text="text" />
  </v-badge>
</template>

<script>
export default {
  name: 'UnitStatus',

  props: {
    unit: {
      type: Object,
      required: true,
    },
  },

  computed: {
    color() {
      if (!this.unit.rentable) {
        return 't-grey';
      }
      if (this.unit.availableForRent) {
        return 'success';
      }
      return 'error';
    },

    text() {
      if (!this.unit.rentable) {
        return this.$t('Недоступно для аренды');
      }
      if (this.unit.availableForRent) {
        return this.$t('Свободно');
      }
      return this.$t('Занято');
    },

    textClass() {
      return `${this.color}--text`;
    },
  },
};
</script>
