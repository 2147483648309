<template>
  <Card class="d-flex flex-column" :to="to" @click="$emit('click', $event)">
    <AdaptiveImg :src="news.files[0]" />

    <ItemInfo
      class="pt-4 px-6"
      :news="news"
      :liked="liked"
      @click:like="$emit('click:like', $event)"
    />

    <div class="py-2 px-6 text-body-1">
      <div class="secondary--text font-weight-medium">
        {{ news.headline }}
      </div>
      <div v-if="news.category" class="mt-2 text-body-2 t-grey--text">
        {{ news.category.name }}
      </div>
    </div>

    <v-spacer />
    <v-card-text class="pt-0 px-6">
      <v-sheet class="mr-n1" min-height="16">
        <Tag v-if="news.fixedOnTopPosition" class="mr-1" color="primary" text-color="white" x-small>
          {{ $t('Важно') }}
        </Tag>
        <Tag
          v-for="tag in news.tags"
          :key="tag"
          class="mr-1"
          x-small
          @click.prevent.stop="$emit('click:tag', tag)"
        >
          {{ tag }}
        </Tag>
      </v-sheet>
    </v-card-text>

    <slot name="menu" />
  </Card>
</template>

<script>
import Card from '@/components/ui/Card';
import ItemInfo from '@/components/news/ItemInfo';
import AdaptiveImg from '@/components/ui/AdaptiveImg';
import Tag from '@/components/ui/Tag';

export default {
  name: 'NewsCard',

  components: {
    AdaptiveImg,
    Tag,
    ItemInfo,
    Card,
  },

  props: {
    liked: Boolean,
    news: {
      type: Object,
      required: true,
    },
    to: Object,
  },
};
</script>
