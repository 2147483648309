<template>
  <span>
    {{ intervals[0].start | date('dateLong') }},
    <span v-for="({ start, end }, idx) of intervals" :key="start">
      {{ $t('с') }} {{ start | time }} {{ $t('до') }} {{ end | timeTo
      }}<template v-if="idx < intervals.length - 1">{{ ',' }}</template>
    </span>
  </span>
</template>

<script>
import { getReservationIntervals } from '@/utils/serviceTicket';

export default {
  name: 'ReservationIntervals',

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    intervals() {
      return getReservationIntervals(this.serviceTicket.reservation);
    },
  },
};
</script>
