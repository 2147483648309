<template>
  <v-card :height="height" rounded outlined v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-card>
</template>

<script>
export default {
  name: 'Card',

  props: {
    height: {
      type: [String, Number],
      default: '100%',
    },
  },
};
</script>
