<template>
  <div>
    <slot v-bind="{ loading }"></slot>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { buildQueryParams } from '@/utils/pagination';

export default {
  name: 'PaginatedListContainer',

  props: {
    dependency: [String, Number, Object],
    fetchItems: {
      type: Function,
      required: true,
    },
    fetchMore: Function,
    filter: {
      type: Object,
      default: () => ({}),
    },
    lazy: Boolean,
    pagination: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
      needRefresh: false,
      lastQuery: buildQueryParams(this.pagination, this.filter),
    };
  },

  watch: {
    filter: {
      deep: true,
      handler: debounce(function () {
        const pagination = { ...this.pagination };
        if (Object.hasOwn(pagination, 'page')) {
          pagination.page = 1;
        }
        this.$emit('update:pagination', pagination);
      }, 640),
    },

    pagination: {
      deep: true,
      // debounce prevent double call on sort
      handler: debounce(function () {
        this.needRefresh = true;
      }),
    },

    needRefresh(newVal) {
      if (newVal) {
        this.needRefresh = false;
        this.route();
      }
    },

    // $route() {
    //   this.loadData();
    // },

    dependency: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },

  created() {
    if (!this.lazy) {
      this.loadData();
    }
  },

  methods: {
    route() {
      const query = buildQueryParams(this.pagination, this.filter);
      if (JSON.stringify(query) !== JSON.stringify(this.lastQuery)) {
        // this.$router.push({ query });
        this.loadData();
        this.lastQuery = query;
      }
    },

    loadData() {
      this.loading = true;
      const params = buildQueryParams(this.pagination, this.filter);
      this.fetchItems(params)
        .then(() => this.$emit('data-loaded'))
        .finally(() => (this.loading = false));
    },

    /**
     * @public
     */
    loadMore() {
      this.loading = true;
      const params = buildQueryParams(this.pagination, this.filter);
      this.fetchMore(params)
        .then(() => this.$emit('data-loaded'))
        .finally(() => (this.loading = false));
    },

    /**
     * @public
     */
    refresh() {
      return this.loadData();
    },
  },
};
</script>
