<template>
  <div v-if="images.length">
    <div v-for="(img, i) in images" :key="i">
      <v-card @click="openGallery(i)">
        <v-img :src="$crop(img, '390x260')" max-height="252" />
      </v-card>
      <div class="py-4" />
    </div>
    <PhotoViewer :images="showImages" :show-idx="showIdx" />
  </div>
</template>

<script>
import PhotoViewer from '@/components/gallery/PhotoViewer.vue';

export default {
  name: 'ServiceImages',

  components: { PhotoViewer },

  props: {
    images: Array,
  },

  data: () => ({
    showImages: null,
    showIdx: null,
  }),

  computed: {
    length() {
      return this.images.length > this.showCount ? this.showCount : this.images.length;
    },
  },

  methods: {
    openGallery(idx) {
      this.showIdx = idx;
      this.showImages = [...this.images];
    },
  },
};
</script>
