<script>
import dayjs from 'dayjs';
import DatePicker from '@/components/ui/DatePicker.vue';

export default {
  name: 'DateField',

  functional: true,

  render(h, { props, data, children }) {
    const { options } = props.field;
    const min = options && options.fromToday ? dayjs.tz().format('YYYY-MM-DD') : undefined;

    return h(
      DatePicker,
      {
        ...data,
        props: {
          ...data.props,
          min: min,
        },
      },
      children
    );
  },
};
</script>
