<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    width="80vw"
    max-width="1000"
    scrollable
  >
    <v-card>
      <v-card-title>
        <SectionHeader>{{ service.offerTitle }}</SectionHeader>
      </v-card-title>
      <v-card-text>
        <EditorContent :content="service.offerBody" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseDialog from '@/components/ui/BaseDialog';
import EditorContent from '@/components/ui/EditorContent';
import SectionHeader from '@/components/ui/SectionHeader';

export default {
  name: 'ServiceOfferDialog',

  components: { SectionHeader, EditorContent },

  extends: BaseDialog,

  props: {
    service: {
      type: Object,
      required: true,
    },
  },
};
</script>
