<template>
  <v-form ref="form" :disabled="loading">
    <v-row dense>
      <v-col cols="12" md="6">
        <div>{{ $t('Выберите количество гостей') }}</div>
        <v-row dense>
          <v-col cols="12" class="d-flex align-baseline justify-space-between">
            <div class="mr-2">{{ $t('Взрослые') }}</div>
            <QuantityInput v-model="form.adultsNumber" :min="1" />
          </v-col>
          <v-col cols="12" class="d-flex align-baseline justify-space-between">
            <div class="mr-2">{{ $t('Дети') }}</div>
            <QuantityInput v-model="form.childsNumber" :min="0" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6">
        <DatePickerPeriod
          :label="$t('Выберите даты бронирования')"
          :start.sync="form.periodStart"
          :end.sync="form.periodEnd"
          :min="minDate.toISOString()"
          :rules="[$rules.requiredArray()]"
          :hint="$t('Обязательное поле')"
          few-days
          until-midnight
          persistent-hint
          top
        />
      </v-col>
      <v-col v-if="nights" cols="2" class="d-flex align-center">
        {{ nights }} {{ nights | plural('ночь', 'ночи', 'ночей') }}
      </v-col>
    </v-row>
    <v-row v-if="hasCheckinModifiers" dense>
      <v-col cols="12">
        <v-checkbox
          v-model="form.earlyCheckin"
          :label="$t('Требуется ранний заезд')"
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-model="form.lateCheckout"
          :label="$t('Требуется поздний выезд')"
          hide-details
        />
      </v-col>
    </v-row>
    <div class="mt-6 pa-0">
      <BtnPrimary :loading="loading" @click="search">{{ $t('Найти номера') }}</BtnPrimary>
    </div>
  </v-form>
</template>

<script>
import dayjs from 'dayjs';
import hotelApi from '@/api/hotelApi';
import { getNights } from '@/utils/date';
import DatePickerPeriod from '@/components/ui/DatePickerPeriod';
import BtnPrimary from '@/components/ui/BtnPrimary';
import QuantityInput from '@/components/ui/QuantityInput.vue';

export default {
  name: 'HotelSearchForm',

  components: {
    QuantityInput,
    BtnPrimary,
    DatePickerPeriod,
  },

  props: {
    service: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      form: {
        adultsNumber: 1,
        childsNumber: 0,
        periodStart: null,
        periodEnd: null,
        earlyCheckin: false,
        lateCheckout: false,
      },
    };
  },

  computed: {
    minDate() {
      return dayjs.tz().add(1, 'day').startOf('d');
    },

    nights() {
      return getNights(this.form.periodStart, this.form.periodEnd);
    },

    hasCheckinModifiers() {
      return this.service.variants.some(variant =>
        variant.tariffs.some(tariff =>
          tariff.modifiers.some(
            modifier =>
              ['hotel_early_checkin', 'hotel_late_checkout'].includes(modifier.type) &&
              modifier.status === 'active'
          )
        )
      );
    },
  },

  methods: {
    async search() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;
      this.$emit('update:loading', true);
      try {
        const ch = this.service.checkoutTime.split(':')[0];

        let periodStart = this.form.periodStart;
        if (!this.form.earlyCheckin) {
          periodStart = dayjs.unix(this.form.periodStart).tz().hour(ch).startOf('h').unix();
        }

        let periodEnd = this.form.periodEnd;
        if (!this.form.lateCheckout) {
          periodEnd = dayjs.unix(this.form.periodEnd).tz().hour(ch).startOf('h').unix() - 1;
        }

        const payload = {
          ...this.form,
          periodStart,
          periodEnd,
          serviceId: this.service.id,
        };
        const data = await hotelApi.searchVariants(payload);

        const params = {
          ...payload,
          nights: this.nights,
        };
        this.$emit('result', { params, data });
      } finally {
        this.loading = false;
        this.$emit('update:loading', true);
      }
    },
  },
};
</script>
