import { ServiceType } from '@/constants/service';

export const TicketState = {
  NEW: 'new',
  INVOICING: 'invoicing',
  WAITING_FOR_WIRE_PAYMENT: 'waiting for wire payment',
  WAITING_FOR_CONFIRMATION: 'waiting for confirmation',
  CONFIRMED: 'confirmed',
  WAITING_FOR_PAYMENT: 'waiting for payment',
  CANCELED: 'canceled',
  ACTIVE: 'active',
  IN_WORK: 'in_work',
  PACKED: 'packed',
  COMPLETED: 'completed',
  IN_PROGRESS_STAFF: 'in progress (staff)',
  OPEN: 'open',
  PENDING_AUTO_CLOSE_PLUS: 'pending auto close+',
  PENDING_AUTO_CLOSE_MINUS: 'pending auto close-',
  CLOSED_SUCCESSFUL: 'closed successful',
  CLOSED_UNSUCCESSFUL: 'closed unsuccessful',
  CLOSED_SUCCESSFUL_CUSTOMER: 'closed successful (confirmed by customer)',
  CLOSED_UNSUCCESSFUL_CUSTOMER: 'closed unsuccessful (confirmed by customer)',
  PROCESSING_REQUIRED: 'processing required',
  DELIVERY: 'delivery',
};

export const WorkflowBooking = {
  [TicketState.NEW]: [TicketState.NEW, TicketState.CONFIRMED, TicketState.CANCELED],
};

export const WorkflowCoffeeBreak = {
  [TicketState.NEW]: [TicketState.NEW, TicketState.CANCELED],
  [TicketState.CONFIRMED]: [
    TicketState.CONFIRMED,
    TicketState.IN_PROGRESS_STAFF,
    TicketState.PACKED,
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.IN_PROGRESS_STAFF]: [
    TicketState.IN_PROGRESS_STAFF,
    TicketState.PACKED,
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.PACKED]: [
    TicketState.PACKED,
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.DELIVERY]: [
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.PROCESSING_REQUIRED]: [
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.CLOSED_SUCCESSFUL]: [TicketState.CLOSED_SUCCESSFUL, TicketState.CANCELED],
};

export const WorkflowPoster = {
  [TicketState.NEW]: [TicketState.NEW, TicketState.CANCELED],
  [TicketState.CONFIRMED]: [TicketState.CONFIRMED, TicketState.CANCELED],
  [TicketState.IN_PROGRESS_STAFF]: [
    TicketState.IN_PROGRESS_STAFF,
    TicketState.PACKED,
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.PACKED]: [
    TicketState.PACKED,
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.DELIVERY]: [
    TicketState.DELIVERY,
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.PROCESSING_REQUIRED]: [
    TicketState.PROCESSING_REQUIRED,
    TicketState.CLOSED_SUCCESSFUL,
    TicketState.CANCELED,
  ],
  [TicketState.CLOSED_SUCCESSFUL]: [TicketState.CLOSED_SUCCESSFUL, TicketState.CANCELED],
};

export const WorkflowRequest = {
  [TicketState.NEW]: [
    TicketState.IN_WORK,
    TicketState.PENDING_AUTO_CLOSE_PLUS,
    TicketState.CANCELED,
  ],
  [TicketState.IN_WORK]: [TicketState.PENDING_AUTO_CLOSE_PLUS, TicketState.CANCELED],
  [TicketState.PENDING_AUTO_CLOSE_PLUS]: [TicketState.IN_WORK, TicketState.CANCELED],
};

export const WorkflowCoworking = {
  [TicketState.NEW]: [TicketState.CONFIRMED, TicketState.CANCELED],
  [TicketState.INVOICING]: [TicketState.WAITING_FOR_WIRE_PAYMENT, TicketState.CANCELED],
  [TicketState.WAITING_FOR_WIRE_PAYMENT]: [TicketState.CONFIRMED, TicketState.CANCELED],
};

export const WorkflowByType = {
  [ServiceType.COMMERCIAL]: WorkflowRequest,
  [ServiceType.CONTACTING_TPARK]: WorkflowRequest,
  [ServiceType.RENT_OFFICE]: WorkflowRequest,
  [ServiceType.COWORKING]: WorkflowCoworking,
  [ServiceType.HOTEL]: WorkflowCoworking,
};
