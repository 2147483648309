<template>
  <div>
    <PageHeader :title="serviceTicket && serviceTicket.service.name" :subtitle="subtitle" />

    <div v-if="serviceTicket">
      <BlockTitle>Заказ {{ serviceTicket.extId }}</BlockTitle>

      <div class="mt-3">
        <BookingInfo
          v-if="[ServiceType.BOOKING, ServiceType.MASSAGE].includes(serviceTicket.service.type)"
          :service-ticket="serviceTicket"
        />
        <CoffeeBreakInfo
          v-if="[ServiceType.COFFEE_BREAK].includes(serviceTicket.service.type)"
          :service-ticket="serviceTicket"
        />
        <CoworkingInfo
          v-if="[ServiceType.COWORKING].includes(serviceTicket.service.type)"
          :service-ticket="serviceTicket"
        />
        <HotelInfo
          v-if="[ServiceType.HOTEL].includes(serviceTicket.service.type)"
          :service-ticket="serviceTicket"
        />
        <CustomerInfo v-if="serviceTicket.customer.name" :service-ticket="serviceTicket" />
      </div>

      <div v-if="serviceTicket.totalPrice" class="mt-3">
        {{ ' ' }}
        <a
          v-if="payment && payment.fiscalUrl"
          :href="payment.fiscalUrl"
          target="_blank"
          class="primary--text"
        >
          Посмотреть чек
        </a>
        <div class="pt-1 big-sum primary--text">{{ serviceTicket.totalPrice }} ₽</div>
      </div>

      <div class="mt-5">
        <v-btn color="primary" outlined @click="onClickNewOrder">{{ $t('Новый заказ') }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import {
  actions,
  state,
  subscribeToUpdateServiceTicket,
  unsubscribeToUpdateServiceTicket,
} from '@/store/store';
import { PaymentStatus, PaymentType } from '@/constants/payment';
import { ServiceType } from '@/constants/service';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import PageHeader from '@/components/ui/PageHeader.vue';
import BookingInfo from '@/views/services/info/BookingInfo.vue';
import CoworkingInfo from '@/views/services/info/CoworkingInfo.vue';
import CustomerInfo from '@/views/services/info/CustomerInfo.vue';
import HotelInfo from '@/views/services/info/HotelInfo.vue';
import CoffeeBreakInfo from '@/views/services/info/CoffeeBreakInfo.vue';

export default {
  name: 'OrderInfo',

  components: {
    CoffeeBreakInfo,
    HotelInfo,
    CustomerInfo,
    CoworkingInfo,
    BookingInfo,
    PageHeader,
    BlockTitle,
  },

  data() {
    return {
      ServiceType: ServiceType,
    };
  },

  computed: {
    serviceTicket() {
      return state.serviceTicket;
    },

    payment() {
      return state.payment;
    },

    subtitle() {
      if (!this.serviceTicket) {
        return null;
      }

      if (!this.serviceTicket.paymentType) {
        return this.$options.filters.orderStatus(this.serviceTicket);
      }

      if (this.serviceTicket.paymentType === PaymentType.WIRE_PAYMENT) {
        return 'Счёт будет выставлен в ближайшее время';
      }

      if (
        [PaymentStatus.CREATED, PaymentStatus.REDIRECTED].includes(this.serviceTicket.paymentStatus)
      ) {
        return 'Платеж обрабатывается...';
      }

      if ([PaymentStatus.PURCHASED].includes(this.serviceTicket.paymentStatus)) {
        return 'Оплачено';
      }

      return 'Неуспешная оплата. Заказ отменен';
    },
  },

  async created() {
    await actions.fetchServiceTicketResult();
    await subscribeToUpdateServiceTicket();
  },

  beforeDestroy() {
    unsubscribeToUpdateServiceTicket();
  },

  methods: {
    onClickNewOrder() {
      actions.goToCategory(state.serviceTicket.service.categoryId);
    },
  },
};
</script>
