<template>
  <DynamicFieldInput
    :field="field"
    :label="field.name"
    :rules="rules"
    :hint="hint"
    :persistent-hint="!!hint"
    :disabled="form.disabled"
    clearable
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { DynamicFieldType } from '@/constants/dynamicFields';
import DynamicFieldInput from './DynamicFieldInput.vue';

export default {
  name: 'DynamicField',

  components: { DynamicFieldInput },

  inject: ['form'],

  props: {
    field: {
      type: Object,
      required: true,
    },
  },

  computed: {
    rules() {
      const rules = [];
      if (this.field.required) {
        if (
          [DynamicFieldType.UPLOAD, DynamicFieldType.MULTISELECT].includes(this.field.inputType)
        ) {
          rules.push(this.$rules.requiredArray());
        } else if ([DynamicFieldType.CHECKBOX].includes(this.field.inputType)) {
          rules.push(this.$rules.requiredTrue());
        } else {
          rules.push(this.$rules.required());
        }
      }

      if ([DynamicFieldType.NUMBER].includes(this.field.inputType)) {
        rules.push(this.$rules.number());
      }

      return rules;
    },

    hint() {
      if (this.field.description) {
        return this.field.description;
      }

      if (this.field.required) {
        return 'Обязательное поле';
      }

      return null;
    },
  },
};
</script>
