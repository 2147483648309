<template>
  <v-sheet class="px-2 py-4 px-sm-10 py-sm-8">
    <component :is="orderComponent" v-if="serviceTicket" />
    <Wrapper class="mt-6">
      <a class="text-decoration-none" href="#" @click.prevent="back">← {{ $t('Назад') }}</a>
    </Wrapper>
  </v-sheet>
</template>

<script>
import { mdiArrowLeft } from '@mdi/js';
import { state } from '@/store/orders';
import { ServiceType } from '@/constants/service';
import Wrapper from '@/components/ui/Wrapper.vue';
import OrderDefault from '@/views/home/orders/order/OrderDefault.vue';
import OrderBooking from '@/views/home/orders/order/OrderBooking.vue';
import OrderCoffeeBreak from '@/views/home/orders/order/OrderCoffeeBreak.vue';

const componentByType = {
  [ServiceType.BOOKING]: OrderBooking,
  [ServiceType.COMMERCIAL]: OrderDefault,
  // [ServiceType.CONTACTING_TPARK]: OrderTpark,
  [ServiceType.COFFEE_BREAK]: OrderCoffeeBreak,
  // [ServiceType.POSTER]: OrderPoster,
  [ServiceType.MASSAGE]: OrderBooking,
  // [ServiceType.EVENT_TICKET]: OrderEventTicket,
  // [ServiceType.COWORKING]: OrderCoworking,
  // [ServiceType.HOTEL]: OrderHotel,
  [ServiceType.RENT_OFFICE]: OrderDefault,
};

export default {
  name: 'OrderView',
  components: { Wrapper },

  data() {
    return {
      mdiArrowLeft: mdiArrowLeft,
    };
  },

  computed: {
    serviceTicket() {
      return state.viewOrder;
    },

    service() {
      return this.serviceTicket?.service;
    },

    orderComponent() {
      return this.service && (componentByType[this.service.type] || OrderDefault);
    },
  },

  methods: {
    back() {
      state.viewOrder = null;
    },
  },
};
</script>
