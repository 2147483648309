<template>
  <g @mouseenter="$emit('mouseenter', $event)" @mouseleave="$emit('mouseleave', $event)">
    <a
      href="#"
      :class="{
        active: false,
        disabled: disabled, // not shown in editor
        free: item.unit.rentable && item.unit.availableForRent,
        rented: item.unit.rentable && !item.unit.availableForRent,
        service: false,
        unavailable: !item.unit.rentable,
        hover: false,
        selected: false,
        'no-link': !$listeners['click'],
      }"
      @click.prevent="$emit('click', $event)"
    >
      <polygon class="contour" :points="polygonPoints" />
      <text v-if="textPoint" text-anchor="middle" :x="textPoint.x" :y="textPoint.y">
        <tspan v-if="displayName" text-anchor="middle" :x="textPoint.x" :dy="nameDy">
          {{ displayName }}
        </tspan>
        <tspan v-if="displayArea" text-anchor="middle" :x="textPoint.x" :dy="areaDy">
          {{ displayArea }}&nbsp;м²
        </tspan>
      </text>
    </a>
  </g>
</template>

<script>
import { centroid } from '@/utils/plan';

export default {
  name: 'PlanContour',

  props: {
    item: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
  },

  computed: {
    polygonPoints() {
      return this.item.points.map(x => [x.x, x.y].join(',')).join(' ');
    },

    textPoint() {
      return centroid(this.item.points);
    },

    displayName() {
      return !this.item.isNameHidden && this.item.unit.name;
    },

    displayArea() {
      return !this.item.isAreaHidden && this.item.unit.area;
    },

    nameDy() {
      return this.displayName ? '-1em' : null;
    },

    areaDy() {
      return this.displayArea ? '1em' : null;
    },
  },
};
</script>

<style scoped lang="scss">
polygon.contour {
  stroke-width: 1px;
  stroke-dasharray: 11, 3;
  fill: transparent;
  /*transition: all 0.225s cubic-bezier(0.39, 0.575, 0.565, 1);*/
}
@media (min-width: 768px) {
  polygon.contour {
    stroke-width: 2px;
    stroke-dasharray: 14, 5;
  }
}
/*
polygon.bg {
  fill: white;
}
 */
text {
  //transition: all 0.225s cubic-bezier(0.39, 0.575, 0.565, 1);
  font-size: 16px;
  font-weight: 300;
  fill: #3b3b3b;
  text-shadow: 0 0 3px white, 0 0 3px white, 0 0 3px white, 0 0 3px white, 0 0 3px white;
}
a {
  text-decoration: none;
}
a.free polygon.contour {
  stroke: rgba(69, 184, 69, 0.9);
  fill: rgba(69, 184, 69, 0.2);
}
a.reserved polygon.contour {
  stroke: rgba(255, 144, 14, 0.9);
  fill: rgba(255, 144, 14, 0.2);
}
a.rented polygon.contour {
  stroke: rgba(255, 88, 85, 0.9);
  fill: rgba(255, 88, 85, 0.2);
}
a.service polygon.contour {
  stroke: rgba(0, 156, 255, 0.9);
  fill: rgba(0, 156, 255, 0.2);
}
a.unavailable polygon.contour {
  stroke: #989898 !important;
  fill: rgba(152, 152, 152, 0.2) !important;
}
a.unavailable text {
  fill: #989898 !important;
}

/*
a.active polygon.contour,
a.selected polygon.contour {
  stroke-dasharray: 15, 0;
}
a.active text,
a.selected text {
  font-weight: 500;
}
a.active.free polygon.contour {
  fill: url(#StripeFree);
}
a.active.reserved polygon.contour {
  fill: url(#StripeReserved);
}
a.active.rented polygon.contour {
  fill: url(#StripeRented);
}
a.active.service polygon.contour {
  fill: url(#StripeService);
}*/

/*
a.dimmed polygon.contour {
  stroke: #989898 !important;
  fill: rgba(152, 152, 152, 0.2) !important;
}
a.dimmed text {
  fill: #989898 !important;
  filter: none;
}
*/

a:hover:not(.active):not(.selected):not(.disabled),
a.hover:not(.active):not(.selected):not(.disabled) {
  cursor: pointer;
}
a:hover:not(.active):not(.selected):not(.disabled) polygon.contour,
a.hover:not(.active):not(.selected):not(.disabled) polygon.contour {
  //filter: url(#Shadow);
  stroke-dasharray: 15, 0;
}

a.no-link {
  cursor: default !important;
}

/* .disabled plan
.disabled a.free polygon.contour,
.disabled a.free polygon.contour {
  stroke: rgba(120, 133, 120, 0.9);
  fill: rgba(120, 133, 120, 0.2);
}
.disabled a.reserved polygon.contour,
.disabled a.reserved polygon.contour {
  stroke: rgba(207, 140, 62, 0.9);
  fill: rgba(207, 140, 62, 0.2);
}
.disabled a.rented polygon.contour,
.disabled a.rented polygon.contour {
  stroke: rgba(221, 121, 119, 0.9);
  fill: rgba(221, 121, 119, 0.2);
}
.disabled a.service polygon.contour,
.disabled a.service polygon.contour {
  stroke: rgba(51, 145, 204, 0.9);
  fill: rgba(51, 145, 204, 0.2);
}
 */

/* .selectable plan
.selectable a.selected polygon.contour,
.selectable a.selected polygon.contour {
  stroke: #b43881;
  fill: url(#StripeSelected) !important;
}*/
</style>
