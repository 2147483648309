<template>
  <div class="py-2">
    <v-icon class="mr-4" color="primary">{{ mdiClockTimeFourOutline }}</v-icon>
    {{ time }}
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mdiClockTimeFourOutline } from '@mdi/js';

export default {
  name: 'BookingInfo',

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mdiClockTimeFourOutline,
    };
  },

  computed: {
    time() {
      const { slots, timeSlotDuration } = this.serviceTicket.reservation;
      const d1 = dayjs.unix(Math.min(...slots)).tz();
      const d2 = dayjs.unix(Math.max(...slots) + timeSlotDuration).tz();

      return `${d1.format('DD.MM.YYYY')} c ${d1.format('HH:mm')} до ${d2.format('HH:mm')}`;
    },
  },
};
</script>
