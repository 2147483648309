import dayjs from 'dayjs';

export function getNights(start, end) {
  if (!start || !end) {
    return null;
  }

  return dayjs
    .unix(end - 1)
    .tz()
    .startOf('d')
    .diff(dayjs.unix(start).tz().startOf('d'), 'day');
}
