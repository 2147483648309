<template>
  <div>
    <div class="py-2">
      <v-list>
        <template v-for="(item, idx) of serviceTicket.order.items">
          <v-list-item :key="item.id" class="px-6">
            <v-list-item-avatar size="48" tile>
              <v-img :src="item.product.imageUrl" alt="" class="rounded-lg" />
            </v-list-item-avatar>
            <v-list-item-content>
              {{ item.product.commercialName || item.product.name }}
              <v-list-item-subtitle v-if="item.product.description">
                {{ item.product.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              {{ item.count }}
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="idx !== serviceTicket.order.items.length - 1" :key="item.id" />
        </template>
      </v-list>
    </div>
    <div class="py-2">
      <v-icon class="mr-4" color="primary">{{ mdiMapMarkerOutline }}</v-icon>
      {{ serviceTicket.deliveryPlace }}
    </div>
    <div v-if="serviceTicket.needToPack" class="py-2">
      <v-icon class="mr-4">{{ mdiFoodTakeoutBoxOutline }}</v-icon>
      {{ $t('Упаковать с собой') }}
    </div>
    <div v-if="serviceTicket.servedOnTable" class="py-2">
      <v-icon class="mr-4">{{ mdiTableChair }}</v-icon>
      {{ $t('Принести за столик') }}
    </div>
  </div>
</template>

<script>
import {
  mdiCartOutline,
  mdiFoodTakeoutBoxOutline,
  mdiMapMarkerOutline,
  mdiTableChair,
} from '@mdi/js';

export default {
  name: 'CoffeeBreakInfo',

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mdiCartOutline: mdiCartOutline,
      mdiMapMarkerOutline: mdiMapMarkerOutline,
      mdiTableChair: mdiTableChair,
      mdiFoodTakeoutBoxOutline: mdiFoodTakeoutBoxOutline,
    };
  },
};
</script>
