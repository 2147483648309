<template>
  <OrderBase>
    <template #menu>
      <OrderMenu :service-ticket="serviceTicket" />
    </template>

    <template #subtitle>
      <ReservationIntervals :service-ticket="serviceTicket" class="primary--text" />
      <span v-if="showEntryTime && entryTime" class="t-grey--text text-body-2">
        ({{ $t('вход возможен с') }} {{ entryTime | time }})
      </span>
    </template>

    <v-row>
      <v-col cols="12">
        <Wrapper>
          <OrderBlock :title="$t('Заказ')">
            <BookingOrderItems :service-ticket="serviceTicket" />
            <OrderItemsTotalPrice :price="serviceTicket.totalPrice" />
          </OrderBlock>
        </Wrapper>
      </v-col>

      <!--<v-col v-if="serviceTicket.service.showLinkedServices && isPayed" cols="12">
        <v-divider class="pb-4" />
        <Wrapper class="mb-2 font-weight-bold">
          {{ serviceTicket.service.linkedServicesHeader }}
        </Wrapper>
        <LinkedServices :service-ticket="serviceTicket" />
      </v-col>-->
    </v-row>
  </OrderBase>
</template>

<script>
import dayjs from 'dayjs';
import OrderBase from '@/views/home/orders/order/OrderBase';
import { serviceTicketMixin } from '@/views/home/orders/mixins/serviceTicketMixin';
import Wrapper from '@/components/ui/Wrapper';
import OrderBlock from '@/components/order/OrderBlock';
import BookingOrderItems from '@/components/order/booking/BookingOrderItems';
import OrderItemsTotalPrice from '@/components/order/OrderItemsTotalPrice';
import OrderMenu from '@/views/home/orders/order/menu/OrderMenu';
import ReservationIntervals from '@/components/booking/ReservationIntervals';

export default {
  name: 'OrderBooking',

  components: {
    ReservationIntervals,
    OrderMenu,
    OrderItemsTotalPrice,
    BookingOrderItems,
    OrderBlock,
    Wrapper,
    OrderBase,
  },

  mixins: [serviceTicketMixin],

  computed: {
    // за "30" минут до начала бронирования проверить
    // занят ли слот времени до бронирования пользователя
    // и отобразить время, когда можно войти в переговорку
    showEntryTime() {
      if (this.serviceTicket.service.type !== this.$ServiceType.BOOKING) {
        return false;
      }

      if (this.serviceTicket.status !== this.$stStatus.CONFIRMED) {
        return false;
      }

      const diff = this.reservationStart - dayjs().unix();
      const { timeSlotDuration } = this.serviceTicket.service.booking;

      return diff < timeSlotDuration && diff > -timeSlotDuration;
    },

    reservationStart() {
      return Math.min(...this.serviceTicket.reservation.slots);
    },

    prevSlotTime() {
      return this.reservationStart - this.serviceTicket.reservation.timeSlotDuration;
    },

    prevSlotReserved() {
      return this.getSlot(this.serviceTicket.serviceId, this.prevSlotTime)?.reserved;
    },

    entryTime() {
      return this.prevSlotReserved
        ? this.reservationStart
        : this.reservationStart - (this.serviceTicket.service.booking?.earlyAccess || 0);
    },
  },

  watch: {
    showEntryTime: {
      immediate: true,
      handler(val) {
        if (val && this.prevSlotReserved === undefined) {
          this.fetchServiceSlots({
            serviceId: this.serviceTicket.serviceId,
            periodStart: this.prevSlotTime,
            periodEnd: this.prevSlotTime,
          });
        }
      },
    },
  },

  methods: {
    getSlot() {},

    fetchServiceSlots() {},
  },
};
</script>
