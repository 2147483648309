import { VBtn } from 'vuetify/lib';

export default {
  name: 'BtnAction',
  functional: true,
  render(h, { data, children }) {
    return h(
      VBtn,
      {
        ...data,
        attrs: {
          color: 't-menu-bg',
          ...data.attrs,
        },
      },
      children
    );
  },
};
