<template>
  <v-text-field
    v-mask="mask"
    :value="value"
    :rules="[
      v => !!v || 'Укажите номер',
      v => !v || /^[78]\d{10}$/.test(String(v).replace(/\D/g, '')) || 'Укажите корректный номер',
    ]"
    validate-on-blur
    v-bind="$attrs"
    @keyup="onKeyup"
    v-on="$listeners"
  />
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'PhoneField',

  directives: { mask },

  props: ['value'],

  data: () => ({
    initialMask: '+7 (###) ###-##-##',
    mask: '+7 (###) ###-##-##',
  }),

  methods: {
    onKeyup(e) {
      if (this.value === '') {
        this.mask = this.initialMask;
      }

      if (e.key === '8' && this.value === '+7 (8') {
        this.$emit('input', '8');
        this.$nextTick(() => (this.mask = '8 (###) ###-##-##'));
      }
    },
  },
};
</script>
