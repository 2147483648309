<template>
  <div>
    <v-sheet class="py-2">
      <FileList
        :files="files"
        :icon-size="40"
        :disabled="saving"
        @remove="$refs.files.remove($event)"
      />

      <v-textarea
        v-model.trim="comment"
        :label="$t('Введите текст...')"
        background-color="transparent"
        class="comment overflow-auto"
        style="max-height: 30vh"
        rows="1"
        auto-grow
        solo
        flat
        hide-details
        :disabled="saving"
        @keydown.enter.exact="submit"
      >
        <template slot="prepend-inner">
          <v-btn icon :disabled="files.length >= maxFiles" @click="$refs.files.click()">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-badge :content="files.length" :value="files.length" overlap>
                  <v-icon v-bind="attrs" v-on="on">{{ mdiPaperclip }}</v-icon>
                </v-badge>
              </template>
              <span>{{ $t('Максимальный размер файла: 50 MB') }}</span>
            </v-tooltip>
          </v-btn>
        </template>
        <template slot="append">
          <v-btn icon :disabled="sendDisabled" :loading="saving" @click="submit">
            <v-icon>{{ mdiSend }}</v-icon>
          </v-btn>
        </template>
      </v-textarea>

      <FileInput id="tcf" ref="files" v-model="files" accept="*" :max-files="maxFiles" />
    </v-sheet>
  </div>
</template>

<script>
import { mdiPaperclip, mdiSend } from '@mdi/js';
import FileList from '@/components/file/FileList';
import FileInput from '@/components/file/FileInput';

export default {
  name: 'ChatInput',

  components: { FileInput, FileList },

  props: {
    sendComment: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    comment: '',
    files: [],
    saving: false,
    maxFiles: 5,
  }),

  computed: {
    sendDisabled() {
      return !this.comment && !this.files.length;
    },

    mdiPaperclip() {
      return mdiPaperclip;
    },
    mdiSend() {
      return mdiSend;
    },
  },

  methods: {
    async submit() {
      if (this.sendDisabled) {
        return;
      }

      this.saving = true;

      await this.sendComment({
        article: {
          subject: this.$t('Комментарий с сайта'),
          body: this.comment,
        },
        files: this.files,
      });

      this.reset();

      this.saving = false;
    },

    reset() {
      this.comment = '';
      this.$refs.files.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  &.v-textarea::v-deep {
    .v-input__prepend-inner,
    .v-input__prepend-outer,
    .v-input__append-inner,
    .v-input__append-outer {
      align-self: flex-end !important;
      margin-top: 0;
      margin-bottom: 6px;
    }
  }
}
</style>
