<template>
  <div>
    <template v-if="!prices || (!prices.guest && !prices.resident)">{{ $t('Бесплатно') }}</template>
    <template v-else-if="prices.guest === prices.resident">
      {{ $n(prices.guest, 'currency') }}
    </template>
    <template v-else>
      <div class="mb-2">
        {{ prices.guest > 0 ? $n(prices.guest, 'currency') : $t('Бесплатно') }}
        {{ $t('для гостей') }}
      </div>
      <div>
        {{ prices.resident > 0 ? $n(prices.resident, 'currency') : $t('Бесплатно') }}
        {{ $t('для резидентов') }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EventPrices',
  props: {
    prices: Object,
  },
};
</script>
