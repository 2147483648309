export const PriceUnit = {
  HOUR: 'hour',
  DAY: 'day',
};

export const PriceUnitLabel = {
  day: 'Сутки',
  hour: 'Час',
};

export const ModifierAffect = {
  TARIFF_PRICE: 'price',
  TOTAL_COST: 'total cost',
};
