<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div class="font-weight-medium">Реквизиты Юридического лица для выставления счета</div>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="form.name" label="Наименование" :rules="[$rules.required()]" />
      </v-col>
      <v-col cols="0" md="6"></v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.inn"
          label="ИНН"
          :rules="[$rules.required(), $rules.inn()]"
          maxlength="12"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.kpp"
          label="КПП"
          :rules="[$rules.required(), $rules.kpp()]"
          maxlength="9"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <div class="font-weight-medium">Контактная информация для выставления счета</div>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.fio"
          label="ФИО ответственного лица"
          :rules="[$rules.required()]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field v-model="form.position" label="Должность" :rules="[]" />
      </v-col>
      <v-col cols="12" md="6">
        <PhoneField
          v-model="form.phone"
          label="Телефон ответственного лица"
          :rules="[$rules.required()]"
        />
      </v-col>
      <v-col cols="12" md="6">
        <EmailField
          v-model="form.email"
          label="Электронная почта ответственного лица"
          :rules="[$rules.required()]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formProxyMixin } from '@/mixins/forms';
import PhoneField from '@/components/ui/PhoneField.vue';
import EmailField from '@/components/ui/EmailField.vue';

export default {
  name: 'ExternalCompanyForm',

  components: {
    EmailField,
    PhoneField,
  },

  mixins: [formProxyMixin],
};
</script>
