<template>
  <DatePicker
    v-model="period"
    :allowed-dates="allowedDates"
    :few-days="fewDays"
    :min="minDate || min"
    :max="maxDate || max"
    :rules="rules"
    range
    v-bind="$attrs"
  />
</template>

<script>
import dayjs from 'dayjs';
import DatePicker from '@/components/ui/DatePicker';

export default {
  name: 'DatePickerPeriod',

  components: { DatePicker },

  props: {
    allowedDates: Function,
    disabledDates: Array, // array of timestamp
    fewDays: Boolean,
    start: Number, // unix timestamp
    end: Number, // unix timestamp
    max: String, // y-m-d
    min: String, // y-m-d
    rules: Array,
    untilMidnight: Boolean, // end date 23:59:59
  },

  data: () => ({
    period: null,
    minDate: null,
    maxDate: null,
  }),

  computed: {
    inputValue() {
      return `${this.start || ''}-${this.end || ''}`;
    },
  },

  watch: {
    inputValue: {
      immediate: true,
      handler(val) {
        const parts = val.split('-');

        let d1 = parts[0] ? dayjs.unix(parts[0]).tz() : null;
        let d2 = parts[1] ? dayjs.unix(parts[1] - 86400 + (this.untilMidnight ? 1 : 0)).tz() : null;

        this.period =
          d1 && d2 ? [d1 && d1.format('YYYY-MM-DD'), d2 && d2.format('YYYY-MM-DD')] : null;
      },
    },

    period(val) {
      if (!val) {
        this.minDate = null;
        this.$emit('update:start', null);
        this.$emit('update:end', null);
      }

      if (val && val.length) {
        const startDate = dayjs.tz(val[0]);
        const startTime = startDate.unix();
        this.minDate = startDate.format('YYYY-MM-DD');
        const firstDisabled = this.disabledDates && this.disabledDates.find(x => x > startTime);
        if (firstDisabled) {
          this.maxDate = dayjs.unix(firstDisabled).tz().format('YYYY-MM-DD');
        }
      }

      if (val && val.length === 2) {
        this.minDate = null;
        this.maxDate = null;
        this.$emit('update:start', dayjs.tz(val[0]).unix());
        this.$emit('update:end', dayjs.tz(val[1]).unix() + 86400 - (this.untilMidnight ? 1 : 0));
      }
    },
  },
};
</script>
