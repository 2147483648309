<template>
  <div>
    <div class="mb-3 text-subtitle-1 font-weight-medium">
      {{ tableField.name }}
    </div>
    <div v-if="blocks.length" class="d-flex flex-column gap-2">
      <div v-for="(block, idx) of blocks" :key="block.item">
        <div class="mb-1 d-flex align-center justify-space-between">
          <div>
            <span v-if="tableField.options.multiple">{{ $t('Элемент {0}', [idx + 1]) }}</span>
          </div>
          <div v-show="blockDeletionAllowed">
            <v-icon :disabled="isDisabled" @click="onDelete(idx)">{{ mdiDeleteOutline }}</v-icon>
          </div>
        </div>
        <DynamicFields v-model="block.values" :dynamic-fields="tableField.children" />
      </div>
    </div>
    <div class="mt-6">
      <BtnSecondary
        v-if="!blocks.length || isMultiple"
        rounded
        :disabled="isDisabled"
        @click="onAdd"
      >
        <v-icon left>{{ mdiPlus }}</v-icon>
        {{ $t('Добавить') }}
      </BtnSecondary>
    </div>
  </div>
</template>

<script>
import DynamicFields from '@/components/dynamic-fields/DynamicFields.vue';
import BtnSecondary from '@/components/ui/BtnSecondary';
import { mdiDeleteOutline, mdiPlus } from '@mdi/js';

export default {
  name: 'DynamicTableFields',

  components: {
    BtnSecondary,
    DynamicFields,
  },

  inject: ['form'],

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    tableField: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Array,
    },
  },

  data() {
    return {
      blocks: [],
    };
  },

  computed: {
    mdiPlus() {
      return mdiPlus;
    },
    mdiDeleteOutline() {
      return mdiDeleteOutline;
    },

    isDisabled() {
      return this.form?.disabled;
    },

    isMultiple() {
      return this.tableField.options?.multiple ?? false;
    },

    isRequired() {
      return this.tableField.required ?? false;
    },

    blockDeletionAllowed() {
      return !this.isRequired || this.blocks.length > 1;
    },

    values() {
      const values = [];
      this.blocks.forEach((block, index) => {
        const blockValues = block.values.map(x => ({ ...x, item: index + 1 }));
        values.push(...blockValues);
      });
      return values;
    },
  },

  watch: {
    blocks: {
      deep: true,
      handler() {
        const values = this.values.filter(x => x.value);
        if (values.length) {
          values.unshift({
            id: this.tableField.id,
            parentId: this.tableField.parentId,
            name: this.tableField.name,
            inputType: this.tableField.inputType,
            value: '',
          });
        }
        this.$emit('update:modelValue', values);
      },
    },
  },

  created() {
    for (const value of this.modelValue ?? []) {
      if (value.inputType === 'table') {
        continue;
      }

      let block = this.blocks.find(x => x.item === value.item);
      if (block) {
        block.values.push(value);
      } else {
        this.blocks.push({ item: value.item, values: [value] });
      }
    }
  },

  mounted() {
    if (this.blocks.length === 0) {
      this.onAdd();
    }
  },

  methods: {
    onAdd() {
      this.blocks.push({
        item: new Date().getTime(),
        values: this.tableField.children.map(x => ({
          id: x.id,
          parentId: x.parentId,
          name: x.name,
          inputType: x.inputType,
          value: null,
        })),
      });
    },

    onDelete(idx) {
      this.blocks.splice(idx, 1);
    },
  },
};
</script>
