<template>
  <div>
    <div v-if="parent" class="mb-2">
      <span class="t-grey--text">{{ $t('Основной заказ') }} </span>
      {{ parent.serviceName }} {{ parent.extId }}
    </div>

    <ServiceTicketHeaderCommon :service-ticket="serviceTicket">
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </ServiceTicketHeaderCommon>
  </div>
</template>

<script>
import ServiceTicketHeaderCommon from '@/components/order/ServiceTicketHeaderCommon.vue';

export default {
  name: 'ServiceTicketHeader',

  components: { ServiceTicketHeaderCommon },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    parent() {
      return this.serviceTicket.parentServiceTicket;
    },
  },
};
</script>
