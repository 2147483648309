<script>
import { VSelect } from 'vuetify/lib';

export default {
  name: 'SelectField',

  functional: true,

  render(h, { props, data, children }) {
    return h(
      VSelect,
      {
        ...data,
        props: {
          ...data.props,
          items: props.field.values.map(x => ({ text: x, value: x })),
        },
      },
      children
    );
  },
};
</script>
