<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="value"
        append-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="{ ...$attrs, ...attrs }"
        v-on="on"
        @click:clear="$emit('input', null)"
      />
    </template>
    <v-time-picker
      v-if="menu"
      :value="value"
      :min="min"
      :max="max"
      :allowed-hours="allowedHours"
      :allowed-minutes="allowedMinutes"
      format="24hr"
      full-width
      scrollable
      @click:hour="onClickHour"
      @click:minute="onClickMinute"
      @change="onChange"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'TimePicker',

  inheritAttrs: false,

  props: {
    value: String,
    min: String,
    max: String,
    allowedHours: [Function, Array],
    allowedMinutes: [Function, Array],
  },

  data: () => ({
    menu: null,
  }),

  methods: {
    open() {
      this.menu = true;
    },

    onClickHour(val) {
      this.$emit('click:hour', val);
    },

    onClickMinute(val) {
      this.$emit('click:minute', val);
      this.$refs.menu.save(this.value);
    },

    onChange(time) {
      this.$emit('input', time);
    },
  },
};
</script>
