<template>
  <div class="mx-n1">
    <Tag
      v-for="(tag, i) in tags"
      :key="i"
      :small="small"
      :x-small="xSmall"
      :close="close"
      class="ma-1"
      @click.prevent="$listeners['click:tag'] ? $emit('click:tag', tag) : null"
      @click:close="$emit('click:close', tag)"
      @mousedown.stop="() => {}"
    >
      {{ tag }}
    </Tag>
  </div>
</template>

<script>
import Tag from '@/components/ui/Tag';

export default {
  name: 'Tags',
  components: { Tag },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    small: Boolean,
    xSmall: Boolean,
    close: Boolean,
  },
};
</script>
