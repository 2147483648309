<template>
  <div id="tpw">
    <v-app v-if="locationId && userHash">
      <v-main>
        <LastOrders :limit="4" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/lastOrders';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import LastOrders from '@/views/last-orders/LastOrders.vue';

export default {
  name: 'LastOrdersWidget',

  components: { LastOrders },

  mixins: [commonPropsMixin, themeMixin],

  computed: {
    location() {
      return state.location;
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
      userHash: this.userHash,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
  },
};
</script>
