import { toBase64 } from '@/utils/utils';
import http from '@/api/http';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const storageUrl = host + '/' + process.env.VUE_APP_API_STORAGE.replace(/^\/+|\/+$/g, '');

export const uploadFiles = async files => {
  const result = [];

  if (files && files.length > 0) {
    const reqs = [];
    for (let file of files) {
      if (typeof file === 'string' && file.startsWith('/')) {
        const res = await fetch(file);
        const buf = await res.arrayBuffer();
        file = new File([buf], file.split('/').pop());
      }

      reqs.push(
        http.post(`${storageUrl}/upload`, {
          content: await toBase64(file.file || file),
          fileName: file?.name,
        })
      );
    }

    const responses = await Promise.all(reqs);
    for (let res of responses) {
      result.push(res.url);
    }
  }

  return result;
};
