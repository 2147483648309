<template>
  <div v-if="images.length">
    <div v-for="(img, i) in images" :key="i">
      <v-card @click="$refs.gallery.show(images, i)">
        <v-img :src="$crop(img, '390x260')" max-height="252" />
      </v-card>
      <div class="py-4" />
    </div>
    <PhotoViewer ref="gallery" />
  </div>
</template>

<script>
import PhotoViewer from '@/components/gallery/PhotoViewer.vue';

export default {
  name: 'ServiceImages',

  components: { PhotoViewer },

  props: {
    images: Array,
  },

  computed: {
    length() {
      return this.images.length > this.showCount ? this.showCount : this.images.length;
    },
  },
};
</script>
