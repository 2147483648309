<template>
  <v-img
    class="icon"
    :src="require(`@/assets/attachment-icons/${icon}.svg`)"
    :height="size"
    :width="size"
  />
</template>

<script>
const iconByExt = {
  default: 'file',
  pptx: 'file_ppt',
  ppt: 'file_ppt',
  doc: 'file_doc',
  docx: 'file_doc',
  rtf: 'file_doc',
  zip: 'file_zip',
  '7z': 'file_7z',
  rar: 'file_rar',
  pdf: 'file_pdf',
  xls: 'file_xls',
  xlsx: 'file_xls',
  txt: 'file_txt',
  exe: 'file_exe',
  mp4: 'file_video',
  webm: 'file_video',
  mp3: 'file_audio',
  wav: 'file_audio',
};

const iconByMediaType = {
  audio: 'file_audio',
  video: 'file_video',
};

export default {
  name: 'AttachmentIcon',

  props: {
    filename: {
      type: String,
      required: true,
    },
    contentType: String,
    size: {
      type: [String, Number],
      default: 64,
    },
  },

  computed: {
    icon() {
      const ext = this.filename.split('.').pop();
      const type = this.contentType && this.contentType.split('/')[0];
      return iconByExt[ext] || iconByMediaType[type] || iconByExt.default;
    },
  },
};
</script>
