<template>
  <v-sheet class="px-2 py-4 px-sm-10 py-sm-8">
    <v-row v-if="serviceTicket">
      <v-col cols="8">
        <Wrapper v-if="showSuccess">
          <div class="mb-6">
            <h1 class="text-h1">{{ $t('Регистрация успешно завершена!') }}</h1>
          </div>

          <EventInfo v-if="event" class="mb-4" :event="event" compact />

          <p>
            Спасибо за регистрацию на мероприятие. Ваш электронный билет был отправлен на указанный
            адрес электронной почты {{ serviceTicket.customer.email }}.
          </p>
          <p>
            Пожалуйста, проверьте вашу почту, в том числе папку со спамом, чтобы убедиться в
            получении билета. Предъявите этот билет при входе на мероприятие.
          </p>
        </Wrapper>
        <Wrapper v-else-if="showProgress">
          {{ 'Платеж обрабатывается...' }}
        </Wrapper>
        <Wrapper v-else>
          {{ 'Неуспешная оплата. Заказ отменен.' }}
        </Wrapper>

        <Wrapper v-if="type === 'event-view'" class="mt-6 d-flex gap-2">
          <v-btn color="t-grey" outlined @click="goToEvent">{{ $t('Назад') }}</v-btn>
        </Wrapper>
        <Wrapper v-else class="mt-6 d-flex gap-2">
          <v-btn color="t-grey" outlined @click="goToEvent">{{ $t('К мероприятию') }}</v-btn>
          <v-btn color="t-grey" outlined @click="goToList">{{ $t('В список') }}</v-btn>
        </Wrapper>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import {
  actions,
  state,
  subscribeToUpdateServiceTicket,
  unsubscribeToUpdateServiceTicket,
} from '@/store/eventStore';
import { PaymentStatus } from '@/constants/payment';
import Wrapper from '@/components/ui/Wrapper.vue';
import EventInfo from '@/components/event/EventInfo.vue';

export default {
  name: 'EventRegistrationInfo',

  components: {
    EventInfo,
    Wrapper,
  },

  computed: {
    type() {
      return state.type;
    },
    serviceTicket() {
      return state.serviceTicket;
    },
    event() {
      return state.currentEvent;
    },
    payment() {
      return state.payment;
    },

    showSuccess() {
      if (!this.serviceTicket) {
        return false;
      }

      return (
        !this.serviceTicket.paymentType ||
        this.serviceTicket.paymentType === 'wire_payment' ||
        (this.serviceTicket.paymentType === 'online' &&
          this.serviceTicket.paymentStatus === PaymentStatus.PURCHASED)
      );
    },

    showProgress() {
      return [PaymentStatus.CREATED, PaymentStatus.REDIRECTED].includes(
        this.serviceTicket.paymentStatus
      );
    },
  },

  async mounted() {
    if (!this.serviceTicket) {
      await actions.fetchServiceTicket();
    }
    subscribeToUpdateServiceTicket();

    if (!this.event) {
      actions.fetchCurrentEvent(this.serviceTicket.metadata.eventId);
    }
  },

  beforeDestroy() {
    unsubscribeToUpdateServiceTicket();
  },

  methods: {
    goToEvent() {
      state.token = null;
      state.serviceTicket = null;
      state.payment = null;
    },

    goToList() {
      state.token = null;
      state.serviceTicket = null;
      state.payment = null;
      state.currentEvent = null;
    },
  },
};
</script>
