<template>
  <div>
    <div class="mb-2 font-weight-bold">{{ title }}</div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'OrderBlock',
  props: {
    title: String,
  },
};
</script>
