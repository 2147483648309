<template>
  <v-dialog v-model="dialog" :persistent="saving" v-bind="$attrs" width="320">
    <v-card>
      <v-card-title>
        {{ $t('Закрыть заявку?') }}
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="select" column hide-details class="mt-0">
          <v-radio :label="$t('Выполнена')" value="close"></v-radio>
          <v-radio :label="$t('Неактуальна')" value="cancel"></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <TextBtn :disabled="saving" @click="dialog = false">
          {{ $t('Отменить') }}
        </TextBtn>
        <TextBtn :loading="saving" @click="submit">
          {{ $t('Да') }}
        </TextBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { actions } from '@/store/orders';
import { TicketState } from '@/constants/ticketState';
import TextBtn from '@/components/ui/TextBtn';

export default {
  name: 'TicketCloseModal',

  components: { TextBtn },

  props: {
    serviceTicketId: {
      type: Number,
      required: true,
    },
    ticketId: {
      type: Number,
      required: true,
    },
    state: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    saving: false,
    select: 'close',
  }),

  watch: {
    serviceTicketId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.dialog = true;
        }
      },
    },

    dialog(val) {
      if (val === false) {
        this.$emit('close');
      }
    },
  },

  methods: {
    async submit() {
      this.saving = true;

      let state = TicketState.CANCELED;
      if (this.select === 'close') {
        state =
          this.state === TicketState.PENDING_AUTO_CLOSE_MINUS
            ? TicketState.CLOSED_UNSUCCESSFUL_CUSTOMER
            : TicketState.CLOSED_SUCCESSFUL_CUSTOMER;
      }

      const payload = {
        id: this.serviceTicketId,
        state: state,
      };

      await actions
        .updateState(payload)
        .then(() => (this.dialog = false))
        .finally(() => (this.saving = false));
    },
  },
};
</script>
