export function buildQueryParams(pagination, filter) {
  const { page, itemsPerPage } = pagination;
  const { sortDesc, sortBy } = pagination;

  let sort;
  if (sortBy) {
    sort = sortBy + (sortDesc ? '-' : '');
  }

  const params = {
    page,
    pageSize: itemsPerPage,
    // limit: itemsPerPage,
    // offset: page * itemsPerPage - itemsPerPage,
    sort: sort,
  };

  return {
    ...buildQueryFilter(params),
    ...buildQueryFilter(filter),
  };
}

export function makePagination(query, defaults = {}) {
  let { sortBy, sortDesc } = defaults;
  if (query.sort) {
    sortBy = query.sort;
    sortDesc = query.sort.endsWith('-');
    if (sortDesc) {
      sortBy = sortBy.slice(0, -1);
    }
  }

  return {
    page: query.page ? Number(query.page) : 1,
    itemsPerPage: query.pageSize ? Number(query.pageSize) : 10,
    sortBy: sortBy,
    sortDesc: sortDesc,
  };
}

export function makeInfinityPaging(query, defaults = {}) {
  let { sortBy, sortDesc } = defaults;
  if (query.sort) {
    sortBy = query.sort;
    sortDesc = query.sort.endsWith('-');
    if (sortDesc) {
      sortBy = sortBy.slice(0, -1);
    }
  }

  return {
    sortBy: sortBy,
    sortDesc: sortDesc,
  };
}

export function makeFilter(query, types, defaults) {
  const res = {};

  Object.keys(query).forEach(key => {
    if (!(key in types)) {
      return;
    }

    if (query[key] === null || query[key] === undefined) {
      res[key] = null;
      return;
    }

    if (Array.isArray(types[key])) {
      const singleType = types[key][0];
      if (typeof singleType === 'object') {
        res[key] = query[key] ? query[key].map(x => makeFilter(x, singleType)) : null;
      }
      if (typeof singleType === 'function') {
        res[key] = query[key] ? query[key].split(',').map(x => singleType(x)) : null;
      }
      return;
    }

    res[key] = types[key] ? types[key](query[key]) : query[key];
  });

  return { ...defaults, ...res };
}

export const buildQueryFilter = data => {
  return Object.keys(data)
    .filter(key => data[key] !== undefined && data[key] !== null && data[key] !== '')
    .filter(key => !Array.isArray(data[key]) || data[key].length > 0)
    .reduce((obj, key) => {
      obj[key] = makeParam(data[key]);
      return obj;
    }, {});
};

const makeParam = val => (Array.isArray(val) && typeof val[0] !== 'object' ? val.join(',') : val);
