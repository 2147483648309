<template>
  <div>
    <v-carousel height="auto" hide-delimiters>
      <v-carousel-item
        v-for="(img, i) in images"
        :key="i"
        :src="$crop(img, '390x260')"
        :aspect-ratio="3 / 2"
        @click="$refs.gallery.show(images, i)"
      />
    </v-carousel>

    <template v-if="variant">
      <div class="mt-6">
        <div class="text-h3 pb-4">{{ variant.name }}</div>
        <nl2br tag="div" :text="variant.description" />
      </div>
      <div class="mt-4 primary--text">
        <slot name="price"></slot>
      </div>
    </template>

    <PhotoViewer ref="gallery" />
  </div>
</template>

<script>
import PhotoViewer from '@/components/gallery/PhotoViewer.vue';

export default {
  name: 'AsideInfo',

  components: { PhotoViewer },

  props: {
    service: {
      required: true,
      type: Object,
    },
    variant: {
      type: Object,
    },
  },

  computed: {
    images() {
      return this.variant?.images ?? this.service.images;
    },
  },
};
</script>
