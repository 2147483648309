<template>
  <v-sheet
    class="px-6 d-flex align-center"
    :class="{
      't-back-light t-additional--text': !type,
      'primary white--text': type === 'packed',
      'success white--text': type === 'delivery',
    }"
    :min-height="height"
  >
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: 'OrderStatusBox',

  props: {
    height: {
      type: [Number, String],
      default: 48,
    },
    type: {
      type: String,
      validator: value => ['packed', 'delivery'].includes(value),
    },
  },
};
</script>
