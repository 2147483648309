export function centroid(polygon) {
  var i = -1,
    n = polygon.length,
    x = 0,
    y = 0,
    a,
    b = polygon[n - 1],
    c,
    k = 0;

  while (++i < n) {
    a = b;
    b = polygon[i];
    k += c = a.x * b.y - b.x * a.y;
    x += (a.x + b.x) * c;
    y += (a.y + b.y) * c;
  }

  return (k *= 3), { x: x / k, y: y / k };
}

export function getDistance(pt1, pt2) {
  return Math.sqrt((pt2.x - pt1.x) ** 2 + (pt2.y - pt1.y) ** 2);
}
