import Vue from 'vue';
import dayjs from 'dayjs';
import http from '@/api/http';
import { uploadFiles } from '@/api/storage';
import { DynamicFieldType } from '@/constants/dynamicFields';
import { PaymentType } from '@/constants/payment';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const authUrl = host + '/' + process.env.VUE_APP_API_AUTH.replace(/^\/+|\/+$/g, '');
const otrsUrl = host + '/' + process.env.VUE_APP_API_OTRS.replace(/^\/+|\/+$/g, '');

const initialState = {
  userHash: null,
  // loc
  locationId: 1,
  location: null,
  publicOffer: null,
  // catalog
  categories: [],
  services: [],
  selectedCategory: null,
  selectedService: null,
  slotsByService: {
    // id => [...slots],
  },
  calendar: { 0: {} },
  workDates: { 0: [] },
  workTime: { 0: { '2020-02-20': [] } },
  // coffee-break
  cbCategories: [],
  cbProducts: [],
  // coworking
  occupation: {
    // key => [{startTime, free}, ...],
  },
  // result
  token: localStorage.getItem('serviceTicketToken'),
  serviceTicket: null,
  payment: null,
};

const getKey = params => {
  return Object.keys(params)
    .sort()
    .filter(key => params[key])
    .map(key => [key, params[key]].join('='))
    .join('&');
};

export const state = Vue.observable(initialState);

export const getters = {
  getServiceSlots: (id, time1, time2) => {
    const slots = state.slotsByService[id] || [];

    return slots.filter(x => x.slot >= time1 && x.slot < time2);
  },

  getWorkDates: serviceId => state.workDates?.[serviceId] || [],

  getWorkTime: (serviceId, date) => state.workTime?.[serviceId]?.[date],

  getOccupationForPeriod: (params, time1, time2) => {
    return (state.occupation[getKey(params)] ?? []).filter(
      x => x.startTime >= time1 && x.startTime < time2
    );
  },
};

export const actions = {
  initData({ locationId, userHash }) {
    state.locationId = locationId;
    http.setLocationId(locationId);
    if (userHash) {
      state.userHash = userHash;
      http.setUserHash(userHash);
    }
  },

  async fetchLocationInfo() {
    if (!state.location) {
      if (state.locationId) {
        state.location = await http.get(`${authUrl}/location/${state.locationId}`);
      }
      if (state.location) {
        dayjs.tz.setDefault(state.location.city.timezone);
      }
      // if (state.location) {
      //   const offers = await http.get(`${offerUrl}/public-offer`, {
      //     params: { locationId: state.locationId },
      //   });
      //   state.publicOffer = offers[0] ?? null;
      // }
    }
  },

  async fetchServicesCatalog() {
    if ((!state.categories.length || !state.services.length) && state.locationId) {
      const promises = state.userHash
        ? [http.get(`${otrsUrl}/servicing/category`), http.get(`${otrsUrl}/servicing/service`)]
        : [
            http.get(`${otrsUrl}/widget/servicing/category`),
            http.get(`${otrsUrl}/widget/servicing/service`),
          ];

      await Promise.all(promises).then(res => {
        state.categories = res[0];
        state.services = res[1];
      });
    }
  },

  async fetchServiceSlots({ serviceId, ...params }) {
    return http.get(`${otrsUrl}/servicing/service/${serviceId}/slot`, { params }).then(data => {
      let newSlots = data;
      if (serviceId in state.slotsByService) {
        newSlots = state.slotsByService[serviceId].filter(
          x => !data.find(item => item.slot === x.slot)
        );
        newSlots.push(...data);
      }

      newSlots.sort((a, b) => a.slot - b.slot);

      Vue.set(state.slotsByService, serviceId, newSlots);

      return state.slotsByService[serviceId];
    });
  },

  async fetchWorkDatesMonth({ serviceId, date }) {
    const calendar = state.calendar?.[serviceId] || {};

    const [yyyy, mm] = date.split('-');
    if (yyyy in calendar && mm in calendar[yyyy]) {
      return Promise.resolve();
    }

    const dt = dayjs.tz(date);
    const dateFrom = dt.startOf('month').format('YYYY-MM-DD');
    const dateTo = dt.endOf('month').format('YYYY-MM-DD');

    const params = { serviceId, dateFrom, dateTo };

    return http.get(`${otrsUrl}/servicing/service/${serviceId}/workdate`, { params }).then(data => {
      const calendar = { ...state.calendar?.[serviceId] };
      const dates = data.dates || [];

      dates.forEach(x => {
        const [y, m, d] = x.split('-');
        if (!(y in calendar)) {
          calendar[y] = {};
        }
        if (!(m in calendar[y])) {
          calendar[y][m] = {};
        }
        calendar[y][m][d] = '+';
      });

      const workDates = [...new Set([...(state.workDates?.[serviceId] || []), ...dates])];

      // Vue.set(state.timezone, serviceId, data.timezone);
      Vue.set(state.workDates, serviceId, workDates);
      Vue.set(state.calendar, serviceId, calendar);

      return true;
    });
  },

  async createReservation({ serviceId, ...data }) {
    return state.userHash
      ? http.post(`${otrsUrl}/servicing/service/${serviceId}/reservation`, data)
      : http.post(`${otrsUrl}/widget/service/${serviceId}/reservation`, data);
  },

  async handlePayment({ serviceTicket }) {
    if (serviceTicket.paymentType === PaymentType.ONLINE && !serviceTicket.service.freeOfCharge) {
      const payment = await http.post(
        `${otrsUrl}/servicing/token-access/service-ticket/${serviceTicket.token}/payment`,
        { source: 'widget' }
      );
      localStorage.setItem('serviceTicketToken', serviceTicket.token);
      window.location.href = payment.redirectUrl;
    } else {
      state.serviceTicket = serviceTicket;
      state.token = serviceTicket.token;
    }
  },

  async fetchServiceTicketResult() {
    try {
      let serviceTicket = state.serviceTicket;
      if (state.token) {
        serviceTicket = await http.get(
          `${otrsUrl}/servicing/token-access/service-ticket/${state.token}`
        );
      }

      state.serviceTicket = serviceTicket;
      state.selectedService = serviceTicket.service;
      state.selectedCategory = state.categories.find(
        x => x.id === serviceTicket.service.categoryId
      );
    } catch (e) {
      state.serviceTicket = null;
      state.token = null;
      localStorage.removeItem('serviceTicketToken');
    }
  },

  async fetchTokenPayment() {
    state.payment = await http.get(
      `${otrsUrl}/servicing/token-access/service-ticket/${state.token}/payment`
    );
  },

  async createTicket({ serviceId, ...data }) {
    let dynamicFields;
    if (data?.dynamicFields?.length > 0) {
      dynamicFields = [];
      for (let item of data.dynamicFields) {
        if (item.inputType === DynamicFieldType.UPLOAD) {
          const urls = await uploadFiles(item.value);
          const newValue = item.value.map((file, idx) => ({
            filename: file.name,
            contentSize: file.size,
            contentType: file.type,
            url: urls[idx],
          }));
          item = { ...item, value: newValue };
        }
        dynamicFields.push(item);
      }
    }

    if (state.userHash) {
      return http.post(`${otrsUrl}/servicing/service/${serviceId}/ticket`, {
        ...data,
        dynamicFields,
      });
    }

    return http.post(`${otrsUrl}/widget/servicing/service/${serviceId}/ticket`, {
      ...data,
      dynamicFields,
    });
  },

  async fetchOccupation({ periodStart, periodEnd, ...params }) {
    const data = await http.get(`${otrsUrl}/coworking/occupation/service/${params.serviceId}`, {
      params: { ...params, periodStart, periodEnd },
    });

    const prevData = state.occupation[getKey(params)] ?? [];

    const newData = prevData
      .filter(x => !data.find(y => y.startTime === x.startTime))
      .concat(data)
      .sort((a, b) => a.startTime - b.startTime);

    Vue.set(state.occupation, getKey(params), newData);
  },

  async createCoworking(data) {
    return http.post(`${otrsUrl}/widget/coworking/ticket`, data);
  },

  async createHotelOrder(data) {
    return http.post(`${otrsUrl}/widget/hotel/ticket`, data);
  },

  async fetchCbCategories() {
    state.cbCategories = await http.get(`${otrsUrl}/coffee-break/category`, {
      params: { useGrid: 0 },
    });
  },

  async fetchCbProducts({ serviceId }) {
    state.cbProducts = await http.get(`${otrsUrl}/servicing/service/${serviceId}/product`, {
      params: { useGrid: 0 },
    });
  },

  async fetchWorkTime({ serviceId, date }) {
    const workTime = state.workTime?.[serviceId]?.[date];
    if (workTime) {
      return Promise.resolve();
    }

    const data = await http.get(`${otrsUrl}/servicing/service/${serviceId}/worktime/${date}`);

    if (!state.workTime[serviceId]) {
      state.workTime[serviceId] = {};
    }
    state.workTime[serviceId][date] = data;
  },

  async createOrder(data) {
    return state.userHash
      ? http.post(`${otrsUrl}/coffee-break/order`, data)
      : http.post(`${otrsUrl}/widget/coffee-break/order`, data);
  },

  async goToCategory(categoryId) {
    state.selectedService = null;
    state.selectedCategory = state.categories.find(x => x.id === categoryId);
    state.serviceTicket = null;
    state.payment = null;
    state.token = null;
  },
};

let subscribed = false;
export const subscribeToUpdateServiceTicket = async () => {
  if (!state.token) {
    return;
  }
  if (!subscribed) {
    subscribed = true;
    localStorage.removeItem('serviceTicketToken');
    setTimeout(() => {
      updateServiceTicket();
    }, 2400);
  }
};

const updateServiceTicket = async () => {
  if (!subscribed) {
    return;
  }

  const serviceTicket = await http.get(
    `${otrsUrl}/servicing/token-access/service-ticket/${state.token}`
  );

  if (!serviceTicket) {
    return;
  }

  state.serviceTicket = serviceTicket;

  if (serviceTicket.paymentId && ['created', 'redirected'].includes(serviceTicket.paymentStatus)) {
    setTimeout(() => {
      updateServiceTicket();
    }, 3200);
  }

  if (serviceTicket.paymentStatus === 'purchased') {
    await actions.fetchTokenPayment();
  }
};

export const unsubscribeToUpdateServiceTicket = async () => {
  subscribed = false;
};
