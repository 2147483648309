<template>
  <v-sheet class="px-2 py-4 px-sm-10 py-sm-8">
    <NewsDisplay v-if="news" class="mb-8" :news="news" @click:tag="onClickTag" />
    <div class="mt-8">
      <v-btn color="t-grey" outlined @click="goBack">
        {{ $t('Назад') }}
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { actions, state } from '@/store/newsStore';
import NewsDisplay from '@/components/news/NewsDisplay.vue';

export default {
  name: 'NewsView',

  components: {
    NewsDisplay,
  },

  computed: {
    type() {
      return state.type;
    },
    news() {
      return state.currentNews;
    },
  },

  mounted() {
    if (state.currentNews) {
      actions.fetchCurrentNews(state.currentNews.id);
    }
  },

  methods: {
    goBack() {
      state.currentNews = null;
    },

    onClickTag(tag) {
      state.params = { tag };
      state.newsList = [];
      state.meta = {};
      state.currentNews = null;
    },
  },
};
</script>
