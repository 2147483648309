<template>
  <div>
    <div class="header text-h2">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
};
</script>

<style lang="scss" scoped>
.header {
  color: #17344d;
  padding-bottom: 4px;
}

.header:after {
  content: '';
  display: block;
  position: absolute;
  width: 88px;
  height: 4px;
  background: var(--v-primary-base);
}
</style>
