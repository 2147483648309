<template>
  <div id="tpw">
    <v-app v-if="locationId && userHash">
      <v-main>
        <OrderList v-if="!viewOrder" />
        <OrderView v-else />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/orders';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import OrderList from '@/views/orders/OrderList.vue';
import OrderView from '@/views/orders/OrderView.vue';

export default {
  name: 'OrdersWidget',

  components: { OrderView, OrderList },

  mixins: [commonPropsMixin, themeMixin],

  computed: {
    location() {
      return state.location;
    },
    viewOrder() {
      return state.viewOrder;
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
      userHash: this.userHash,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
  },
};
</script>
