<template>
  <div>
    <Wrapper class="pb-6">
      <ServiceTicketHeader :service-ticket="serviceTicket">
        <template v-if="$scopedSlots.title" #title>
          <slot name="title" />
        </template>

        <template v-if="$scopedSlots.subtitle" #subtitle>
          <slot name="subtitle" />
        </template>

        <template #menu>
          <slot name="menu" />
        </template>
      </ServiceTicketHeader>
    </Wrapper>

    <div class="mb-4">
      <OrderStatusBox>
        <ServiceTicketStatus :service-ticket="serviceTicket" user-mode>
          <!-- Необходимо оплатить... -->
        </ServiceTicketStatus>

        <v-spacer></v-spacer>

        <!-- Кнопка Оплатить -->

        <div v-if="serviceTicket.status === $stStatus.PENDING_CLOSE" class="d-flex gap-2">
          <ChangeStateButton :service-ticket="serviceTicket" :new-state="'in_work'">
            {{ $t('Вернуть в работу') }}
          </ChangeStateButton>
          <ChangeStateButton :service-ticket="serviceTicket">
            {{ $t('Подтвердить выполнение заказа') }}
          </ChangeStateButton>
        </div>

        <slot name="status-buttons-ext" />
      </OrderStatusBox>
      <OrderStatusBox
        v-if="
          serviceTicket.status === $stStatus.CANCELED &&
          serviceTicket.paymentStatus === $PaymentStatus.PURCHASED
        "
        class="mt-2"
      >
        {{ $t('Стоимость заказа будет возвращена...') }}
      </OrderStatusBox>
      <slot name="after-status" />
    </div>

    <div class="mb-8">
      <slot />
    </div>

    <OrderPanels :value="[0, 1, 2]">
      <slot name="panels">
        <OrderPanel v-if="hasDetails" :header="$t('Детали заказа')">
          <RequestTicketDetailsBlock :service-ticket="serviceTicket" />
        </OrderPanel>
        <!--<OrderPanel v-if="hasPayments && !serviceTicket.freeOfCharge" :header="$t('Оплата')">
          <PaymentBlock :service-ticket="serviceTicket" />
        </OrderPanel>-->
        <OrderPanel
          v-if="service && service.description"
          :header="$t('Описание и характеристики услуги')"
        >
          <EditorContent :content="service.description" />
        </OrderPanel>
      </slot>
    </OrderPanels>
  </div>
</template>

<script>
import { PaymentMethod, PaymentStatus } from '@/constants/payment';
import { ServiceType } from '@/constants/service';
import { serviceTicketMixin } from '@/views/home/orders/mixins/serviceTicketMixin';
import OrderPanel from '@/components/order/OrderPanel';
import EditorContent from '@/components/ui/EditorContent.vue';
import OrderStatusBox from '@/components/order/OrderStatusBox';
import ServiceTicketHeader from '@/components/order/ServiceTicketHeader.vue';
import OrderPanels from '@/components/order/OrderPanels';
import ServiceTicketStatus from '@/components/order/ServiceTicketStatus';
import Wrapper from '@/components/ui/Wrapper';
import ChangeStateButton from '@/views/home/orders/order/ChangeStateButton.vue';
import RequestTicketDetailsBlock from '@/components/order/RequestTicketDetailsBlock.vue';

export default {
  name: 'OrderBase',

  components: {
    RequestTicketDetailsBlock,
    ChangeStateButton,
    Wrapper,
    ServiceTicketStatus,
    OrderPanels,
    ServiceTicketHeader,
    OrderStatusBox,
    OrderPanel,
    EditorContent,
  },

  mixins: [serviceTicketMixin],

  data: () => ({
    processPayStatuses: [PaymentStatus.CREATED, PaymentStatus.REDIRECTED],
    processPayMethods: [PaymentMethod.ANONYMOUS, PaymentMethod.SAVED_CARD],
    timerProcessMethods: [PaymentMethod.ANONYMOUS, PaymentMethod.SAVED_CARD],
  }),

  computed: {
    hasDetails() {
      return (
        (this.serviceTicket.dynamicFields || []).length ||
        this.serviceTicket.comment ||
        this.serviceTicket.rentUnitId
      );
    },

    hasPayments() {
      return ![
        ServiceType.COMMERCIAL,
        ServiceType.CONTACTING_TPARK,
        ServiceType.RENT_OFFICE,
      ].includes(this.serviceTicket.service.type);
    },
  },

  // watch: {
  //   serviceTicket: {
  //     immediate: true,
  //     handler(serviceTicket) {
  //       if (serviceTicket && serviceTicket.paymentIds.length) {
  //         this.fetchPayments({ serviceTicketId: serviceTicket.id });
  //       }
  //     },
  //   },
  //
  //   'serviceTicket.payer': {
  //     immediate: true,
  //     handler(payer) {
  //       if (payer) {
  //         this.fetchPayers([payer]);
  //       }
  //     },
  //   },
  // },

  // methods: {
  //   goToPaymentForm() {
  //     localStorage.setItem(
  //       'returnLocation',
  //       JSON.stringify({ name: 'home.orders.view', params: { id: this.serviceTicket.id } })
  //     );
  //     window.location.href = this.payment.securePageURL ?? this.payment.redirectUrl;
  //   },
  //
  //   async refresh() {
  //     await this.refreshServiceTicket();
  //   },
  // },
};
</script>
