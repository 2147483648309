<template>
  <v-list two-line v-bind="$attrs" color="transparent">
    <v-list-item v-for="(file, idx) in files" :key="idx">
      <v-list-item-avatar class="my-1" :size="iconSize" tile>
        <v-img v-if="file.thumb" :src="file.thumb" contain />
        <AttachmentIcon v-else :filename="file.name" :content-type="file.type" :size="iconSize" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <slot name="name" v-bind="{ item: file, index: idx }">{{ file.name }}</slot>
        </v-list-item-title>
        <v-list-item-subtitle v-if="file.size">
          {{ file.size | formatSize }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action v-if="$listeners.remove">
        <v-btn icon :disabled="disabled" @click.stop="$emit('remove', file)">
          <v-icon color="grey lighten-1">{{ mdiClose }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <v-list-item v-for="(url, idx) in uploadedFiles" :key="idx">
      <v-list-item-avatar class="my-1" :size="iconSize" tile>
        <v-img :src="url" contain />
      </v-list-item-avatar>

      <v-list-item-content>
        <a class="text--primary" :href="url" target="_blank" download>
          {{ url.split('/').pop() }}
        </a>
      </v-list-item-content>

      <v-list-item-action v-if="$listeners['removeUploadedFile']">
        <v-btn icon :disabled="disabled" @click.stop="$emit('removeUploadedFile', url)">
          <v-icon color="grey lighten-1">{{ mdiClose }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mdiClose } from '@mdi/js';
import AttachmentIcon from '@/components/file/AttachmentIcon';

export default {
  name: 'FileList',

  components: { AttachmentIcon },

  props: {
    files: {
      type: Array,
      default: () => [],
    },
    uploadedFiles: {
      type: Array,
      default: () => [],
    },
    iconSize: {
      type: [String, Number],
      default: 64,
    },
    disabled: Boolean,
  },

  computed: {
    mdiClose() {
      return mdiClose;
    },
  },
};
</script>
