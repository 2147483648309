export const themeMixin = {
  props: {
    primaryColor: String,
    theme: String,
  },

  mounted() {
    this.initTheme();
  },

  methods: {
    initTheme() {
      if (this.primaryColor) {
        this.$vuetify.theme.themes.light.primary = this.primaryColor;
      }

      if (this.theme) {
        try {
          const theme = JSON.parse(this.theme);
          Object.keys(theme).forEach(key => {
            this.$vuetify.theme.themes.light[key] = theme[key];
          });
        } catch (e) {
          console.error('Invalid input for "theme"');
        }
      }

      // this.$vuetify.theme.applyTheme();
    },
  },
};

export const commonPropsMixin = {
  props: {
    locationId: {
      type: Number,
      required: true,
    },
    userHash: {
      type: String,
    },
  },
};
