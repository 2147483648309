<template>
  <OrderPositions>
    <v-divider />
    <OrderPosition
      :name="serviceTicket.service.name"
      :qty="`${count} x ${$n(price, 'currency')}`"
      :sum="$n(serviceTicket.totalPrice, 'currency')"
    />
    <v-divider />
  </OrderPositions>
</template>

<script>
import OrderPositions from '@/components/order/OrderPositions';
import OrderPosition from '@/components/order/OrderPosition';

export default {
  name: 'BookingOrderItems',

  components: {
    OrderPosition,
    OrderPositions,
  },

  props: {
    serviceTicket: {
      type: Object,
      required: true,
    },
  },

  computed: {
    count() {
      return this.serviceTicket.reservation.slots.length;
    },

    price() {
      return this.serviceTicket.reservation.totalAmount / this.count;
    },
  },
};
</script>
