<script>
import { DynamicFieldType } from '@/constants/dynamicFields';
import TextValue from './value/TextValue.vue';
import MultiselectValue from './value/MultiselectValue.vue';
import UploadValue from './value/UploadValue.vue';
import TextareaValue from './value/TextareaValue.vue';
import DateValue from './value/DateValue.vue';
import CheckboxValue from './value/CheckboxValue.vue';

export default {
  name: 'DynamicFieldValue',

  functional: true,

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  render(h, { props, data, children }) {
    const map = {
      [DynamicFieldType.CHECKBOX]: CheckboxValue,
      [DynamicFieldType.DATE]: DateValue,
      [DynamicFieldType.MULTISELECT]: MultiselectValue,
      [DynamicFieldType.TEXTAREA]: TextareaValue,
      [DynamicFieldType.UPLOAD]: UploadValue,
    };

    const Component = map[props.item.inputType] ?? TextValue;

    return h(Component, { ...data, props: { ...data.props, value: props.item.value } }, children);
  },
};
</script>
