import dayjs from 'dayjs';

const formats = {
  // 09.09.2020
  short: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  // 09.09.2020, 10:00
  long: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  // среда
  weekday: {
    weekday: 'long',
  },
  // 9 сентября
  dayAndMonth: {
    day: 'numeric',
    month: 'long',
  },
  // среда, 9 сентября 2020 г.
  dateLongWithWeekday: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    weekday: 'long',
  },
};

export default function (value, format = 'short') {
  if (!value && value !== 0) {
    return '';
  }

  const date = typeof value === 'number' ? dayjs.unix(value).tz() : dayjs.tz(value);

  let options = formats[format] || formats['short'];

  return new Intl.DateTimeFormat('ru', options).format(
    new Date(date.format('YYYY-MM-DDTHH:mm:ss')) // keep timezone
  );
}
