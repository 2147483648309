<template>
  <div id="tpw">
    <v-app v-if="showApp">
      <v-main>
        <EventRegistrationInfo v-if="showResult" />
        <EventView v-else-if="showEvent" />
        <LastEvents v-else-if="count" :url="url" />
        <EventList v-else />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/eventStore';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import EventList from '@/views/events/EventList.vue';
import EventView from '@/views/events/EventView.vue';
import EventRegistrationInfo from '@/views/events/EventRegistrationInfo.vue';
import LastEvents from '@/views/events/LastEvents.vue';

export default {
  name: 'EventsWidget',

  components: {
    LastEvents,
    EventRegistrationInfo,
    EventView,
    EventList,
  },

  mixins: [commonPropsMixin, themeMixin],

  props: {
    eventId: [Number, String],
    count: [Number, String],
    url: String,
  },

  computed: {
    location() {
      return state.location;
    },
    showApp() {
      if (this.count && !this.eventId) {
        return state.location && state.events.length > 0;
      }

      return !!state.location;
    },
    showResult() {
      return state.token || state.serviceTicket;
    },
    showEvent() {
      return state.type === 'event-view' || state.currentEvent;
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
      userHash: this.userHash,
      eventId: this.eventId,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
    if (this.count && !this.eventId) {
      actions.fetchEvents({ pageSize: this.count });
    }
  },
};
</script>
