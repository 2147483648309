<template>
  <div>
    <PageHeader :title="service.name" :subtitle="service.title" />

    <v-row>
      <v-col cols="12" md="8">
        <BlockTitle class="mb-5">Описание и характеристики</BlockTitle>
        <EditorContent v-if="service.description" :content="service.description" class="mb-10" />
        <div class="mt-6">
          <v-btn color="t-grey" :disabled="saving" outlined @click="$emit('back')">
            {{ 'Назад' }}
          </v-btn>
          <v-btn v-if="service.addButton" class="ml-2" color="primary" @click="dialog = true">
            {{ service.buttonName }}
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" md="4">
        <div v-for="(img, i) in service.images" :key="i">
          <v-card @click="openGallery(i)">
            <v-img :src="$crop(img, '390x260')" max-height="252" />
          </v-card>
          <div class="py-4" />
        </div>
      </v-col>
      <PhotoViewer :images="showImages" :show-idx="showIdx" />
    </v-row>

    <v-dialog v-model="dialog" :persistent="saving" max-width="932">
      <v-card>
        <v-card-title>
          <SectionHeader>{{ 'Заявка на услугу' }}</SectionHeader>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" :disabled="saving">
            <v-row v-if="!userHash">
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || 'Укажите ФИО']"
                  label="Фамилия Имя Отчество"
                  name="name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <PhoneField v-model="form.phone" type="phone" label="Телефон" name="phone" />
              </v-col>
              <v-col cols="12" md="6">
                <EmailField
                  v-model="form.email"
                  type="email"
                  label="Электронная почта"
                  name="email"
                  required
                />
              </v-col>
            </v-row>
            <DynamicForm v-model="form.dynamicFields" :dynamic-fields="service.dynamicFields" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :loading="saving" @click.prevent="onSubmit">
            {{ 'Отправить' }}
          </v-btn>
          <AcceptTerms class="pl-4" :service="service" />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar">
      Заявка создана. В ближайшее время с вами свяжется наш менеджер.
    </v-snackbar>
  </div>
</template>

<script>
import { actions, state } from '@/store/store';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import EditorContent from '@/components/ui/EditorContent.vue';
import PhoneField from '@/components/ui/PhoneField.vue';
import EmailField from '@/components/ui/EmailField.vue';
import SectionHeader from '@/components/ui/SectionHeader.vue';
import PhotoViewer from '@/components/gallery/PhotoViewer.vue';
import PageHeader from '@/components/ui/PageHeader.vue';
import AcceptTerms from '@/components/terms/AcceptTerms.vue';
import DynamicForm from '@/components/dynamic-fields/DynamicForm.vue';

const initForm = () => ({
  name: undefined,
  phone: undefined,
  email: undefined,
  dynamicFields: null,
});

export default {
  name: 'SimpleServiceView',

  components: {
    DynamicForm,
    AcceptTerms,
    PageHeader,
    PhotoViewer,
    SectionHeader,
    EmailField,
    PhoneField,
    EditorContent,
    BlockTitle,
  },

  data: () => ({
    dialog: false,
    saving: false,
    snackbar: false,
    form: initForm(),
    showImages: null,
    showIdx: null,
  }),

  computed: {
    service() {
      return state.selectedService;
    },

    userHash() {
      return state.userHash;
    },
  },

  methods: {
    openGallery(idx) {
      this.showIdx = idx;
      this.showImages = [...this.service.images];
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      try {
        const payload = { ...this.form, source: 'widget', serviceId: this.service.id };
        await actions.createTicket(payload);
        this.dialog = false;
        this.snackbar = true;
        this.form = initForm();
        this.$refs.form.resetValidation();
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
