<template>
  <v-pagination class="paging" :total-visible="7" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: 'Pagination',
};
</script>

<style lang="scss">
.paging .v-pagination__navigation,
.paging .v-pagination__item {
  box-shadow: none;
  font-size: 0.875rem;
}

.paging .v-pagination__item {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
}

.paging .theme--light.v-pagination .v-pagination__navigation,
.paging .theme--light.v-pagination .v-pagination__item {
  color: var(--v-t-grey-base);
}

.paging .theme--light.v-pagination .v-pagination__item--active {
  background: #e0e0e0 !important;
  border: none;
}
</style>
