import { stStatus } from '@/constants/stStatus';
import { PaymentStatus } from '@/constants/payment';
import { ServiceType } from '@/constants/service';

export default {
  computed: {
    $ServiceType() {
      return ServiceType;
    },
    $stStatus() {
      return stStatus;
    },
    $PaymentStatus() {
      return PaymentStatus;
    },
    // $PaymentType() {
    //   return PaymentType;
    // },
    // $PaymentMethod() {
    //   return PaymentMethod;
    // },
  },
};
