<template>
  <g>
    <path
      d="M16 11H18V13H16V11ZM12 3H19C20.11 3 21 3.89 21 5V19H22V21H2V19H10V5C10 3.89 10.89 3 12 3ZM12 5V19H19V5H12Z"
    />
    <path d="M5 11V8L9 12L5 16V13H2V11H5Z" />
  </g>
</template>

<script>
export default {
  name: 'IconDoorIn',
};
</script>
