import { stStatus } from '@/constants/stStatus';
import { state } from '@/store/orders';

export const serviceTicketMixin = {
  computed: {
    serviceTicketId() {
      return state.viewOrder?.id;
    },

    serviceTicket() {
      return state.viewOrder;
    },

    ticket() {
      return this.serviceTicket?.ticket;
    },

    service() {
      return this.serviceTicket?.service;
    },

    isClosed() {
      return (
        this.serviceTicket?.status === stStatus.CLOSED ||
        this.serviceTicket?.status === stStatus.CANCELED
      );
    },
  },

  methods: {
    refreshServiceTicket() {
      this.fetchItem({ id: this.serviceTicketId });
    },
  },
};
