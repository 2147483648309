<template>
  <v-card elevation="8" rounded="lg" height="100%" v-bind="$attrs" v-on="$listeners">
    <v-img :src="$crop(image, '900x600')" :aspect-ratio="3 / 2" contain />
    <v-card-title class="pb-2 t-dark--text">
      {{ unit.name }}
    </v-card-title>
    <v-card-text v-if="unit.area" class="text-body-1">
      {{ $t('Площадь') }} - {{ unit.area }}&nbsp;м²
    </v-card-text>
  </v-card>
</template>

<script>
import { state } from '@/store/plan';

export default {
  name: 'TerritoryCard',

  props: {
    unit: {
      type: Object,
      required: true,
    },
  },

  computed: {
    image() {
      return this.unit.images?.[0] ?? state.location.companyLogo;
    },
  },
};
</script>
