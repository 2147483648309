<template>
  <div id="tpw">
    <v-app v-if="location">
      <v-main>
        <EventRegistrationInfo v-if="showResult" />
        <EventView v-else-if="showEvent" />
        <EventList v-else />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { actions, state } from '@/store/eventStore';
import { commonPropsMixin, themeMixin } from '@/mixins/widget';
import EventList from '@/views/events/EventList.vue';
import EventView from '@/views/events/EventView.vue';
import EventRegistrationInfo from '@/views/events/EventRegistrationInfo.vue';

export default {
  name: 'EventsWidget',

  components: { EventRegistrationInfo, EventView, EventList },

  mixins: [commonPropsMixin, themeMixin],

  props: {
    eventId: [Number, String],
  },

  computed: {
    location() {
      return state.location;
    },
    showResult() {
      return state.token || state.serviceTicket;
    },
    showEvent() {
      return state.type === 'event-view' || state.currentEvent;
    },
  },

  created() {
    actions.initData({
      locationId: this.locationId,
      userHash: this.userHash,
      eventId: this.eventId,
    });
  },

  mounted() {
    actions.fetchLocationInfo();
  },
};
</script>
