<template>
  <Card min-width="160">
    <v-card-text class="px-6 py-4">
      <div class="mb-2 text--secondary text-body-2">{{ label }}</div>
      <div class="font-weight-medium"><slot /></div>
    </v-card-text>
  </Card>
</template>

<script>
import Card from '@/components/ui/Card.vue';

export default {
  name: 'UnitParamCard',
  components: { Card },
  props: {
    label: String,
  },
};
</script>
