<template>
  <div class="editor-content d-flex flex-column" v-html="content" />
</template>

<script>
export default {
  name: 'EditorContent',
  props: {
    content: String,
  },
};
</script>

<style lang="scss">
.editor-content,
.ck-content {
  & > p:last-child {
    margin-bottom: 0;
  }

  .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol {
    margin-bottom: 1em;
  }
}
</style>
