<template>
  <v-dialog :persistent="isLoading" :width="width" v-bind="$attrs" v-on="$listeners">
    <template #activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }"></slot>
    </template>

    <v-card>
      <v-card-title>
        <slot name="title">{{ title }}</slot>
      </v-card-title>
      <v-card-text v-if="$slots.default">
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <TextBtn color="t-grey" :disabled="isLoading" @click="$emit('input', false)">
          {{ $t('Нет') }}
        </TextBtn>
        <TextBtn :loading="isLoading" @click="onSubmit">
          {{ $t('Да') }}
        </TextBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TextBtn from '@/components/ui/TextBtn';

export default {
  name: 'ConfirmDialog',

  components: { TextBtn },

  props: {
    title: String,
    submit: Function,
    loading: Boolean,
    width: {
      type: [Number, String],
      default: 400,
    },
  },

  data: () => ({
    isLoading: false,
  }),

  watch: {
    loading(val) {
      this.isLoading = val;
    },
  },

  methods: {
    onSubmit() {
      if (this.submit) {
        this.isLoading = true;
        Promise.resolve(this.submit()).finally(() => (this.isLoading = false));
      } else {
        this.$emit('confirm');
      }
    },
  },
};
</script>
