import { STStatus, closedStatuses } from '@/constants/STStatus';
import { PaymentStatus } from '@/constants/payment';

export const getStatusColor = item => {
  const map = {
    [STStatus.NEW]: 't-attention',
    [STStatus.CONFIRMED]: 'success',
    [STStatus.ACTIVE]: 'success',
    [STStatus.IN_WORK]: 'success',
    [STStatus.PACKED]: 'primary',
    [STStatus.DELIVERY]: 'success',
    [STStatus.CLOSED]: 't-disabled',
    [STStatus.PROCESSING_REQUIRED]: 't-attention',
    [STStatus.CANCELED]: 't-disabled',
  };

  let color = map[item.status] ?? 't-disabled';
  if (item.status === STStatus.CANCELED && item.paymentStatus === PaymentStatus.PURCHASED) {
    color = 't-attention';
  }
  if (item.status === STStatus.CANCELED && item.paymentStatus === PaymentStatus.REFUNDED) {
    color = 't-disabled';
  }

  return color;
};

export const getReservationIntervals = ({ slots, timeSlotDuration }) => {
  const items = [
    // {start, end, slots}
  ];

  let prevSLot = null;
  let item = null;
  for (let slot of slots) {
    if (prevSLot && prevSLot + timeSlotDuration === slot) {
      item.slots.push(slot);
      item.end = slot + timeSlotDuration;
    } else {
      item = { start: slot, end: slot + timeSlotDuration, slots: [slot] };
      items.push(item);
    }
    prevSLot = slot;
  }

  return items;
};

export const isExpired = (st, time) => {
  if (closedStatuses.includes(st.status)) {
    return false;
  }

  if (st.order && (st.readyTime || st.confirmedAt)) {
    return time > (st.readyTime || st.confirmedAt) + 15 * 60;
  }

  if (st.incomingOrder && st.status === STStatus.PACKED && st.packedAt) {
    return time > st.packedAt + 15 * 60;
  }

  return false;
};
