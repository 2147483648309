<script>
/**
 * Преобразует сноски в ссылки.
 *
 * "Для оплаты перейдите по [1]ссылке, а потом ещё [2]сюда.
 *
 * [1] http://example.com/1
 * [2] http://example.com/2"
 *
 */
export default {
  name: 'ArticleBody',

  functional: true,

  props: {
    tag: {
      type: String,
      default: 'div',
    },
    body: {
      type: String,
      required: true,
    },
  },

  render(createElement, { props }) {
    let body = props.body || '';

    const refsRegex = /(\[\d+\]) (http.+)/g;
    const refs = body.match(refsRegex);

    const nl2br = text =>
      text.split('\n').reduce((acc, str) => {
        if (!Array.isArray(acc)) {
          return [acc, createElement('br'), str];
        }
        return acc.concat([createElement('br'), str]);
      });

    if (!refs) {
      return createElement(props.tag, { class: props.className }, nl2br(body));
    }

    // { '[1]' => 'http://foo.bar' }
    const refMap = {};
    for (const ref of refs) {
      const [num, link] = ref.split(' ', 2);
      refMap[num] = link;
    }

    const children = [];
    body = body.replace(refsRegex, '').trim();
    const links = body.match(/(\[\d+\])([\wа-я])+/gi);

    let chunk = body;
    for (const link of links) {
      const split = chunk.split(link, 2);

      const num = link.match(/\[\d+\]/)[0];
      const linkText = link.replace(num, '');
      const el = createElement('a', { attrs: { href: refMap[num], target: '_blank' } }, linkText);

      children.push(...nl2br(split[0]));
      children.push(el);
      chunk = split[1];
    }

    children.push(...nl2br(chunk));

    return createElement(props.tag, { class: props.className }, children);
  },
};
</script>
