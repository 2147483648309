import { VBtn } from 'vuetify/lib';

export default {
  name: 'BtnPrimary',
  functional: true,
  render(h, context) {
    return h(
      VBtn,
      {
        ...context.data,
        attrs: {
          color: 'primary',
          ...context.data.attrs,
        },
      },
      context.children
    );
  },
};
