<template>
  <span>
    <template v-if="readyTime">
      <template v-if="!short">
        {{ readyTime | date('dateLong') }}, {{ $t('в') }}{{ '\xa0' }}{{ readyTime | time }}
      </template>
      <template v-else>
        {{ readyTime | date('long') }}
      </template>
    </template>
    <template v-else>
      {{ $t('По готовности') }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'ReadyTime',
  props: {
    readyTime: Number,
    short: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
