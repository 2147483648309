import Vue from 'vue';
import dayjs from 'dayjs';
import http from '@/api/http';
import { uploadFiles } from '@/api/storage';
import { DynamicFieldType } from '@/constants/dynamicFields';

const host = process.env.VUE_APP_URL.replace(/\/+$/g, '');
const authUrl = host + '/' + process.env.VUE_APP_API_AUTH.replace(/^\/+|\/+$/g, '');
const locationUrl = host + '/' + process.env.VUE_APP_API_LOCATION.replace(/^\/+|\/+$/g, '');
// const offerUrl = host + '/' + process.env.VUE_APP_API_OFFER.replace(/^\/+|\/+$/g, '');
const otrsUrl = host + '/' + process.env.VUE_APP_API_OTRS.replace(/^\/+|\/+$/g, '');

const initialState = {
  locationId: null,
  location: null,
  rentService: null,
  structList: [],
  schemeList: [],
  selectedUnitId: null,
};

export const state = Vue.observable(initialState);

export const getters = {
  getSelectedUnit() {
    return state.structList.find(x => x.id === state.selectedUnitId);
  },

  getChildrenUnits() {
    return state.structList.filter(x => (x.parentIds || []).includes(state.selectedUnitId));
  },

  getParentUnits() {
    const items = [];
    let itemId = state.selectedUnitId;
    while (itemId) {
      const found = state.structList.find(x => x.id === itemId);
      if (found && itemId !== state.selectedUnitId) {
        items.push(found);
      }
      itemId = found?.parentIds?.[0];
    }

    return items;
  },
};

export const actions = {
  async setLocationId(locationId) {
    http.setLocationId(locationId);
    state.locationId = locationId;
  },

  async fetchLocationData() {
    state.location = await http.get(`${authUrl}/location/${state.locationId}`);
    if (state.location) {
      dayjs.tz.setDefault(state.location.city.timezone);
    }
    if (state.location?.rentServiceId) {
      state.rentService = await http.get(
        `${otrsUrl}/widget/servicing/service/${state.location.rentServiceId}`
      );
    }
  },

  async fetchLocationStructure() {
    let [structList, schemeList] = await Promise.all([
      http.get(`${locationUrl}/widget/location/${state.locationId}/list`),
      http.get(`${locationUrl}/widget/floor-scheme`, {
        params: { locationId: state.locationId, useGrid: 0 },
      }),
    ]);

    structList = structList.filter(x => x.showOnSite); // mb back filter

    schemeList.forEach(scheme => {
      scheme.unit = structList.find(x => x.id === scheme.unitId);
      scheme.content.forEach(item => {
        item.unit = structList.find(x => x.id === item.unitId);
      });
      scheme.content = scheme.content.filter(x => x.unit);
    });
    schemeList = schemeList.filter(x => x.unit);

    state.structList = structList;
    state.schemeList = schemeList;
  },

  async createTicket({ serviceId, ...data }) {
    let dynamicFields;
    if (data?.dynamicFields?.length > 0) {
      dynamicFields = [];
      for (let item of data.dynamicFields) {
        if (item.inputType === DynamicFieldType.UPLOAD) {
          const urls = await uploadFiles(item.value);
          const newValue = item.value.map((file, idx) => ({
            filename: file.name,
            contentSize: file.size,
            contentType: file.type,
            url: urls[idx],
          }));
          item = { ...item, value: newValue };
        }
        dynamicFields.push(item);
      }
    }

    return http.post(`${otrsUrl}/widget/servicing/service/${serviceId}/ticket`, {
      ...data,
      dynamicFields,
    });
  },
};
