import { render, staticRenderFns } from "./NewsWidget.vue?vue&type=template&id=264064d9"
import script from "./NewsWidget.vue?vue&type=script&lang=js"
export * from "./NewsWidget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports