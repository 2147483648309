<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div class="d-flex">
          <SlotSelect
            v-model="form.tariffId"
            :items="foundTariffs"
            :label="$t('Выберите номер')"
            height="42"
            mandatory
            hide-details
            :rules="[$rules.required()]"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="currentModifiers && currentModifiers.length > 0" dense>
      <v-col cols="12">
        <div class="mb-2">{{ $t('Дополнительные услуги') }}</div>
        <div class="d-flex flex-column gap-3">
          <div v-for="modifier in currentModifiers" :key="modifier.id">
            <v-checkbox
              v-model="form.tariffModifierIds"
              :value="modifier.id"
              :disabled="modifier.enabledByDefault"
              hide-details
            >
              <template #label>
                <ModifierLabel :modifier="modifier" :price-unit="selectedTariff.priceUnit" />
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="isSelectEarlyCheckin || isSelectLateCheckout" dense>
      <v-col cols="12">
        <div class="mb-2">{{ $t('Предполагаемое время') }}</div>
        <v-row dense>
          <v-col v-if="isSelectEarlyCheckin" cols="4">
            <v-select
              v-model="form.expectedCheckin"
              :label="$t('Ранний заезд')"
              :items="earlyCheckinItems"
            ></v-select>
          </v-col>
          <v-col v-if="isSelectLateCheckout" cols="4">
            <v-select
              v-model="form.expectedCheckout"
              :label="$t('Поздний выезд')"
              :items="lateCheckoutItems"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formProxyMixin } from '@/mixins/forms';
import { ModifierAffect, PriceUnit } from '@/constants/serviceTariff';
import SlotSelect from '@/components/ui/SlotSelect';
import ModifierLabel from '@/components/tariff/ModifierLabel.vue';

export default {
  name: 'HotelCommonForm',

  components: {
    ModifierLabel,
    SlotSelect,
  },

  mixins: [formProxyMixin],

  props: {
    service: {
      required: true,
      type: Object,
    },
    searchParams: Object,
    searchResult: Array,
  },

  data() {
    return {
      PriceUnit: PriceUnit,
      ModifierAffect: ModifierAffect,
    };
  },

  computed: {
    foundTariffs() {
      const items = [];
      if (this.searchResult) {
        this.searchResult.forEach(variant => {
          items.push(...variant.availableTariffs.map(x => ({ ...x, name: variant.name })));
        });
      }
      return items;
    },

    selectedTariff() {
      return this.foundTariffs.find(x => x.id === this.form.tariffId);
    },

    selectedVariant() {
      return this.service.variants.find(x => x.tariffs.find(x => x.id === this.form.tariffId));
    },

    currentModifiers() {
      return (this.selectedTariff?.modifiers ?? []).filter(x => x.status === 'active');
    },

    selectedModifiers() {
      return this.currentModifiers.filter(x => this.form.tariffModifierIds.includes(x.id));
    },

    isSelectEarlyCheckin() {
      return this.selectedModifiers.some(x => x.type === 'hotel_early_checkin');
    },

    isSelectLateCheckout() {
      return this.selectedModifiers.some(x => x.type === 'hotel_late_checkout');
    },

    earlyCheckinItems() {
      const h = parseInt(this.service.checkoutTime.split(':')[0]);
      const items = [{ text: this.$t('Неизвестно'), value: '' }];
      for (let i = h; i >= 0; i--) {
        const time = String(i).padStart(2, '0') + ':00';
        items.push({ text: time, value: time });
      }
      return items;
    },

    lateCheckoutItems() {
      const h = parseInt(this.service.checkoutTime.split(':')[0]);
      const items = [{ text: this.$t('Неизвестно'), value: '' }];
      for (let i = h; i < 24; i++) {
        const time = String(i).padStart(2, '0') + ':00';
        items.push({ text: time, value: time });
      }
      return items;
    },

    totalPrice() {
      if (!this.selectedTariff) {
        return null;
      }

      const nights = this.searchParams.nights;
      const guests = this.searchParams.adultsNumber + this.searchParams.childsNumber;

      const tariffCost = this.selectedTariff.price * nights;

      let affectedTariff =
        this.selectedModifiers
          .filter(x => x.affect === ModifierAffect.TARIFF_PRICE)
          .map(x => x.price * (x.dependsOnGuests ? guests : 1))
          .reduce((a, b) => a + b, 0) * nights;

      let affectedTotal = this.selectedModifiers
        .filter(x => x.affect === ModifierAffect.TOTAL_COST)
        .map(x => x.price * (x.dependsOnGuests ? guests : 1))
        .reduce((a, b) => a + b, 0);

      return tariffCost + affectedTariff + affectedTotal;
    },
  },

  watch: {
    currentModifiers: {
      immediate: true,
      handler() {
        this.form.tariffModifierIds = this.currentModifiers
          .filter(x => x.enabledByDefault)
          .map(x => x.id);

        if (this.searchParams?.earlyCheckin) {
          const modifier = this.currentModifiers.find(x => x.type === 'hotel_early_checkin');
          if (modifier && !this.form.tariffModifierIds.includes(modifier.id)) {
            this.form.tariffModifierIds.push(modifier.id);
          }
        }

        if (this.searchParams?.lateCheckout) {
          const modifier = this.currentModifiers.find(x => x.type === 'hotel_late_checkout');
          if (modifier && !this.form.tariffModifierIds.includes(modifier.id)) {
            this.form.tariffModifierIds.push(modifier.id);
          }
        }
      },
    },

    isSelectEarlyCheckin: {
      immediate: true,
      handler(val) {
        this.form.expectedCheckin = val ? this.service.checkoutTime : null;
      },
    },

    isSelectLateCheckout: {
      immediate: true,
      handler(val) {
        this.form.expectedCheckout = val ? this.service.checkoutTime : null;
      },
    },
  },
};
</script>
