<template>
  <div class="d-flex align-center justify-space-between text-caption t-grey--text">
    <div class="d-flex align-center">
      {{ news.publishedFrom | date('long') }}
    </div>

    <ItemAction
      :value="liked"
      :icon-on="mdiHeart"
      :icon-off="mdiHeartOutline"
      :count="news.likeCount"
      @click="$emit('click:like', news.id)"
    />
  </div>
</template>

<script>
import { mdiHeart, mdiHeartOutline } from '@mdi/js';
import ItemAction from '@/components/news/ItemAction';

export default {
  name: 'ItemInfo',

  components: { ItemAction },

  props: {
    news: { type: Object, required: true },
    liked: Boolean,
  },

  computed: {
    mdiHeart() {
      return mdiHeart;
    },
    mdiHeartOutline() {
      return mdiHeartOutline;
    },
  },
};
</script>
