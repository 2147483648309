<template>
  <div>
    <div class="mb-4 text-h1">{{ $t('Новости') }}</div>

    <div class="mt-4">
      <v-row class="ev-row">
        <v-col v-for="item in items" :key="item.id" cols="12" md="6" lg="4" xl="3">
          <NewsCard :news="item" @click="onClick(item)" />
        </v-col>
      </v-row>
    </div>

    <div v-if="url" class="mt-4">
      <a :href="url">{{ $t('Посмотреть все новости') }} →</a>
    </div>
  </div>
</template>

<script>
import { state } from '@/store/newsStore';
import NewsCard from '@/components/news/NewsCard.vue';

export default {
  name: 'LastNews',

  components: {
    NewsCard,
  },

  props: {
    url: String,
  },

  computed: {
    items() {
      return state.newsList;
    },
  },

  methods: {
    onClick(item) {
      state.currentNews = item;
    },
  },
};
</script>
