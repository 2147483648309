<template>
  <div class="message d-flex" :class="{ 'justify-end': fromCustomer }">
    <div v-if="fromAgent" class="align-self-end">
      <v-avatar size="32">
        <template v-if="item.user">
          <UserImg :src="item.user.photoUrl" alt="Avatar" size="32" />
        </template>
        <template v-else>
          <UserImg alt="Avatar" />
        </template>
      </v-avatar>
    </div>

    <v-card
      elevation="0"
      rounded="lg"
      class="body"
      :class="{
        'body-private': !item.visibleForCustomer,
        'from-agent': fromAgent,
        'from-customer': fromCustomer,
      }"
      :color="fromCustomer ? 't-menu-bg' : ''"
    >
      <v-card-text class="px-4 py-3 pb-2 text-body-2 text--primary">
        <div v-if="fromAgent" class="mb-2 t-grey--text font-weight-bold">
          <template v-if="item.userId">
            {{ item.user ? item.user.fullName : $t('Администратор') }}
          </template>
          <template v-else>
            {{ $t('Система') }}
          </template>
        </div>

        <div v-if="item.attachments.length > 0" class="mb-2">
          <AttachmentsView
            :article-id="item.id"
            :attachments="item.attachments"
            @click-image="$emit('click-image', $event)"
          />
        </div>

        <ArticleBody :body="item.body" />

        <div class="mt-1 text-caption text--secondary text-right t-grey--text">
          {{ item.createdAt | time }}
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AttachmentsView from '@/components/common/chat/AttachmentsView';
import ArticleBody from '@/components/common/chat/ArticleBody';
import UserImg from '@/components/user/UserImg';

export default {
  name: 'ChatItem',

  components: {
    UserImg,
    ArticleBody,
    AttachmentsView,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    agentSide: Boolean,
  },

  computed: {
    fromAgent() {
      return this.item.senderType === 'agent' || this.item.senderType === 'system';
    },

    fromCustomer() {
      return this.item.senderType === 'customer';
    },
  },
};
</script>

<style scoped lang="scss">
.message {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.from-agent {
  margin-left: 8px;

  &::before {
    position: absolute;
    content: '';
    border: 8px solid transparent;
    border-bottom-color: white;
    bottom: 0;
    left: -8px;
  }

  //&::after {
  //  position: absolute;
  //  content: '';
  //  border: 8px solid transparent;
  //  border-bottom-color: $back-light;
  //  border-right-color: $back-light;
  //  border-bottom-right-radius: 50%;
  //  bottom: 0;
  //  left: -16px;
  //}
}

.from-customer {
  margin-right: 8px;

  &::before {
    position: absolute;
    content: '';
    border: 8px solid transparent;
    border-bottom-color: $menu-bg;
    bottom: 0;
    right: -8px;
  }
}

.body-private {
  background-color: $warning !important;

  &::before {
    border-bottom-color: $warning !important;
  }
}
</style>
