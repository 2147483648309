<template>
  <div v-show="false">
    <file-upload
      ref="upload"
      v-model="files"
      :input-id="inputId"
      :name="inputId"
      :accept="accept"
      :multiple="multiple"
      :directory="directory"
      :extensions="extensions"
      :size="size || 0"
      :maximum="maxFiles"
      :drop="drop && !disabled"
      :drop-directory="dropDirectory"
      :add-index="addIndex"
      @input-filter="inputFilter"
      @input-file="inputFile"
    />
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component';

export default {
  name: 'FileInput',

  components: { FileUpload },

  props: {
    id: String,
    value: Array,
    disabled: Boolean,
    accept: String,
    maxFileSize: {
      type: Number,
      default: 50 * 1024 * 1024,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    extensions: String,
    maxFiles: {
      type: Number,
      default: 5,
    },
  },

  data: () => ({
    files: [],
    minSize: 0,
    size: 1024 * 1024 * 10,
    directory: false,
    drop: true,
    dropDirectory: false,
    addIndex: false,
  }),

  computed: {
    inputId() {
      return this.id || 'file';
    },
  },

  watch: {
    value(val) {
      if (!val || val.length === 0) {
        this.$refs.upload.clear();
      }
    },
  },

  methods: {
    add(...args) {
      this.$refs.upload.add(...args);
    },

    clear() {
      this.$refs.upload.clear();
    },

    remove(file) {
      this.$refs.upload.remove(file);
    },

    replace(...args) {
      this.$refs.upload.replace(...args);
    },

    update(...args) {
      this.$refs.upload.update(...args);
    },

    click() {
      this.$refs.upload.$el.querySelector('input').click();
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        if (/\.(php?|html?|js?)$/i.test(newFile.name)) {
          return prevent();
        }

        if (newFile.size > this.maxFileSize) {
          return prevent();
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }

        // Thumbnails
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
    },

    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.$emit('add', newFile);
      }
      if (!newFile && oldFile) {
        this.$emit('remove', oldFile);
      }

      if (newFile && oldFile) {
        if (newFile.active && !oldFile.active) {
          if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
            this.$refs.upload.update(newFile, { error: 'size' });
          }
        }
      }

      this.$emit('input', this.files);
    },
  },
};
</script>
