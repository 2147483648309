import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { VBtn, VTextarea, VTextField } from 'vuetify/lib';
import ru from 'vuetify/lib/locale/ru';

import '@/styles/main.scss';

Vuetify.config.silent = process.env.VUE_APP_ENV !== 'local';

VBtn.options.props.depressed.default = true;
VTextField.options.props.color = { type: String, default: 'secondary' };
VTextarea.extendOptions.props.autoGrow = { type: Boolean, default: true };
VTextarea.extendOptions.props.rows = {
  ...VTextarea.extendOptions.props.rows,
  default: 1,
};

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    dark: false,
    disable: false,
    options: {
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        primary: '#D8001A',
        secondary: '#353439',
        't-dark': '#353439',
        't-grey': '#6F7882',
        't-back-light': '#F7F9FC',
        't-menu-bg': '#E5EBF4',
        't-additional': '#7694B8',
        't-positive': '#00B040',
        't-attention': '#FF6B00',
        't-disabled': '#E1E3E6',
      },
      dark: process.env.NODE_ENV !== 'production',
    },
  },
  lang: {
    defaultLocale: 'ru',
    current: 'ru',
    locales: {
      ru: {
        ...ru,
        dataIterator: {
          noResultsText: 'Не найдено подходящих записей',
          loadingText: 'Загружаем...',
        },
        noDataText: 'Данные отсутствуют',
      },
    },
  },
});
