<template>
  <div>
    <div v-for="(item, idx) of value" :key="idx" :class="{ 'mb-2': idx < value.length - 1 }">
      <a
        :href="item.url"
        :download="item.filename"
        :title="item.filename"
        class="text-decoration-none"
        target="_blank"
        @click="onClick($event, item)"
      >
        {{ item.filename }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadValue',

  props: {
    value: Array,
  },

  methods: {
    showGallery() {},

    onClick(e, item) {
      if (!item.contentType.startsWith('image')) {
        return;
      }

      e.preventDefault();

      const images = this.value.filter(x => x.contentType.startsWith('image'));
      const selected = images.indexOf(item);
      const urls = images.map(x => x.url);
      this.showGallery({ images: urls, selected });
    },
  },
};
</script>
