<template>
  <div>
    <div class="mb-2 font-weight-bold">{{ label }}</div>
    <span class="mr-2 text-h4 primary--text">{{ $n(totalPrice, 'currency') }}</span>
    <span v-if="positions !== null" class="t-grey--text">
      {{ 'за' }} {{ positions }} {{ positions | plural('позицию', 'позиции', 'позиций') }}
    </span>
    <v-input :value="totalPrice" :rules="rules" :hide-details="!rules || rules.length === 0" />
  </div>
</template>

<script>
export default {
  name: 'TotalPrice',
  props: {
    label: {
      type: String,
      default() {
        return this.$t('Стоимость заказа');
      },
    },
    rules: Array,
    positions: {
      type: Number,
      default: null,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
  },
};
</script>
