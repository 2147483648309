export const PaymentStatus = {
  CREATED: 'created',
  PURCHASED: 'purchased',
  REDIRECTED: 'redirected',
  ERROR: 'error',
  FAILED: 'failed',
  POSTPAY: 'postpay',
  REFUNDED: 'refunded',
};

export const PaymentMethod = {
  ANONYMOUS: 'anonymous',
  SAVED_CARD: 'saved_card',
  GOOGLE_PAY: 'google_pay',
  APPLE_PAY: 'apple_pay',
};

export const PaymentType = {
  INVOICE_OFFER: 'invoice_offer',
  ONLINE: 'online',
  POSTPAY: 'postpay',
  TERMINAL: 'terminal',
  WIRE_PAYMENT: 'wire_payment',
};
