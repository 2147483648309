<template>
  <div>
    <PageHeader :title="title" :subtitle="subtitle" />

    <v-row>
      <v-col v-for="item in shownCategories" :key="item.id" cols="12" md="6">
        <CategoryCard :category="item" @click="onClickCategory(item)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="service in shownServices" :key="service.id" cols="12" md="6" lg="4">
        <ServiceBookingCard
          v-if="service.type === 'booking'"
          :service="service"
          @click="onClickService(service)"
        />
        <ServiceCard v-else :service="service" @click="onClickService(service)" />
      </v-col>
    </v-row>

    <v-row v-if="categoryId">
      <v-col cols="12">
        <v-btn color="t-grey" outlined @click="$emit('back')">
          {{ 'Назад' }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CategoryCard from '@/components/catalog/CategoryCard.vue';
import ServiceCard from '@/components/catalog/ServiceCard.vue';
import ServiceBookingCard from '@/components/catalog/ServiceBookingCard.vue';
import { state } from '@/store/store';
import PageHeader from '@/components/ui/PageHeader.vue';

export default {
  name: 'ServiceCatalog',

  components: {
    PageHeader,
    ServiceBookingCard,
    ServiceCard,
    CategoryCard,
  },

  props: {
    categoryId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    title() {
      if (state.selectedService) {
        return state.selectedService.name;
      }
      if (state.selectedCategory) {
        return state.selectedCategory.name;
      }
      return null;
    },

    subtitle() {
      if (state.selectedService) {
        return state.selectedService.title;
      }
      return null;
    },

    shownCategories() {
      return state.categories.filter(x => x.parentId === this.categoryId);
    },

    shownServices() {
      return this.categoryId ? state.services.filter(x => x.categoryId === this.categoryId) : [];
    },
  },

  methods: {
    onClickCategory(item) {
      state.selectedService = null;
      state.selectedCategory = item;
    },

    onClickService(item) {
      state.selectedService = item;
    },
  },
};
</script>
