<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <div>Выберите количество мест</div>
        <v-text-field
          v-model="form.quantity"
          class="input-center"
          style="width: 120px"
          hide-details
          readonly
          :prepend-inner-icon="mdiMinus"
          :append-icon="mdiPlus"
          @click:prepend-inner="form.quantity = form.quantity - (form.quantity > 1 ? 1 : 0)"
          @click:append="form.quantity += 1"
        />
      </v-col>
    </v-row>
    <v-row v-if="variants.length > 0" dense>
      <v-col cols="12">
        <SlotSelect
          v-model="form.serviceVariantId"
          :items="variants"
          label="Выберите тариф"
          height="42"
          mandatory
          hide-details
        />
      </v-col>
    </v-row>
    <v-row v-if="priceUnits.length > 1" dense>
      <v-col cols="12">
        <div class="mb-2">Выберите период аренды</div>
        <v-radio-group v-model="form.priceUnit" row hide-details>
          <v-radio
            v-for="priceUnit in priceUnits"
            :key="priceUnit"
            :label="priceUnitLabels[priceUnit]"
            :value="priceUnit"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-if="form.priceUnit === PriceUnit.HOUR" :key="form.serviceVariantId" dense>
      <v-col cols="12" md="5">
        <DatePicker
          v-model="form.hourDate"
          :min="minHourlyDate.toISOString()"
          label="Выберите дату аренды"
          hint="Обязательное поле"
          persistent-hint
        />
      </v-col>
      <v-col :key="form.hourDate" cols="12">
        <SlotToggle
          v-model="form.hourSlots"
          :slots="hourlySlots"
          label="Выберите часы аренды из доступных"
          :rules="[validateHourlySlots]"
          :hint="hourlyHint"
          persistent-hint
        />
        <div v-if="currentTariff" class="mt-2 text-caption text--secondary">
          Стоимость 1 часа - {{ currentTariff.price }} ₽
        </div>
      </v-col>
    </v-row>
    <v-row v-if="form.priceUnit === PriceUnit.DAY" :key="form.serviceVariantId" dense>
      <v-col cols="12" md="5">
        <DatePickerPeriod
          label="Выберите период аренды"
          :start.sync="form.dayPeriodStart"
          :end.sync="form.dayPeriodEnd"
          :min="minDailyDate.toISOString()"
          :allowed-dates="allowedDates"
          :disabled-dates="disabledDailyDates"
          :rules="[validateDailyPeriod]"
          :hint="dailyHint"
          persistent-hint
        />
        <div v-if="currentTariff" class="mt-2 text-caption text--secondary">
          Стоимость 1 суток - {{ currentTariff.price }} ₽
        </div>
      </v-col>
    </v-row>
    <v-row v-if="currentModifiers && currentModifiers.length > 0" dense>
      <v-col cols="12">
        <div class="mb-2">Дополнительные услуги</div>
        <div class="d-flex flex-column gap-3">
          <div v-for="modifier in currentModifiers" :key="modifier.id">
            <v-checkbox
              v-model="form.tariffModifierIds"
              :value="modifier.id"
              :disabled="modifier.enabledByDefault"
              hide-details
            >
              <template #label>
                <ModifierLabel :modifier="modifier" :price-unit="currentTariff.priceUnit" />
              </template>
            </v-checkbox>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mdiPlus, mdiMinus } from '@mdi/js';
import { actions, getters } from '@/store/store';
import { formProxyMixin } from '@/mixins/forms';
import { uniqueFilter } from '@/utils/arrays';
import { plural } from '@/utils/string';
import { ModifierAffect, PriceUnit } from '@/constants/serviceTariff';
import SlotSelect from '@/components/ui/SlotSelect';
import DatePicker from '@/components/ui/DatePicker';
import SlotToggle from '@/components/ui/SlotToggle';
import DatePickerPeriod from '@/components/ui/DatePickerPeriod';
import ModifierLabel from '@/components/tariff/ModifierLabel.vue';

export default {
  name: 'CoworkingCommonForm',

  components: {
    ModifierLabel,
    DatePickerPeriod,
    SlotToggle,
    DatePicker,
    SlotSelect,
  },

  mixins: [formProxyMixin],

  props: {
    service: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      mdiPlus,
      mdiMinus,
      PriceUnit: PriceUnit,
      ModifierAffect: ModifierAffect,
      monthRequests: {},
    };
  },

  computed: {
    variants() {
      return this.service.variants;
    },

    variant() {
      return this.service.variants.find(x => x.id === this.form.serviceVariantId);
    },

    tariffs() {
      return (this.variant?.tariffs ?? this.service.tariffs).filter(x => x.status === 'active');
    },

    priceUnits() {
      return this.tariffs.map(x => x.priceUnit).filter(uniqueFilter);
    },

    minTariffPrice() {
      return this.priceUnits.map(priceUnit => ({
        priceUnit,
        price: Math.min(...this.tariffs.filter(x => x.priceUnit === priceUnit).map(x => x.price)),
      }));
    },

    priceUnitLabels() {
      return {
        [PriceUnit.HOUR]: 'Почасовая',
        [PriceUnit.DAY]: 'Посуточная',
      };
    },

    unitTariffs() {
      return this.tariffs
        .filter(x => x.priceUnit === this.form.priceUnit)
        .sort((a, b) => a.minQuantity - b.minQuantity);
    },

    minNumberUnits() {
      return Math.min(...this.unitTariffs.map(x => x.minQuantity));
    },

    maxNumberUnits() {
      return Math.max(...this.unitTariffs.map(x => x.maxQuantity));
    },

    unitCount() {
      switch (this.form.priceUnit) {
        case PriceUnit.HOUR:
          return this.form.hourSlots.length;

        case PriceUnit.DAY:
          return dayjs
            .unix(this.form.dayPeriodEnd)
            .tz()
            .diff(dayjs.unix(this.form.dayPeriodStart).tz(), 'day');
      }

      return null;
    },

    currentTariff() {
      const priceUnit = this.form.priceUnit;

      return this.tariffs.find(
        x =>
          x.priceUnit === priceUnit &&
          this.unitCount >= x.minQuantity &&
          this.unitCount <= x.maxQuantity
      );
    },

    currentModifiers() {
      return (this.currentTariff?.modifiers ?? []).filter(x => x.status === 'active');
    },

    selectedModifiers() {
      return this.currentModifiers.filter(x => this.form.tariffModifierIds.includes(x.id));
    },

    totalPrice() {
      if (!this.currentTariff) {
        return null;
      }

      const tariffCost = this.currentTariff.price * this.unitCount;

      let affectedTariff =
        this.selectedModifiers
          .filter(x => x.affect === ModifierAffect.TARIFF_PRICE)
          .map(x => x.price)
          .reduce((a, b) => a + b, 0) * this.unitCount;

      let affectedTotal = this.selectedModifiers
        .filter(x => x.affect === ModifierAffect.TOTAL_COST)
        .map(x => x.price)
        .reduce((a, b) => a + b, 0);

      const ticketPrice = tariffCost + affectedTariff + affectedTotal;

      return this.form.quantity * ticketPrice;
    },

    minHourlyDate() {
      const today = dayjs().tz();

      const [weh, wem] = this.service.workEndTime.split(':');
      const workBefore = today.hour(weh).minute(wem);

      return today.unix() >= workBefore.unix() ? today.add(1, 'day') : today;
    },

    hourlyHint() {
      if (this.minNumberUnits === this.maxNumberUnits) {
        return (
          `Обязательное поле. Выберите ${this.maxNumberUnits} ` +
          plural(this.maxNumberUnits, 'час', 'часа', 'часов')
        );
      }

      return `Обязательное поле. Выберите от ${this.minNumberUnits} до ${this.maxNumberUnits} часов`;
    },

    dailyHint() {
      if (this.minNumberUnits === this.maxNumberUnits) {
        return (
          `Обязательное поле. Выберите ${this.maxNumberUnits} ` +
          plural(this.maxNumberUnits, 'сутки', 'суток', 'суток')
        );
      }

      return `Обязательное поле. Выберите от ${this.minNumberUnits} до ${this.maxNumberUnits} суток`;
    },

    occupationParams() {
      const params = {
        priceUnit: this.form.priceUnit,
        quantity: this.form.quantity,
        serviceId: this.service.id,
      };

      if (this.form.serviceVariantId) {
        params.serviceVariantId = this.form.serviceVariantId;
      }

      return params;
    },

    hourlySlots() {
      const slots = [];
      const date = this.form.hourDate;

      if (!date) {
        return slots;
      }

      const { workBeginTime, workEndTime } = this.service;
      const [wbh, wbm] = workBeginTime.split(':');
      const startAt = dayjs(date).tz().hour(wbh).minute(wbm).unix();
      const [weh, wem] = workEndTime.split(':');
      const endAt = dayjs(date).tz().hour(weh).minute(wem).unix();

      const items = getters.getOccupationForPeriod(this.occupationParams, startAt, endAt);

      // return items.map(x => ({ slot: x.startTime, free: x.free, duration: 3600 }));

      let time = startAt;
      while (time < endAt) {
        const found = items.find(x => x.startTime === time);
        slots.push({ slot: time, free: found?.free ?? false, duration: 3600 });
        time += 3600;
      }

      return slots;
    },

    minDailyDate() {
      return dayjs().tz().add(1, 'day').startOf('d');
    },

    disabledDailyDates() {
      return getters
        .getOccupationForPeriod(this.occupationParams, 0, Infinity)
        .filter(x => !x.free)
        .map(x => x.startTime);
    },

    serviceVariantAndPriceUnit() {
      return `${this.form.serviceVariantId}-${this.form.priceUnit}`;
    },

    hourlyOccupationFetchKey() {
      return `${this.form.serviceVariantId}-${this.form.hourDate}-${this.form.quantity}`;
    },

    variantOccupationSlots() {
      return getters.getOccupationForPeriod(this.occupationParams, 0, Infinity);
    },

    period() {
      let startAt = null;
      let endAt = null;

      if (this.form.priceUnit === PriceUnit.DAY) {
        startAt = this.form.dayPeriodStart;
        endAt = this.form.dayPeriodEnd;
      }

      if (this.form.priceUnit === PriceUnit.HOUR && this.form.hourSlots.length) {
        startAt = Math.min(...this.form.hourSlots);
        endAt = Math.max(...this.form.hourSlots) + 3600;
      }

      return { startAt, endAt };
    },
  },

  created() {
    this.form.quantity = 1;
    this.form.serviceVariantId = this.variants?.[0]?.id ?? null;
    this.form.priceUnit = this.priceUnits[0];
    this.initPeriod();

    this.$watch('form.serviceVariantId', () => {
      this.form.priceUnit = this.priceUnits[0];
    });

    this.$watch('currentTariff.id', () => {
      this.form.tariffModifierIds = this.currentModifiers
        .filter(x => x.enabledByDefault)
        .map(x => x.id);
    });

    this.$watch('serviceVariantAndPriceUnit', () => {
      this.initPeriod();
    });

    this.$watch('form.hourDate', () => {
      this.form.hourSlots = [];
    });

    this.$watch(
      'hourlyOccupationFetchKey',
      () => {
        if (this.form.priceUnit === PriceUnit.HOUR && this.form.hourDate) {
          actions.fetchOccupation({
            ...this.occupationParams,
            periodStart: dayjs.tz(this.form.hourDate).startOf('day').unix(),
            periodEnd: dayjs.tz(this.form.hourDate).endOf('day').unix(),
          });
        }
      },
      { immediate: true }
    );
  },

  methods: {
    initPeriod() {
      this.form.hourDate = null;
      this.form.hourSlots = [];
      this.form.dayPeriodStart = null;
      this.form.dayPeriodEnd = null;

      if (this.form.priceUnit === PriceUnit.HOUR) {
        this.form.hourDate = this.minHourlyDate.format('YYYY-MM-DD');
      }
    },

    buildIncorrectTariffMessage(count, periodText) {
      const lowerTariff = this.unitTariffs.findLast(x => x.maxQuantity < count);
      const higherTariff = this.unitTariffs.find(x => x.minQuantity > count);

      const periodsText = [lowerTariff, higherTariff]
        .filter(x => x)
        .map(x =>
          x.minQuantity !== x.maxQuantity
            ? `от ${x.minQuantity} до ${x.maxQuantity} ${periodText}`
            : ` ${x.maxQuantity} ${periodText}`
        )
        .join(' или ');

      return `Не найден подходящий тариф` + (periodsText ? `. Выберите ${periodsText}` : ``);
    },

    validateHourlySlots(v) {
      if (!v || !v.length) {
        return this.hourlyHint;
      }

      if (v.length < this.minNumberUnits || v.length > this.maxNumberUnits) {
        return this.hourlyHint;
      }

      return !!this.currentTariff || this.buildIncorrectTariffMessage(v.length, 'часов');
    },

    validateDailyPeriod() {
      if (!this.form.dayPeriodEnd || !this.form.dayPeriodStart) {
        return this.dailyHint;
      }

      const count = Math.round((this.form.dayPeriodEnd - this.form.dayPeriodStart) / 86400);
      if (count < this.minNumberUnits || count > this.maxNumberUnits) {
        return this.dailyHint;
      }

      return !!this.currentTariff || this.buildIncorrectTariffMessage(count, 'суток');
    },

    requestMonth(date) {
      const ym = date.substring(0, 7); // ymd
      const key = JSON.stringify({ ...this.occupationParams, ym });

      if (!this.monthRequests[key]) {
        this.monthRequests[key] = actions.fetchOccupation({
          ...this.occupationParams,
          periodStart: dayjs.tz(date).startOf('month').unix(),
          periodEnd: dayjs.tz(date).endOf('month').unix(),
        });
      }
      return this.monthRequests[key];
    },

    allowedDates(date) {
      this.requestMonth(date);

      const time = dayjs.tz(date).startOf('day').unix();
      const foundItem = this.variantOccupationSlots.find(x => x.startTime === time);

      return foundItem?.free ?? false;
    },
  },
};
</script>
