<template>
  <OrderPositions>
    <v-divider />
    <OrderPosition
      v-for="item of items"
      :key="item.id"
      :qty="`${item.count} x ${$n(item.price, 'currency')}`"
      :sum="$n(item.count * item.price, 'currency')"
    >
      <div>{{ item.product.commercialName || item.product.name }}</div>
    </OrderPosition>
    <v-divider />
  </OrderPositions>
</template>

<script>
import OrderPositions from '@/components/order/OrderPositions';
import OrderPosition from '@/components/order/OrderPosition';

export default {
  name: 'CoffeeBreakOrderItems',

  components: {
    OrderPosition,
    OrderPositions,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
