<template>
  <v-row dense>
    <v-col v-for="(dynamicField, idx) of dynamicFields" :key="idx" cols="12">
      <DynamicField v-model="values[idx].value" :field="dynamicField" />
    </v-col>
  </v-row>
</template>

<script>
import DynamicField from '@/components/dynamic-fields/DynamicField.vue';

export default {
  name: 'DynamicFields',

  components: { DynamicField },

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },

  props: {
    dynamicFields: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Array,
    },
  },

  data() {
    const values = this.dynamicFields.map(field => {
      let res = {
        id: field.id,
        parentId: field.parentId,
        name: field.name,
        inputType: field.inputType,
        value: field.inputType === 'checkbox' ? false : null,
      };

      const model = (this.modelValue ?? []).find(x => x.id === field.id);
      if (model) {
        // may contains additional data, like "item"
        res = { ...res, ...model };
      }

      return res;
    });

    return {
      values: values,
    };
  },

  watch: {
    values: {
      deep: true,
      handler() {
        this.$emit(
          'update:modelValue',
          this.values.filter(x => x.value)
        );
      },
    },
  },
};
</script>
