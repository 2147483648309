<template>
  <div>
    <v-expansion-panels v-model="model" class="by" accordion multiple>
      <v-expansion-panel v-for="category of categories" :key="category.id">
        <v-expansion-panel-header>
          <v-badge
            :value="!!countByCategory[category.id]"
            :content="countByCategory[category.id]"
            class="flex-grow-0"
            inline
          >
            {{ category.name }}
          </v-badge>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <ProductList v-slot="{ product }" :products="getCategoryProducts(category.id)">
            <ProductItem
              :product="product"
              :price-color="getCount(product) > 0 ? 'primary' : undefined"
              :free="free"
              class="px-6"
            >
              <template v-if="!hideButtons" #item-action>
                <PlusMinus
                  :count="getCount(product)"
                  :max="
                    product.unlimitedStocks
                      ? null
                      : product.stockQuantity - product.reservationQuantity
                  "
                  @plus="onProductAdd(product)"
                  @minus="onProductRemove(product)"
                />

                <div
                  v-if="
                    !product.unlimitedStocks &&
                    getCount(product) > 0 &&
                    getCount(product) >= product.stockQuantity - product.reservationQuantity
                  "
                  class="mt-2 text-caption t-grey--text"
                >
                  {{
                    $t('Всего в наличии {0} шт', [
                      product.stockQuantity - product.reservationQuantity,
                    ])
                  }}
                </div>
              </template>
            </ProductItem>
          </ProductList>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ProductList from '@/components/product/ProductList.vue';
import ProductItem from '@/components/product/ProductItem.vue';
import PlusMinus from '@/components/product/PlusMinus.vue';

export default {
  name: 'ProductCatalog',

  components: { PlusMinus, ProductItem, ProductList },

  model: {
    prop: 'cart',
    event: 'change',
  },

  props: {
    cart: {
      type: Array,
      default: () => [],
    },
    categories: Array,
    free: Boolean,
    hideButtons: Boolean,
    products: Array,
  },

  data: () => ({
    model: [],
  }),

  computed: {
    countByCategory() {
      const result = {};
      this.cart.forEach(x => {
        result[x.categoryId] = (result[x.categoryId] || 0) + x.qty;
      });
      return result;
    },
  },

  methods: {
    getCategoryProducts(categoryId) {
      return this.products.filter(x => x.categoryId === categoryId);
    },

    getCount(product) {
      return this.cart.find(x => x.id === product.id)?.qty || 0;
    },

    onProductAdd(product) {
      const newCart = [...this.cart];

      let item = newCart.find(x => x.id === product.id);
      if (!item) {
        item = {
          id: product.id,
          productId: product.id,
          categoryId: product.categoryId,
          name: product.commercialName || product.name,
          price: product.price,
          qty: 0,
        };
        newCart.push(item);
      }
      item.qty++;

      this.$emit('change', newCart);
    },

    onProductRemove(product) {
      const newCart = [...this.cart];

      let item = newCart.find(x => x.id === product.id);
      if (!item) {
        return;
      }
      if (item.qty > 0) {
        item.qty--;
      }
      if (item.qty === 0) {
        const itemIdx = newCart.indexOf(item);
        newCart.splice(itemIdx, 1);
      }

      this.$emit('change', newCart);
    },
  },
};
</script>
